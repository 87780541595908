:root {
    --overdrive-blue: #006693;
    --overdrive-blue-light: #74cee2;
    --overdrive-blue-dark: #0a2240;
    --asterisk-red: #ff0202;
}

/* TEXT COLOR MODIFIERS */
.text--blue {
    color: var(--overdrive-blue);
}

.text--blue--light {
    color: var(--overdrive-blue-light);
}

.text--blue--dark {
    color: var(--overdrive-blue-dark);
}

.text--green {
    color: #8EC549
}

.text--green--dark {
    color: #75AC30
}

.text--yellow {
    color: #FFAE00
}

.text--yellow--light {
    color: #FFFFCC
}

.text--yellow--dark {
    color: #E69500
}

.text--orange {
    color: #FF8000
}

.text--orange--dark {
    color: #E66700
}

.text--red-preorder {
    color: #D02C50
}

.text--red-preorder--light {
    color: #FFEAEE
}

.text--red-preorder--dark {
    color: #B71337
}

.text--red {
    color: #AE4646
}

.text--red-light {
    color: #FFEAEA
}

.text--red--dark {
    color: #7B1313
}

.text--brown {
    color: #AD8B35
}

.text--brown--dark {
    color: #7A5802
}


.text--white {
    color: #FFF
}

.text--grey {
    color: #999999;
}

.text--light-grey {
    color: #666666;
}


/* Validation */
:root {
    --validation-error: #ae4646;
    --validation-background: #fef1ec;
}