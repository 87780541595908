@font-face {
  font-family: "FreightTextProBold-Regular";
  src: url("/Content/fonts/webfonts/2D64F3_0_0.ttf") format("truetype"),
    url("/Content/fonts/webfonts/2D64F3_0_0.eot?#iefix")
      format("embedded-opentype"),
    url("/Content/fonts/webfonts/2D64F3_0_0.eot") format("embedded-opentype"),
    url("/Content/fonts/webfonts/2D64F3_0_0.woff") format("woff");
  font-style: normal;
  font-weight: bold;
}

@font-face {
  font-family: "FreightTextProBook-Regular";
  src: url("/Content/fonts/webfonts/2D64F3_3_0.ttf") format("truetype"),
    url("/Content/fonts/webfonts/2D64F3_3_0.eot?#iefix")
      format("embedded-opentype"),
    url("/Content/fonts/webfonts/2D64F3_3_0.eot") format("embedded-opentype"),
    url("/Content/fonts/webfonts/2D64F3_3_0.woff") format("woff");
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: "FreightTextProBlack-Regular";
  src: url("/Content/fonts/webfonts/2D64F3_4_0.eot");
  src: url("/Content/fonts/webfonts/2D64F3_4_0.eot?#iefix")
      format("embedded-opentype"),
    url("/Content/fonts/webfonts/2D64F3_4_0.woff") format("woff"),
    url("/Content/fonts/webfonts/2D64F3_4_0.ttf") format("truetype");
  font-style: normal;
  font-weight: 900;
}

@font-face {
  font-family: "FreightTextProLight-Regular";
  src: url("/Content/fonts/webfonts/2D64F3_8_0.ttf") format("truetype"),
    url("/Content/fonts/webfonts/2D64F3_8_0.eot?#iefix")
      format("embedded-opentype"),
    url("/Content/fonts/webfonts/2D64F3_8_0.eot") format("embedded-opentype"),
    url("/Content/fonts/webfonts/2D64F3_8_0.woff") format("woff");
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: "FreightTextProMedium-Regular";
  src: url("/Content/fonts/webfonts/2D64F3_A_0.ttf") format("truetype"),
    url("/Content/fonts/webfonts/2D64F3_A_0.eot?#iefix")
      format("embedded-opentype"),
    url("/Content/fonts/webfonts/2D64F3_A_0.eot") format("embedded-opentype"),
    url("/Content/fonts/webfonts/2D64F3_A_0.woff") format("woff");
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: "FreightTextProSemibold-Regular";
  src: url("/Content/fonts/webfonts/2D64F3_B_0.ttf") format("truetype"),
    url("/Content/fonts/webfonts/2D64F3_B_0.eot?#iefix")
      format("embedded-opentype"),
    url("/Content/fonts/webfonts/2D64F3_B_0.eot") format("embedded-opentype"),
    url("/Content/fonts/webfonts/2D64F3_B_0.woff") format("woff");
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: "proxima-nova";
  src: url("/Content/fonts/proximanova-regular-webfont.eot");
  src: url("/Content/fonts/proximanova-regular-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("/Content/fonts/proximanova-regular-webfont.woff") format("woff"),
    url("/Content/fonts/proximanova-regular-webfont.ttf") format("truetype"),
    url("/Content/fonts/proximanova-regular-webfont.svg#proxima-nova")
      format("svg");
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: "proxima-nova-semibold";
  src: url("/Content/fonts/proximanova-semibold-webfont.eot");
  src: url("/Content/fonts/proximanova-semibold-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("/Content/fonts/proximanova-semibold-webfont.woff") format("woff"),
    url("/Content/fonts/proximanova-semibold-webfont.ttf") format("truetype"),
    url("/Content/fonts/proximanova-semibold-webfont.svg#proxima-nova")
      format("svg");
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: "proxima-nova-bold";
  src: url("/Content/fonts/proximanova-bold-webfont.eot");
  src: url("/Content/fonts/proximanova-bold-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("/Content/fonts/proximanova-bold-webfont.woff") format("woff"),
    url("/Content/fonts/proximanova-bold-webfont.ttf") format("truetype"),
    url("/Content/fonts/proximanova-bold-webfont.svg#proxima-nova")
      format("svg");
  font-style: normal;
  font-weight: normal;
}

/********* Import Noto Sans font **********/
/* this helps our pdfs support Devanagari scripts typically used in the Hindi language, and cyrillic used in Russian.*/
/* found here: https://fonts.google.com/specimen/Noto+Sans */
@font-face {
  font-family: "Noto Sans";
  src: url("/Content/fonts/Noto_Sans/NotoSans-Regular.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: "Noto Sans";
  src: url("/Content/fonts/Noto_Sans/NotoSans-Italic.ttf") format("truetype");
  font-style: italic;
  font-weight: normal;
}

@font-face {
  font-family: "Noto Sans";
  src: url("/Content/fonts/Noto_Sans/NotoSans-Bold.ttf") format("truetype");
  font-style: normal;
  font-weight: bold;
}

@font-face {
  font-family: "Noto Sans";
  src: url("/Content/fonts/Noto_Sans/NotoSans-BoldItalic.ttf")
    format("truetype");
  font-style: italic;
  font-weight: bold;
}
/********* END Import Noto Sans font **********/

/********* Import Abhaya_Libre font **********/
/* this helps our pdfs support Sinhala scripts typically used in the Sinhala; Sinhalese language.*/
/* found here: https://fonts.google.com/specimen/Abhaya+Libre */
@font-face {
  font-family: "Abhaya Libre";
  src: url("/Content/fonts/Abhaya_Libre/AbhayaLibre-Regular.ttf")
    format("truetype");
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: "Abhaya Libre";
  src: url("/Content/fonts/Abhaya_Libre/AbhayaLibre-Medium.ttf")
    format("truetype");
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: "Abhaya Libre";
  src: url("/Content/fonts/Abhaya_Libre/AbhayaLibre-SemiBold.ttf")
    format("truetype");
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: "Abhaya Libre";
  src: url("/Content/fonts/Abhaya_Libre/AbhayaLibre-Bold.ttf")
    format("truetype");
  font-style: normal;
  font-weight: bold;
}
/********* END Import Abhaya_Libre font **********/

/********* Import Noto_Sans_SC font **********/
/* this helps our pdfs support Chinese characters .*/
/* found here: https://fonts.google.com/specimen/Noto+Sans+SC */
/* there was not a semibold version of NotoSansSC available when this was added (2020-10-09)*/
@font-face {
  font-family: "Noto Sans SC";
  src: url("/Content/fonts/Noto_Sans_SC/NotoSansSC-Regular.otf")
    format("opentype");
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: "Noto Sans SC";
  src: url("/Content/fonts/Noto_Sans_SC/NotoSansSC-Medium.otf")
    format("opentype");
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: "Noto Sans SC";
  src: url("/Content/fonts/Noto_Sans_SC/NotoSansSC-Bold.otf") format("opentype");
  font-style: normal;
  font-weight: bold;
}
/********* END Import Noto Sans SC font **********/

/********* Import Noto_Sans_KR font **********/
/* this helps our pdfs support Korean characters .*/
/* found here: https://fonts.google.com/specimen/Noto+Sans+KR */
/* there was not a semibold version of NotoSansKR available when this was added (2020-10-15)*/
@font-face {
  font-family: "Noto Sans KR";
  src: url("/Content/fonts/Noto_Sans_KR/NotoSansKR-Regular.otf")
    format("opentype");
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: "Noto Sans KR";
  src: url("/Content/fonts/Noto_Sans_KR/NotoSansKR-Medium.otf")
    format("opentype");
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: "Noto Sans KR";
  src: url("/Content/fonts/Noto_Sans_KR/NotoSansKR-Bold.otf") format("opentype");
  font-style: normal;
  font-weight: bold;
}
/********* END Import Noto Sans KR font **********/

/********* Import Hind Guntur font **********/
/* this helps our pdfs support Telugu scripts*/
/* found here: https://fonts.google.com/specimen/Hind+Guntur */
@font-face {
  font-family: "Hind Guntur";
  src: url("/Content/fonts/Hind_Guntur/HindGuntur-Regular.ttf")
    format("truetype");
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: "Hind Guntur";
  src: url("/Content/fonts/Hind_Guntur/HindGuntur-Medium.ttf")
    format("truetype");
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: "Hind Guntur";
  src: url("/Content/fonts/Hind_Guntur/HindGuntur-SemiBold.ttf")
    format("truetype");
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: "Hind Guntur";
  src: url("/Content/fonts/Hind_Guntur/HindGuntur-Bold.ttf") format("truetype");
  font-style: normal;
  font-weight: bold;
}
/********* END Import Hind Guntur font **********/

.fontFamily--English,
body .fontFamily--English {
  font-family: "proxima-nova";
}

.fontFamily--English-serif,
body .fontFamily--English-serif {
  font-family: serif;
}

.fontFamily--ChineseSimplified,
body .fontFamily--ChineseSimplified {
  font-family: "Noto Sans SC";
}

.fontFamily--Sinhala,
body .fontFamily--Sinhala {
  font-family: "Abhaya Libre";
  font-size: 14px;
}

.fontFamily--DevanagariAndCyrillic,
body .fontFamily--DevanagariAndCyrillic {
  font-family: "Noto Sans";
}

.fontFamily--Korean,
body .fontFamily--Korean {
  font-family: "Noto Sans KR";
}

.fontFamily--Telugu,
body .fontFamily--Telugu {
  font-family: "Hind Guntur";
}
/********* end font families *********/
