.dialog-modal {
    --dialog-margin: 16px;
    --dialog-font: 'proxima-nova', 'Segoe UI', 'Open Sans', Tahoma, Helvetica, sans-serif;
    border: none;
    border-radius: 4px;
    box-shadow: 0 0 10px 3px #696969;
    padding: 0;
    /*arbitrarily chosen number*/
    min-width: 300px;
}

    .dialog-modal::backdrop {
        background: #000000;
        opacity: 0.5;
    }

.dialog-modal * {
    /* override float:left in the inlinemodal layout */
    float: none;
}

.dialog-modal__padding-wrapper {
    min-width: inherit;
    padding: var(--dialog-margin);
}

.dialog-modal__loading-text {
    text-align: center;
}

.dialog-modal__spinner {
    max-width: 25px;
    max-height: 25px;
}

.dialog-modal__close-button {
    position: absolute;
    top: var(--dialog-margin);
    right: var(--dialog-margin);
    margin: 0px;
    cursor: pointer;
}

.dialog-modal__close-button:hover {
    font-weight: bold;
}

.dialog-modal__error-wrapper {
    padding: 0 10px;
}

.dialog-modal__error-text {
    color: #222222;
}

h1.dialog-modal__title {
    padding: 0px;
    margin: 0px;
    font-weight: 600;
    font-size: 1.2rem;
    font-family: var(--dialog-font);
}


.dialog-modal__section {
    margin: 24px 0;
}

.dialog-modal__full-width-section {
    margin: calc(var(--dialog-margin) * -1);
    padding: var(--dialog-margin);
}

.dialog-modal__box-shadow-top {
    box-shadow: 0 -5px 8px #21212142;
    margin-top: 10px;
}

h2.dialog-modal__header {
    font-size: 1rem;
    color: #000000;
    font-family: var(--dialog-font);
}

h3.dialog-modal__subhead {
    font-size: 0.8rem;
    color: #000000;
    font-family: var(--dialog-font);
}

input.dialog-modal__input[type="text"] {
    box-sizing: border-box;
    margin: 0 0 12px 0;
    width: 143px;
    border-radius: 3px;
    border: 1px solid #aaaaaa;
    padding: 16px 8px;
}

input.dialog-modal__input--wide[type="text"] {
    width: 100%;
}
