.unpinned .icon-pinned:before,
.icon-not-pinned:before {
    content: "\e90e";
}
.unpinned:hover .icon-pinned:before,
.icon-not-pinned-hover:before {
    content: "\e90f";
}
.pinned:hover .icon-pinned:before,
.icon-pinned-hover:before {
    content: "\e90c";
}
.pinned .icon-pinned:before,
.icon-pinned:before {
    content: "\e90d";
}
.icon-read-only:before {
  content: "\e95d";
}
.icon-sort:before {
  content: "\e95c";
}
.icon-awards:before {
    content: "\e95a";
}
.icon-awards-alt:before {
    content: "\e95b";
}
.icon-globe:before {
    content: "\e96a";
}
.icon-settings:before {
    content: "\e96b";
}
.icon-download:before {
    content: "\e958";
}
.icon-support-alt:before {
    content: "\e954";
}
.icon-support:before {
    content: "\e959";
}
.icon-guides:before {
    content: "\e955";
}
.icon-get-help:before {
    content: "\e956";
}
.icon-facebook:before {
    content: "\e949";
}
.icon-pinterest:before {
    content: "\e94a";
}
.icon-twitter:before {
    content: "\e94b";
}
.icon-instagram:before {
  content: "\e95f";
}
.icon-blog-rss:before {
    content: "\e948";
}
.icon-garbage:before {
    content: "\e90b";
}
.icon-continue-work-alt:before {
    content: "\e944";
}
.icon-continue-work:before {
    content: "\e94c";
}
.icon-edit-a-title:before {
    content: "\e945";
}
.icon-add-a-title:before {
    content: "\e946";
}
.icon-holds-manager:before {
    content: "\e906";
}
.icon-patron-interest:before {
    content: "\e864"
}
.icon-rtl:before {
    content: "\e907";
}
.icon-smart-lists:before {
    content: "\e942";
}
.icon-metered-access-manager:before {
    content: "\e901";
}
.icon-audiobook:before {
    content: "\e900";
}
.icon-ebook:before {
    content: "\e901";
}
.icon-music:before {
    content: "\e902";
}
.icon-video:before {
    content: "\e903";
}
.icon-periodicals:before {
    content: "\e904";
}
.icon-magazine:before {
    content: "\e960";
}
.icon-ntc:before {
    content: "\e704";
}
.icon-close:before {
    content: "\e905";
}
.icon-locked:before {
    content: "\e908";
}
.icon-unlocked:before {
    content: "\e909";
}
.icon-private:before {
    content: "\e90a";
}
.icon-edit:before {
    content: "\e947";
}
.icon-big-arrow-down:before {
    content: "\e910";
}
.icon-big-arrow-left:before {
    content: "\e911";
}
.icon-big-arrow-right:before {
    content: "\e912";
}
.icon-big-arrow-up:before {
    content: "\e913";
}
.icon-big-arrow-down-solid:before {
    content: "\e93c";
}
.icon-big-arrow-left-solid:before {
    content: "\e93d";
}
.icon-big-arrow-right-solid:before {
    content: "\e93e";
}
.icon-big-arrow-up-solid:before {
    content: "\e950";
}
.icon-cart00:before {
    content: "\e915";
}
.icon-cart01:before {
    content: "\e916";
}
.icon-cart02:before {
    content: "\e917";
}
.icon-cart03:before {
    content: "\e918";
}
.icon-cart04:before {
    content: "\e919";
}
.icon-cart05:before {
    content: "\e91a";
}
.icon-cart06:before {
    content: "\e94e";
}
.icon-cart07:before {
    content: "\e91b";
}
.icon-cart08:before {
    content: "\e91c";
}
.icon-cart09:before {
    content: "\e91d";
}
.icon-manage-carts:before {
    content: "\e91e";
}
.icon-list0:before {
    content: "\e927";
}
.icon-list1:before {
    content: "\e928";
}
.icon-list2:before {
    content: "\e929";
}
.icon-list3:before {
    content: "\e92a";
}
.icon-list4:before {
    content: "\e92b";
}
.icon-list5:before {
    content: "\e92c";
}
.icon-manage-lists:before {
    content: "\e92e";
}
.icon-periodicalsCart00:before {
    content: "\e92f";
}
.icon-periodicalsCart01:before {
    content: "\e931";
}
.icon-periodicalsCart02:before {
    content: "\e932";
}
.icon-periodicalsCart03:before {
    content: "\e933";
}
.icon-periodicalsCart04:before {
    content: "\e934";
}
.icon-periodicalsCart05:before {
    content: "\e935";
}
.icon-periodicalsCart06:before {
    content: "\e936";
}
.icon-periodicalsCart07:before {
    content: "\e937";
}
.icon-periodicalsCart08:before {
    content: "\e941";
}
.icon-manage-periodicals:before {
    content: "\e92d";
}
.icon-copy:before {
    content: "\e923";
}
.icon-table:before {
    content: "\e938";
}
.icon-export:before {
    content: "\e924";
}
.icon-calendar:before {
    content: "\e914";
}
.icon-checkbox-unchecked:before {
    content: "\e93f";
}
.icon-checkbox-checked:before {
    content: "\e94d";
}
.icon-checkbox-partial:before {
    content: "\e91f";
}
.icon-checkmark:before {
    content: "\e920";
}
.icon-search:before {
    content: "\e93a";
}
.icon-plus:before {
    content: "\e930";
}
.icon-minus:before {
    content: "\e939";
}
.icon-small-arrow-down:before {
    content: "\e921";
}
.icon-small-arrow-left:before {
    content: "\e922";
}
.icon-small-arrow-right:before {
    content: "\e93b";
}
.icon-small-arrow-up:before {
    content: "\e94f";
}
.icon-alert:before {
    content: "\e943";
}
.icon-warning-line:before {
    content: "\e951";
}
.icon-warning:before {
    content: "\e940";
}
.icon-help-line:before {
    content: "\e952";
}
.icon-help:before {
    content: "\e925";
}
.icon-info-line:before {
    content: "\e953";
}
.icon-info:before {
    content: "\e926";
}
.icon-outreach:before {
    content: "\e957";
}
.icon-flag-outline:before {
    content: "\e961"""
}
.icon-flag-solid:before {
    content: "\e962";
}
.icon-download:before {
    color: #006693;
}
.icon-checkmark-heavy:before {
    content: "\e963";
}
.icon-clipboard:before {
    content: "\e964";
}
.icon-link:before {
    content: "\e965";
}
.icon-clock:before {
    content: "\e95e";
}
.icon-reset:before {
    content: "\e706";
}
.icon-tips:before {
    content: "\e708";
}
.icon-drag:before {
    content: "\e710";
}
.icon-garbage:before {
    content: "\e90b";
}
.icon-bundle:before {
    content: "\e712";
}
.icon-tag:before {
    content: "\e824"; 
}
.icon-small-arrow-left-double:before {
    content: "\e874";
}

.icon-small-arrow-right-double:before {
    content: "\e876";
}
.icon-notification:before {
    content: "\e968";
}
.icon-budget:before {
    content: "\e974";
}
.icon-play:before {
    content: "▶";
}