.native-date-picker__input {
    padding: 8px;
    border: 1px solid #dddddd;
    border-radius: 3px;
    margin-top: 8px;
    background-color: white;
    &:invalid {
        border-color: var(--validation-error);
        background-color: var(--validation-background);
    }
}

.native-date-picker__input-validation-message {
    display: none;
    color: var(--validation-error);
    margin-top: 8px;
}

.native-date-picker__input:invalid + .native-date-picker__input-validation-message {
    display: block;
}
