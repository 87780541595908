.scrollbar--smaller,
.scrollbar--smaller-fixed {
    /* mnewman (2024): 
        This doesn't work for Safari... but scrollbars are already thin by default in Safari,
        so it doesn't matter.
    */
    scrollbar-width: thin;
}

.scrollbar--smaller-fixed {
    /* Hack because jquery.scrollbar provided a relative-position parent and we need it sometimes */
    position: relative;
}