/********* input/button reset *******/
input,
button {
    margin: 0;
    border: 0;
    padding: 0;
    display: inline-block;
    white-space: normal;
    background: none;
    font-size: 1em;
}

/******** Variables ********/

:root {
    /**
     * Use this instead of transition: all,
     * as many properties (like border-width or padding) will shift layout
     * and most of the non-color non-transform property transitions
     * aren't done on the GPU and can be janky.
     * You probably only care about transitioning color & opacity anyway,
     * but you can always add more properties in your rule if you want:
     * .button {
     *   transition: var(--transition-color);
     * }
     * .button {
     *   transition: var(--transition-color);
     *   transition-duration: 400ms;
     * }
     * .button {
     *   transition: var(--transition-color);
     *   transition-property: var(--transition-color-properties), transform;
     * }
     */
    --transition-color-duration: .1s;
    --transition-color-timing-function: ease-in-out;
    --transition-color-properties: background-color, border-color, color, opacity;
    --_transition-color-dt: var(--transition-color-duration) var(--transition-color-timing-function);
    --transition-color:
        background-color var(--_transition-color-dt),
        border-color var(--_transition-color-dt),
        color var(--_transition-color-dt),
        opacity var(--_transition-color-dt);

    --carousel-item-width: 202px;
}

/********************************* BEGIN FLOATS */
.left {
    float: left;
}

.right {
    float: right;
}

.clear {
    clear: both;
}

.underline {
    text-decoration: underline;
}

.float-none {
    float: none !important;
}

.clear-fix:after {
    display: block;
    visibility: hidden;
    clear: both;
    height: 0;
    content: ".";
}
/********************************* END FLOATS */

input:where([disabled]),
select:where([disabled]),
textarea:where([disabled]){
    opacity: 0.4;
    filter: alpha(opacity=50); /* For IE8 and earlier */
}

select:where([disabled]) {
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
}

/********************************* BEGIN HIDERS */
.hidden {
    position: absolute;
    left: -9999px;
    max-height: 0;
}

.invisible {
    visibility: hidden !important;
}

.visible {
    visibility: visible;
}

.showMe {
    display: block;
}

.showMeInline {
    display: inline-block;
}

.hideMe {
    display: none !important;
}

.overflow--visible {
    overflow: visible !important;
}
/********************************* END HIDERS */


/********************************* BEGIN LAYOUT */

html {
    height: 100%;
}

html, body {
    margin: 0;
    padding: 0;
    width: 100%;
}

body {
    position: relative;
    color: #333333;
    font-size: .8em;
    overflow:visible !important;
    text-rendering: optimizelegibility;
    min-height: 100%;
}

#flexAnchor {
    position: absolute;
    top: 0;
    right: 0;
    width: 0px;
    height: 0px;
}

#headerContent.merchandising {
    height: 111px !important;
}

#header, #neck, #page, #footer {
    min-width: 960px;
    width: 100%;
}

#headerContent, #tabs, #BodyWrapper, #footerContainer {
    padding: 0 1%;
}

#header {
    min-width: 960px;

    background: #0A2240 0% 0% no-repeat padding-box;
    opacity: 1;
}

#neck {
    margin-bottom: 20px;
    min-height: 43px;
    background-color: #006693;
    box-shadow: 0px 4px 10px -3px rgba(0, 0, 0, 0.1);
}

#page {
    position: relative;
    margin: 0;
    padding-bottom: 40px;
}

#BodyWrapper {
    clear: both;
    padding-bottom: 20px;
    background: #ffffff;
}

#PageContent {
    position: relative;
}

#footer {
    clear: both;
    position: absolute;
    bottom: 0;
    z-index: 10;
}

/********************************* END LAYOUT */

/***** BEGIN Flexbox styles *****/
.display--flex {
    display: flex;
}

.display--flex-important {
    display: flex !important;
}

.display--inline-flex {
    display: inline-flex;
}

.display--flex-row {
    display: flex;
    flex-direction: row !important;
}

.display--flex-column {
    display: flex;
    flex-direction: column;
}

.display--flex-1 {
    display: flex;
    flex: 1
}

.flex--grow-1 {
    flex-grow: 1;
}

.flex--grow-1-important {
    flex-grow: 1 !important;
}

.flex--align-items-center {
    align-items: center;
}

.flex--wrap-wrap {
    flex-wrap: wrap;
}

.flex--align-items-flex-start {
    align-items: flex-start
}

.flex--align-items-flex-end {
    align-items: flex-end
}

.flex--justify-content-space-around {
    justify-content: space-around;
}

.flex--justify-content-space-between {
    justify-content: space-between;
}

.flex--direction-row-reverse {
    flex-direction: row-reverse;
}

.flex--justify-content-center {
    justify-content: center;
}
.flex--justify-content-end {
    justify-content: flex-end;
}

/***** END Flexbox styles *****/
/********************************* BEGIN SITE-WIDE ELEMENTS */
body, input, select, textarea, .standard-font {
    font-family: 'proxima-nova', 'Segoe UI', 'Open Sans', Tahoma, Helvetica, sans-serif;
    letter-spacing: initial;
    text-rendering: optimizelegibility;
}

h1, h2, h3, h4, h5, h6 {
    font-family: 'proxima-nova', 'Segoe UI', 'Open Sans', Tahoma, Helvetica, sans-serif;
    margin: 8px auto;
    color: #0A2240;
    font-weight: 500;
    letter-spacing: initial;
    text-rendering: optimizelegibility;
}

h1 {
    font-size: 2.25em;
    font-family: 'FreightTextProBook-Regular', sans-serif;
}

h2 {
    font-size: 1.75em;
}

.middleContent :where(h2) {
    font-family: 'FreightTextProBook-Regular', sans-serif;
}

h1.standard-font,
h2.standard-font {
    font-family: 'proxima-nova', 'Segoe UI', 'Open Sans', Tahoma, Helvetica, sans-serif;
}

h3 {
    font-size: 1.25em;
}

h4 {
    font-size: 1em;
}

h5 {
    font-size: 1em;
}

h6 {
    font-size: 1em;
}

hr {
    margin: 10px 0 5px 0;
    height: 1px;
    border: none;
    background: #E3E3E3;
}

a, a:where(:link, :visited, :active), .fauxLink {
    color: #006693;
    text-decoration: none;
    cursor: pointer;
}

sup {
    vertical-align: text-top;
}

.showMeInline :where(a) {
    width: 100%;
    display: block;
    text-align: left;
    color: #0A2240;
    text-decoration: none !important;
}

    a:where(:hover), .fauxLink:hover, .featuredTitleDetails p.bookTitle:hover {
        color: #0A2240;
        text-decoration: underline;
        cursor: pointer;
    }

    a img {
        border: none;
    }

.bold {
    font-weight: bold;
}

.semiBold {
    font-weight: 600;
}


.bold-plus {
    font-weight: bold;
    text-shadow: 0.05em 0 0;
}

.italic {
    font-style: italic;
}

.advantageText {
    color: #FF9900;
}

.consText, .titleLink h2:hover, #carousels h2 a:hover, .addToCart {
    color: #0A2240;
}

.pinkLink, .required, .ltdText, .circLimitedWarning, .priceText, .preorder {
    color: #d02c50;
}

.est-txt {
    font-size: .8em;
    color: #d02c50;
}

label.requiredField:before, input.requiredField:before {
    display: inline-block;
    margin-left: -7px;
    width: 7px;
    content: '*';
    font-weight: bold;
    color: #d02c50;
}

.expiredText {
    color: #999999;
}

.circLimitedWarning {
    font-style: italic;
    font-size: .85em;
}

.smallGrayTxt {
    margin: 0;
    margin: 2px 0;
    color: #999999;
    font-size: .85em;
    font-weight: 500;
}

    .smallGrayTxt.wider {
        padding-top: 7px;
    }

    .smallGrayTxt.bumpUp {
        margin-top: -3px;
}

ul {
    margin: 0;
    padding-left: 0;
    list-style: none;
}

    ul.bulletedList {
        margin-left: 20px;
        list-style: disc;
    }

        ul.bulletedList ul li {
            margin-left: 15px;
            list-style: circle;
        }

ol {
    margin: 0;
    padding-left: 20px;
}

    ol li {
        margin: 15px 0;
    }

input:where([type="text"], [type="password"], [type="number"]), textarea {
    -webkit-appearance: none;
    margin-top: 2px;
    padding: 1px 2px;
    height: 20px;
    border: 1px solid #aaaaaa;
    border-radius: 0;
    background: #fefefe;
}

    input:where([type="number"])::-webkit-inner-spin-button,
    input:where([type="number"])::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

select {
    margin: 0;
    height: 24px;
    color: #333333;
    font-size: 1em;
}

    select:where([multiple]) {
        height: auto;
    }

    select.source, select.selected {
        border: 1px solid #999999;
    }

.noSample {
    color: #006693;
    font-weight: bold;
    font-size: 1em;
}

.ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

table {
    border-spacing: 0;
    border-collapse: collapse;
}

.fullWidth {
    width: 100%;
}

.relative {
    position: relative;
}

.fixedBar--bottom {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 20px 1%;
    height: auto;
    background-color: white;
    z-index: 99;
    box-shadow: 0px -5px 5px 0px rgba(51, 51, 51, 0.1);
    }

map, area, area:where(:active, :focus) {
    outline: none;
    border: none;
}

.noSelect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
}

.noHover, .noOnClick {
    pointer-events: none;
}
/********************************* END SITE-WIDE ELEMENTS */

/********************************* BEGIN FONT ICONS */
@font-face {
    font-family: 'Marketplace';
    src:    url('/Content/fonts/Marketplace.eot?giyy0w');
    src:    url('/Content/fonts/Marketplace.eot?giyy0w#iefix') format('embedded-opentype'),
        url('/Content/fonts/Marketplace.ttf?giyy0w') format('truetype'),
        url('/Content/fonts/Marketplace.woff?giyy0w') format('woff'),
        url('/Content/fonts/Marketplace.svg?giyy0w#Marketplace') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
    margin-right: 5px;
    font-family: 'Marketplace';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    font-size: 16px;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
@media screen and (-webkit-min-device-pixel-ratio:0) {
    @font-face {
        font-family: 'Marketplace';
        src: url('fonts/Marketplace.svg?giyy0w#Marketplace') format('svg');
    }
}

.icon64 { font-size: 64px !important; }
.icon32 { font-size: 32px !important; }

.va-top-icon {
    font-size: 16px;
    vertical-align: text-top;
}

.va-bottom-icon {
    font-size: 16px;
    vertical-align: text-bottom;
}

.va-menu-icon {
    font-size: 14px;
    vertical-align: text-top;
}

.va-link-fake {
    color: #006693;
}

.shrinkFont {
    font-size: .9em;
}
.fontIconLink, .fontIconLink:hover {
    text-decoration: none !important;
}

.numberCircle {
    width: 22px;
    line-height: 22px;
    border-radius: 50%;
    text-align: center;
    font-size: 18px;
    border: 2px solid #666;
    margin-top: 10px;
}

.icon--background-circle-solid {
    display: inline-block;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    color: #ffffff;
    text-align: center;
    padding-top: 1px;
    /* Define the font and other styles in case this is shown in a grid */
    font-family: 'proxima-nova', 'Segoe UI', 'Open Sans', Tahoma, Helvetica, sans-serif;
    letter-spacing: initial;
    text-rendering: optimizelegibility;
    font-size: 1.3em;
}

.cartTile .cartSizeIcon.icon-periodicalsCart00,
.cartTile .cartSizeIcon.icon-periodicalsCart01,
.cartTile .cartSizeIcon.icon-periodicalsCart02,
.cartTile .cartSizeIcon.icon-periodicalsCart03,
.cartTile .cartSizeIcon.icon-periodicalsCart04,
.cartTile .cartSizeIcon.icon-periodicalsCart05,
.cartTile .cartSizeIcon.icon-periodicalsCart06,
.cartTile .cartSizeIcon.icon-periodicalsCart07,
.cartTile .cartSizeIcon.icon-periodicalsCart08,
.cartSizeIconInCartDetails.icon-periodicalsCart00,
.cartSizeIconInCartDetails.icon-periodicalsCart01,
.cartSizeIconInCartDetails.icon-periodicalsCart02,
.cartSizeIconInCartDetails.icon-periodicalsCart03,
.cartSizeIconInCartDetails.icon-periodicalsCart04,
.cartSizeIconInCartDetails.icon-periodicalsCart05,
.cartSizeIconInCartDetails.icon-periodicalsCart06,
.cartSizeIconInCartDetails.icon-periodicalsCart07,
.cartSizeIconInCartDetails.icon-periodicalsCart08 {
    margin-top: 10px;
    font-size: 5.2em;
}

.cartSizeIcon.icon-periodicalsCart00,
.cartSizeIcon.icon-periodicalsCart01,
.cartSizeIcon.icon-periodicalsCart02,
.cartSizeIcon.icon-periodicalsCart03,
.cartSizeIcon.icon-periodicalsCart04,
.cartSizeIcon.icon-periodicalsCart05,
.cartSizeIcon.icon-periodicalsCart06,
.cartSizeIcon.icon-periodicalsCart07,
.cartSizeIcon.icon-periodicalsCart08 {
    margin-top: 10px;
    font-size: 3.4em;
}

/* Go back icon */
.icon-small-arrow-left.icon-small {
    font-size: 10px;
    margin: 0;
}

.font--size-point85em {
    font-size: 0.85em !important;
}

/********************************* BEGIN LOAD SPINNER */

/** Begin fading circle spinner **/
#floatingCirclesG{
margin:0 auto;
position:relative;
width:48px;
height:48px;
transform:scale(0.6);
}
.f_circleG{
position:absolute;
background-color:#CCEEFF;
height:9px;
width:9px;
border-radius:4px;
animation-name:f_fadeG;
animation-duration:1.04s;
animation-iteration-count:infinite;
animation-direction:normal;
}
#frotateG_01{
left:0;
top:20px;
animation-delay:0.39s;
}
#frotateG_02{
left:6px;
top:6px;
animation-delay:0.52s;
}
#frotateG_03{
left:20px;
top:0;
animation-delay:0.65s;
}
#frotateG_04{
right:6px;
top:6px;
animation-delay:0.78s;
}
#frotateG_05{
right:0;
top:20px;
animation-delay:0.91s;
}
#frotateG_06{
right:6px;
bottom:6px;
animation-delay:1.04s;
}
#frotateG_07{
left:20px;
bottom:0;
animation-delay:1.17s;
}
#frotateG_08{
left:6px;
bottom:6px;
animation-delay:1.3s;
}
@keyframes f_fadeG{
0%{background-color:#006693}
100%{background-color:#CCEEFF}
}
/** End fading circle spinner **/


/** Begin shrinking circle spinner **/
/* to resize this spinner, simply set the width and height of .spinner--container */
.spinner--container {
    margin: 0 auto;
    position: relative;
    width: 48px;
    height: 48px;
}

.spinner-container__internal-button-spinner {
    width: 18px;
    height: 18px;
    display: inline-block;
    top: 4px;
    margin-top: -10px;
    margin-left: 2px;
    margin-right: -3px;
}

.spinner--circle {
    position: absolute;
    background-color: #FFFFFF;
    height: 25%;
    width: 25%;
    border-radius: 50%;
    animation-name: spinner_shrink;
    animation-duration: 1.04s;
    animation-iteration-count: infinite;
    animation-direction: normal;
}

.disabled .spinner--circle {
    background-color: #666666
}

.blue .spinner--circle {
    background-color: #006693;
}

.spinner--rotate_01 {
    left: 0;
    top: 37.5%;
    animation-delay: 0s;
    transform:scale(1);
}

.spinner--rotate_02 {
    left: 10.99%;
    top: 10.99%;
    animation-delay: 0.13s;
    transform:scale(0.9);
}

.spinner--rotate_03 {
    left: 37.5%;
    top: 0;
    animation-delay: 0.26s;
    transform:scale(0.8);
}

.spinner--rotate_04 {
    right: 10.99%;
    top: 10.99%;
    animation-delay: 0.39s;
    transform:scale(0.7);
}

.spinner--rotate_05 {
    right: 0;
    top: 37.5%;
    animation-delay: 0.52s;
    transform:scale(0.6);
}

.spinner--rotate_06 {
    right: 10.99%;
    bottom: 10.99%;
    animation-delay: 0.65s;
    transform:scale(0.5);
}

.spinner--rotate_07 {
    left: 37.5%;
    bottom: 0;
    animation-delay: 0.78s;
    transform:scale(0.4);
}

.spinner--rotate_08 {
    left: 10.99%;
    bottom: 10.99%;
    animation-delay: 0.91s;
    transform:scale(0.3);
}

@keyframes spinner_shrink {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(0.6);
    }

    100% {
        transform: scale(0.3);
    }
}
/** End shrinking circle spinner **/

/********************************* END LOAD SPINNERS */


/********************************* BEGIN HEADER */
#headerContent {
    position: relative;
    height: 80px;
}

.marketplaceHeaderLogo {
    margin-left: 1%;
}

.marketplaceHeaderLogo img {
    width: 200px;
    padding-top: 23px;
}

.manageCartsLink a {
    text-transform: uppercase;
    font-weight: bold !important;
    background: #FFBC2B;
    color: #ffffff !important;
    font-size: 1.2em;
    letter-spacing: 1px;
    padding-left: 20px !important;
}

.manageCartsLink a:hover {
    background: #E69500 !important;
}

.pinnedCartName, .pinnedCartPrice {
    display: inline-block;
    max-width: 120px;
}

.dropdown-menu .pinnedCartName, .dropdown-menu.pinnedCartPrice {
    max-width: 138px;
}

.dropdown-menu.pull-left {
    left: 0;
    right: auto;
}

.pinnedCartName {
    font-size: .95em;
    line-height: 14px;
}

.pinnedCartPrice {
    margin-top: -3px;
    font-weight: bold;
    font-size: 1.3em;
}

#cartDropDown, #collectionDropDown, .viewAllCartsBtn {
    border-radius: 0;
}

.headerFlexSettingsRow {
    color: white;
    flex-direction: row-reverse;
    padding-top: 8px;
    padding-bottom: 13px;
}

.headerFlexContentRow {
    padding-left: 18px;
    padding-bottom: 12px;
    padding-right: 18px;
}

/********************************* END HEADER */

/********************************* BEGIN USER ACCOUNT/SETTINGS TOP MENU */

.UserAccountHeader {
    display: flex;
    align-items: center;
    gap: 10px;
}

.UserAccountHeader-menu-pill {
    display: flex;
    align-items: center;
    gap: 1ch;
    background: #2F435C;
    border-radius: 20px;
    padding-inline: 8px;
    padding-block: 4px;

    --UserAccountHeader-menu-pill-color: #fff;
    color: var(--UserAccountHeader-menu-pill-color);
    font-size: 0.875rem;
}

.UserAccountHeader-menu-pill:is(:hover, :active) {
    text-decoration: none;
    color: var(--UserAccountHeader-menu-pill-color);
}

/*Necessary to overwrite styles on .icon-small classes*/
.UserAccountHeader-menu-pill [class^="icon-"] {
    font-size: 14px;
    margin: 0 !important;
    padding: 0 !important;
}

/*Necessary to overwrite styles on .dropdown-toggle class*/
.UserAccountHeader-menu-pill.dropdown-toggle {
    height: unset;
}
/********************************* END USER ACCOUNT/SETTINGS TOP MENU */


/********************************* BEGIN TOP PROFILE */
#topProfilePartial {
    float: right;
    margin-top: 3px;
    line-height: 1.3em;
}

#username {
    max-width: 230px;
}

#headerContent img {
    position: relative;
    float: left;
    border: none;
}


#topProfilePartial .right img {
    margin-left: 8px;
    vertical-align: middle;
}

.cleanOverDiv {
    margin: 0;
    padding: 0;
    border: 0px solid #FFFFFF;
    background: #ffffff;
}

#profileEdit {
    height: 385px;
}

#formatWarning {
    position: fixed;
    width: 500px;
}

#creditDisclaimer, #formatWarning, .formatOverDiv {
    margin: 0;
    padding: 0 10px;
    height: 300px;
    border: 1px solid #999999;
    border-right: none;
    background: #ffffff;
    box-shadow: 0px 0px 15px;
}

.formatOverDiv {
    height: 235px;
    width: 260px;
}

#creditDisclaimer {
    height: 310px;
    width: 260px;
}

#profileEditForm .inputlabel {
    width: 60px;
}

#profileEditForm .inputControl {
    min-height: 34px;
}

#profileEditForm .inputtext, #profileEditForm textarea {
    width: 130px;
}

#profileEditForm input[type="text"], #profileEditForm textarea {
    margin: 0 0 5px 0;
    width: 260px;
    height: 26px;
    border: 1px solid #CCC;
}

#profileEditForm form {
    margin: 0;
}

#profileEditForm .inputfield {
    padding: 0;
}

#profileEditForm .field-validation-error {
    display: block;
    float: left;
    margin: -4px 0 0 0;
}

#profileeditform .input-validation-error {
    border: 1px solid #AE4646;
    background-color: #FEF1EC;
}

.editIcons.left {
    margin: 4px 4px 12px 4px;
}

#scrollbar__resize-listener {
    width: 100%;
    height: 0;
    margin: 0;
    padding: 0;
    overflow: hidden;
    border-width: 0;
    position: absolute;
}

/********************************* END TOP PROFILE */

/********************************* BEGIN RADIO STYLES */

.radio-style__black [type="radio"]:checked,
.radio-style__black [type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}
.radio-style__black [type="radio"]:checked + label,
.radio-style__black [type="radio"]:not(:checked) + label {
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 16px;
    display: inline-block;
    color: #333333;
}
.radio-style__black [type="radio"]:checked + label:before,
.radio-style__black [type="radio"]:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 10px;
    height: 10px;
    border: 2px solid #333333;
    border-radius: 100%;
    background: transparent;
}
.radio-style__black [type="radio"]:checked + label:after,
.radio-style__black [type="radio"]:not(:checked) + label:after {
    content: '';
    width: 6px;
    height: 6px;
    background: #333333;
    position: absolute;
    top: 4px;
    left: 4px;
    border-radius: 100%;
}
.radio-style__black [type="radio"]:not(:checked) + label:after {
    opacity: 0;
    transform: scale(0);
}
.radio-style__black [type="radio"]:checked + label:after {
    opacity: 1;
    transform: scale(1);
}

/********************************* END RADIO STYLES */

/********************************* BEGIN CHECKBOX STYLES */

.checkbox__container--unobtrusive-grey {
    display: block;
    position: relative;
    padding-left: 23px;
    margin-bottom: 12px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}

.checkbox__container--unobtrusive-grey input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.checkmark {
    position: absolute;
    top: 1px;
    left: 0;
    height: 11px;
    width: 11px;
    background-color: white;
    border: 1px solid #999999;
}

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.checkbox__container--unobtrusive-grey input:checked ~ .checkmark:after {
    display: block;
}

.checkbox__container--unobtrusive-grey .checkmark:after {
    left: 4px;
    top: 1px;
    width: 2px;
    height: 6px;
    border: solid #999999;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

/********************************* END CHECKBOX STYLES */

/********************************* BEGIN MAIN TABS */
/********************************* END MAIN TABS */


/********************************* BEGIN NAVBAR */
#navBar {
    float: left;
    overflow: hidden;
    margin: 0 5px 1px 0;
    padding: 10px 0;
    max-height: 25px;
    width: 100%;
    color: #ffffff;
    vertical-align: top;
    text-align: right;
    font-size: .9em;
}

    #navBar a {
        color: #ffffff;
}

#marketPlaces {
    font-weight: normal;
}

    #marketPlaces a {
        margin: 0;
        padding: 0;
        text-decoration: none;
    }

    #marketPlaces li a {
        padding: 4px 7px;
        color: #333333;
        text-transform: initial !important;
        transition: none;
    }

    #marketPlaces li:hover a {
        color: #ffffff;
    }

    #marketPlaces #marketPlacesNote {
        background: #f0f0f0;
        padding-left: 7px !important;
        cursor: default;
    }

    #marketPlaces #marketPlacesNote p {
        color: black;
        display: block;
    }

    #marketPlaces #marketPlacesNote a {
        padding: initial !important;
        color: #006693;
    }

    #marketPlaces #marketPlacesNote a:hover {
        color: #0A2240;
        text-decoration: underline;
        cursor: pointer;
    }

    #marketPlacesNote .purchasingToolsNoteBody {
        width: 230px;
        white-space: initial;
    }
    #marketPlacesNote .purchasingToolsNoteBody a {
        display: initial;
    }

span.lendingModelName {
    text-transform: none !important;
    font-size: .8em;
}

.insight-reports {
    text-align: center;
    line-height: 4px;
    display: block;
}

.insight-navbar {
    display: block;
    font-size: .8em;
    text-transform: initial;
    text-align: center;
    line-height: 0px;
    margin-top: 12px;
    font-style: italic;
}

/********************************* END NAVBAR */

/********************************* BEGIN SUB-NAVIGATION */


.sub-menu__navigation--tabs {
    display: block;
    margin-top: -20px;
    margin-bottom: 30px;
    padding-bottom: 0px;
    height: 52px;
    width: 100%;
    min-width: 960px;
    background: #F7F7F7;
    border-bottom: 2px solid #EEEEEE;
}

.sub-menu__navigation--tabs li {
    display: inline-block;
    margin: 12px 14px 0 0;
    height: 25px;
    font-size: 1.05em;
}

.sub-menu__navigation--tabs li:first-of-type {
    margin-left: 1%;
}

.sub-menu__navigation--tabs li.active {
    padding: 8px 10px 7px 10px;
    border: 2px solid #EEEEEE;
    border-bottom: 0;
    border-radius: 5px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    background: #ffffff;
}

.sub-menu__navigation--tabs li a {
    height: 100%;
    display: block;
    width: 100%;
    margin-top: 3px;
    text-decoration: none !important;
    transition: all 0.1s ease-in-out;
}

.sub-menu__navigation--tabs li.active a {
    font-weight: bold;
    color: #0A2240;
    cursor: default;
}

.sub-menu__navigation--quick-select {
    width: 100%;
    min-width: 960px;
    height: 25px;
    border-bottom: 2px solid #EEEEEE;
    margin-bottom: 30px;
}

.sub-menu__navigation--quick-select li {
    display: inline-block;
    margin: 0 2px 0 2px;
    padding: 0 12px;
    font-size: 1.05em;
    height: 100%;
    cursor: pointer;
}

.sub-menu__navigation--quick-select li.navigation__label {
    margin-right: 16px;
    padding: 0;
    color: #666666;
    font-size: .95em;
    cursor: default;
}

.sub-menu__navigation--quick-select li.active {
    cursor: default;
    border-bottom: 2px solid #0A2240;
    height: calc(100% - 2px);
}

.sub-menu__navigation--quick-select li a {
    height: 100%;
    display: block;
    width: 100%;
    text-decoration: none;
    transition: all 0.1s ease-in-out;
}

.sub-menu__navigation--quick-select li.active a {
    font-weight: bold;
    color: #0A2240;
    cursor: default;
}

/********************************* END SUB-NAVIGATION */

/********************************* BEGIN BODYCONTENT */
.middleContent, .middleContent.withLeft, .middleContent.withRight {
    margin: 0;
    padding: 0 0 10px 0;
    /*overflow-x: hidden;*/
}

    .middleContent.withLeft {
        /* Note: If you ever change the sidebar width, change the left padding value here to compensate */
        padding: 0 0 10px 250px;
    }

        .middleContent.withLeft.withRight {
            padding: 0 15.75% 10px 200px;
            min-height: 525px;
        }

    .middleContent.news-partial,
    .middleContent.support-partial,
    .middleContent.arch-alert-partial,
    .middleContent.partial-1280 {
        margin: 0 auto;
        max-width: 1280px;
        position: relative;
    }

#leftSideBar, .search-sidebar__scrolling {
    position: absolute;
    top: 0;
    z-index: 300;
    min-height: 100%;
    /* Note: If you change this width, change the left padding value on .middleContent.withLeft to compensate */
    width: 240px;
    height: 100%;
}

#leftSideBar {
    left: -10px;
    background: #ffffff;
}

#leftSideBar.localContent { top: 40px; }

.search-sidebar__scrolling {
    right: 0;
    min-width: 144px;
    width: 15.125%;
}

.survey-link {
    margin: 0 0 5px 8px;
    width: 180px;
    height: 150px;
    background-color: #EEE;
}

/********************************* END BODYCONTENT */


/********************************* BEGIN FOOTER COMPONENTS */
#footerContainer {
    text-align: center;
    font-size: 1em;
    font-family: Arial, Helvetica, Sans-Serif;
    line-height: normal;
    orphans: 2;
}

#footerlinks a.level1 {
    text-decoration: none;
    font-weight: bold !important;
    font-size: small;
}

#footerlinks a.level2 {
    padding: 0;
    text-decoration: none;
    font-size: smaller;
}

.footerSeparator {
    float: left;
    margin: 0px 30px;
}

#copyright {
    float: left;
    clear: both;
    font-size: .9em;
}

#copyrightCenter {
    clear: both;
    font-family: Arial, Helvetica, sans-serif;
}

.copyrightCenter {
    padding: 20px;
    text-align: center;
}

#copyright a {
    text-decoration: none;
}

    #copyright a:hover {
        text-decoration: underline;
    }

.footerLogo {
    float: left;
}

.footerNav_category {
    margin: 0px;
    height: 15px;
    list-style: none;
    text-align: left;
    font-weight: bold;
    font-size: 9pt;
    font-family: Arial, Helvetica, Tahoma, sans-serif;
}

    .footerNav_category a {
        text-decoration: none;
    }

        .footerNav_category a:hover {
            text-decoration: none;
        }

.footerNav_btn {
    width: 147px;
}

    .footerNav_btn:hover {
        background-color: #3167a9;
    }

.disclaimer {
    min-width: 900px;
    height: 30px;
    font-size: .85em;
}

.privacy {
    float: right;
}

.powerLogo {
    margin-right: 500px;
}

.clearfooter {
    clear: both;
    height: 170px;
}
/********************************* END FOOTER COMPONENTS */


/********************************* BEGIN ERRORS */
.selectAll /* Select all div (error pages) */ {
    float: right;
    margin-right: 6px;
    text-align: right;
}

a.selectAllLink /* Select all div (error pages) */ {
    font-size: .7em;
}

.outerErrorDiv /* Outer container div for errors */ {
    float: left;
    width: 100%;
    font-size: .9em;
}

.innerErrorDiv /* Inner container div for errors */ {
    float: left;
    width: 10%;
    text-align: right;
}
/********************************* END ERRORS */


/********************************* BEGIN LOGIN PAGE */
.loginContainer {
margin: 0 auto;
width: 80%;
min-width: 920px;
max-width: 1110px;
}
.applyContainer table, .loginContainer table {
    width:100%;
}
.applyContainer td, .loginContainer td {
    vertical-align:top;
}
.applyContainer td {
    padding-right: 75px;
}
.applyContainer h1, .loginContainer h1, #modal #loginForm h1 {
    color: #D02C50;
    font-size: 2em;
    margin-top: 0;
}
#loginForm form {
    width:100%;
}
#loginForm .editor-field {
    margin-bottom: 10px;
}
#loginForm label {
    margin-bottom: 5px;
}
#loginForm input[type="text"], #loginForm input[type="password"] {
    width: 98%;
    border: 1px solid #CCC;
    padding: 2px 0px 2px 2px;
}
#loginForm input[type="text"].input-validation-error,
#loginForm input[type="password"].input-validation-error, .billingAccountControls, .billingInfo {
    border: 1px solid #D02C50;
    background-color: #FEF1EC;
}
.billingAccountControls {
    width: 800px;
}

.loginError {
    position:relative;
}

.loginError .field-validation-error, .badLoginWarningIcon {
    font-size: .9em;
    padding: 5px 2px;
    color: #ffffff;
    background: #AE4646;
    width: 100%;
    display: block;
    text-align: center;
}

    .badLoginWarningIcon {
        display:none;
    }

    .field-validation-error ~ .badLoginWarningIcon  {
        display: block !important;
        position: absolute;
        left: 2%;
        top: 0;
        width: auto;
}

.adSpace {
    padding-top: 5%;
}

.adSpace iframe {
    margin-top: -2px;
    width: auto !important;
    border: 1px solid #eeeeee;
}
.uprival-ad {
    margin: 0;
    padding: 0;
    line-height: 0;
}

.partner-portal {
    padding: 5px 10px;
    background: #006593;
    margin: 48px 0;
    font-size: 1.5em;
    text-align: center;
    color: #FFF;
}

.partner-portal span a {
    color: #FFF;
}

/********************************* END LOGIN PAGE */


/********************************* BEGIN ACCOUNT SETTINGS */
a.headerLink, span.headerActive {
    float: left;
    margin: 0 10px 0 0;
    padding: 2px 10px;
    border-radius: 3px;
    background: #666666;
    color: #ffffff;
    font-weight: bold;
    font-size: 1.2em;
}

span.headerActive {
    background: #006693;
}

a.headerLink:hover {
    background: #006693;
}
/*Container div for account settings*/
#UsersInfo table th {
    padding-left: 20px;
    text-align: left;
}

#UsersInfo table td {
    padding: 2px 6px 2px 20px;
}

#UsersInfo table .odd {
    background-color: #444444;
}

#UsersInfo table .even {
    background-color: #3A3A3A;
}

#UsersInfo table {
    border-collapse: collapse;
}
/* Div for Create New User form controls */

.profileFormDiv li {
    clear: both;
    padding: 0 0 40px 0;
    list-style: none;
}

.profileFormDiv select {
    float: left;
    width: 180px;
}

.profileFormDiv label {
    float: left;
    width: 249px;
}

    .profileFormDiv label img {
        height: 45px;
    }

.profileFormDiv li div {
    float: left;
    margin-left: 10px;
}

.profileFormDiv .profileEditLink {
    width: 10px;
    color: #006693;
    cursor: pointer;
}

    .profileFormDiv .profileEditLink:hover {
        color: #006693;
    }

.profileFormDiv .profileEditLinkDisabled {
    margin-right: 12px;
    width: 10px;
    color: #CDCED0;
    font-style: italic;
}

.profileFormDiv .editLinksDiv {
    display: none;
    margin: 0px;
}

#txtLibraryName, #txtMainContact, #txtAddress, #txtPhoneNumber, #txtField1, #txtField2, #txtField3, #txtField4 {
    display: none;
    float: left;
}

/********************************* END ACCOUNT SETTINGS */


/********************************* BEGIN CRITICAL ALERTS */

.icon-alert {
    margin-left: 2px;
    margin-right: -7px;
}

/********************************* END CRITICAL ALERTS */


/********************************* BEGIN ICON MENU */
.generalContainer, .asideContainer, .selectExpressContainer, .localContentContainer, .marcContainer, .reportsContainer {
    margin: 0 30px 25px 0;
    width: 60%;
}

.generalContainer.general-element-newsalert {
    width: initial;
}

.general-element-newsalert {
    max-width: 1280px;
    margin-right: 380px;
    float: left;
}

.grouping {
    display: inline-block;
    margin: 0 0 8px 0;
    padding: 5px 10px 10px 10px;
    width: 100%;
    border-radius: 5px;
    background-color: #F4F4F4;
}

.asideContainer.general-right-element {
    position: absolute;
    right: 0;
}


.asideContainer {
    float: right;
    width: 30%;
}

.support-partial .asideContainer,
.news-partial .asideContainer,
.arch-alert-partial .asideContainer{
    max-width: 320px;
    position: relative;
}

.support-partial .generalContainer {
    width: 70%;
    margin-right: 30px;
}

.news-suggestedAd {
    padding: 0 10px 0 10px;
}

.asideContainer .grouping {
    background-color: #FFFFFF;
    border: 1px solid #e2e2e2;
    cursor: default;
}

.selectExpressContainer, .localContentContainer, .marcContainer, .reportsContainer {
    margin: 0;
    width: 50%;
}
.selectExpressContainer .grouping, .localContentContainer .grouping, .marcContainer .grouping, .reportsContainer .grouping {
        margin: 0 12px 25px 0;
        width: auto;
    }

    .reportsContainer .grouping {
        width: 94%;
    }

        .reportsContainer .grouping ul, .generalContainer .support.grouping ul {
            width: 100%;
        }

        .reportsContainer .grouping li {
            padding-bottom: 13px;
            padding-top: 7px;
            border-bottom: 1px solid #e6e6e6;
        }

            .reportsContainer .grouping li:last-child {
                border-bottom: none;
            }

.generalContainer .support.grouping li {
    display: inline-block;
    width: 100%;
}
.support-pg-list {
    margin-left: 90px;
    display: inline-block;
    width: 100%;
    padding-top: 24px;
}
.support-pg-icons {
    position: absolute;
    float: left;
    color: #006693;
    vertical-align: text-top;
    margin: 24px 20px 10px 0;
}

.support-pg-list .grid__row {
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #ddd;

    display: flex;
    align-items: center;
}

.support-pg-list .grid__row:last-child {
    border-bottom: none;
}

.support.grouping {
    padding: 24px 0 24px 24px;
    background-color: #ffffff;
    border: 1px solid #ddd;
    margin-top: 4px;
}

.support.grouping.grid__container-div:first-of-type {
    margin-top: 0;
}

.support-contactbox-header {
    background-color: #006693;
    border-radius: 5px 5px 0 0;
}

.grouping.support-sidebar {
    padding: 0 0 5px;
    margin: 0;
}

.support-primarycontact {
    text-align: center;
    line-height: 20px;
}

.grouping img.support-primarycontact {
    float: none;
}

.support-contact-container {
    margin: 16px;
}

.support-contact-container img {
    border-radius: 50%;
    border: 1px solid #ddd;
}

.support-contactbox-header h2 {
    color: #ffffff;
    padding: 12px 0 8px 12px;

}

.support-contact-line {
    border-bottom: 1px solid #ddd;
    margin: 8px 0;
}

.support-contact-name {
    font-weight: bold;
    font-size: 1.1em;
}

.support-contact-doublequote {
    font-size: 4em;
    margin: 25px 0 0;
    height: 10px;
}

.quick-help-list li {
    padding: 4px 0;
}

.selectExpressContainer {
    width: 100%;
}

.localContentContainer {
    margin: 0px 12px 0 0;
    width:47%;
}

.selectExpressContainer .grouping, .localContentContainer .grouping {
    margin:25px 0 0 0;
    width:98.5%;
}

.selectExpressContainer.right, .localContentContainer.right {
    float:right !important;
    margin: 0 0 0 12px;
}

.selectExpressContainer.right .grouping, .localContentContainer.right .grouping {
    float:right !important;
}

.selectExpressSelectionTextSub {
    margin-left: 30px;
}

.marcContainer .grouping {
    height: 325px;
}

.grouping :where(h1, h2, h3, h4, h5) {
    margin-top: 0;
}

.selectExpressContainer a:first-child .clickable.grouping {
    margin-top: 0;
}

.automate-carts-icons {
    display: inline-block;
    float: left;
    color: #006693;
    vertical-align: text-top;
    margin: 0 20px 10px 0;
}

.local-content-icons {
    display: inline-block;
    float: left;
    color: #006693;
    vertical-align: text-top;
    margin: 0 10px 10px 0;
}

.automate-carts-group p {
    margin: 10px 0;
    margin-left: 84px;
}
.automate-carts-group p:first-child {
    margin: 0;
}

.heading-icons {
    float: left;
    display: inline-block;
    margin: 6px 6px 6px 0;
    color: #0A2240;
    font-size: 24px;
}

.clickable.grouping:hover {
    background-color: #E2E2E2;
}

.clickable.grouping a, .clickable.grouping a:hover, .clickable.grouping a:link, .clickable.grouping, .clickable.grouping:hover {
    padding: 5px 10px;
    color: #333333 !important;
    }

.contactListInfo {
    display: inline-block;
    width: 216px;
}

.contactListInfo a {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    }

.socialLinks {
    display: block;
    margin-bottom: 5px;
    height: 27px;
}

.social {
    display: inline-block;
    vertical-align: top;
    font-size: 1.1em;
    margin-top: 4px;
}

.social-icons {
    display: inline-block;
    margin-bottom: 8px;
    height: 28px;
    width: 28px;
    font-size: 28px;
}

.icon-twitter.social-icons {color: #00aced;}
.icon-facebook.social-icons {color: #3b5998;}
.icon-blog-rss.social-icons {color: #f26522;}
.icon-pinterest.social-icons {color: #cb2027;}
.icon-instagram.social-icons {color: #c32aa3;}

.grouping img {
    float: left;
    margin: 0 10px 10px 0;
    background-repeat: no-repeat;
}

.alert-icon {
    float: left;
    width: 24px;
    height: 24px;
    font-size: 24px;
    margin-top: 2px;
    color: #FFBC2B;
    margin-right: 8px;
}

/********************************* END ICON MENU */


/********************************* BEGIN MARKETPLACE */
#carousels {
    clear: both;

}

#carousels .withBottomPad{
    padding-bottom: 120px;
}

    #showMore3 {
    margin-bottom: 152px;
}

#carousels h2 {
    margin: 10px 0 0 0;
    font-weight: bold;
    font-family: 'FreightTextProBook-Regular', sans-serif;
}

    #carousels h2 a {
        margin: 2px 10px 0 0;
        color: #006693;
        font-weight: 500;
        vertical-align: text-top;
        width: 24px;
        height: 24px;
    }

        #carousels h2 a:hover {
            color: #006693 !important;
        }

.featuredList {
    margin-right: 10px;
}

    .featuredList ul {
        float: left;
        list-style: none;
    }


.featured-list__loading-error {
    margin-left: 2px;
}

.jcarousel-clip-horizontal, .jcarousel-list.jcarousel-list-horizontal, .jcarousel-list, .jcarousel-skin-tango .jcarousel-clip {
    overflow: visible !important;
}

.featuredTitle {
    position: relative;
    float: left;
    margin: 0px auto;
    padding: 0;
    width: 220px;
}

    .featuredTitle .featuredTitleImg img {
        float: left;
        margin-right: 8px;
        width: var(--carousel-item-width);
        border: 1px solid #CFCFCF;
        line-height: 15px;
        min-height: 265px;
        max-height: 270px;
        box-sizing: border-box;
    }

    .featuredTitle ul li {
        text-decoration: none;
    }

    .featuredTitle ul li .price {
        font-size: 20px;
    }

    .featuredTitle a .featuredTitle img img:hover {
        opacity: .8;
    }

.bookTitle {
    margin: 0;
    width: 245px;
    font-weight: bold;
    font-size: 16px;
}

.featuredTitleDetails p.bookTitle {
    margin: 0;
    width: 200px;
    color: #006693;
    font-weight: bold;
}

.featuredTitleDetails {
    float: left;
    width: 200px;
}

.featuredTitleDetails p {
    margin-top: 0;
}

.featuredTitleDetails ul {
    margin: 0;
    padding: 0;
    width: 200px;
}

.featuredTitleDetails li {
    clear: both;
}

    .featuredTitleDetails li.subtitle, .featuredTitleDetails li.series, .featuredTitleBuyButton li.series {
        font-size: 12px;
    }
    .featuredTitleDetails li.creator {
        font-size: 14px;
        float: left;
        margin: 2px 0;
    }

.featuredTitleDetails li a.ellipsis { width:100%; }

.featuredTitleDetails ul li.creator a.titleDetailsLink {
    line-height: 12px;
}

.titleDetailsLink {
    font-size: 14px;
}

.viewTitleDetailsInfo {
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: .85em;
    }

.featuredTitleBuyButton {
    float: left;
    clear: both;
    width: var(--carousel-item-width);
    margin: 10px 0;
}

.featuredTitleBuyButton .addToCartContainer div.btn-group {
    width: 100%;
}

.featuredTitleBuyButton .addToCartContainer div.btn-group .dropdown-menu {
    left: 0;
}

.featuredTitleBuyButton .addToCartContainer a.addToCart,
.featuredTitleBuyButton .addToCartContainer a.disabledAddToCart {
    font-size: 14px;
    width: 156px;
    margin-left: 0;
}

.featuredTitleBuyButton .addToCartContainer .dropdown-menu a.addToCart,
.featuredTitleBuyButton .addToCartContainer .dropdown-menu a.disabledAddToCart {
    width: unset;
    text-align: right;
}

.atc-complete-inactive .addToCartContainer a.dropdown-toggle {
    z-index: 3;
}

.featuredTitleBuyButton .addToCartContainer a.dropdown-toggle {
    border-left: 1px solid #AAA;
    margin-left: -2px;
}

a.showMore {
    font-weight: bold;
    font-size: 1em;
}

#UploadHelp ul.bulletedList {
    text-align: left;
}

.open > .dropdown-menu.format-details-drpdwn,
.open > .dropdown-menu.format-results-drpdwn {
    padding: 0;
    width: 100%;
}

.open > .dropdown-menu.format-results-drpdwn ul,
.open > .dropdown-menu.format-details-drpdwn ul {
    padding: 5px;
    float: left;
    margin: 0;
}

.open > .dropdown-menu.format-results-drpdwn ul li span,
.open > .dropdown-menu.format-details-drpdwn ul li span {
    font-size: 13px;
    color: #006693;
    display: block;
    padding: 3px 0;
}

.open > .dropdown-menu.format-results-drpdwn ul li span:hover,
.open > .dropdown-menu.format-details-drpdwn ul li span:hover {
    color: #006693;
    text-decoration: underline;
}

/********************************* END MARKETPLACE */

/********************************* BEGIN SEARCH RESULTS */

ul.details.left {
    margin: 6px 0 0 2px;
    width: 38%;
}

#listViewTitles {
    float: left;
    margin: 0;
    padding: 0;
    width: 100%;
    list-style: none;
}

#listViewTitles hr {
    margin: 0 auto;
    padding: 0;
    width: 98.5%;
    height: 1px;
    border-style: none;
    background-color: #E2E2E2;
}

.quantityBox {
    float: left;
    margin: 0 9px 0 7px;
    width: 25px;
    border: 1px solid #999999;
    text-align: right;
}

    .quantityBox.all {
        float: right;
    }

.unitsBox .quantityBox-checkouts {
    background-color: #ddd;
    color: #666;
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
    border: 1px solid #999999;
    border-left: none;
    height: 19px;
    line-height: 19px;
    margin-top: 2px;
    padding: 2px 5px 2px 0;
    float: right;
}

.unitsBox :where(input[type="text"]) {
    padding: 1px 8px;
}

.viewtitleDetailsTitle {
    margin: 0;
    padding: 0;
    font-weight: bold;
    font-size: 16px;
}

    .viewTitleDetailsInfo.subTitle {
        font-weight: bold;
        font-size: .95em;
    }

.localContentActions {
    margin-bottom: 3px;
    width: 215px;
    width:auto;
    float:right;
}

.localContentActions .button {
    margin-left: 6px;
}

    .details.right .localContentActions {
        bottom: -25px;
    }

.holdings-attn {
    display: inline-block;
    border: 1px solid #006693;
    background-color: #006693;
    width: 6px;
    height: 6px;
    border-radius: 10px;
}

    .holdings-attn.attn-total {
    border: 1px solid #008000;
    background-color: #008000;
    margin-right: 3px;
    }

    .total-checkouts-toggle.specialFeature:hover {
    color: #008000;
    }

.availabilityWidth {
    display: inline-block;
}

.addAllToCart,
.addAllToCollection {
    position: absolute;
    top: 50%;
    right: 6px;
    margin-top: -15px;
    height: 30px;
}

.addToCartContainer :where(span),
.addToCollectionContainer :where(span) {
    float: left;
    padding: 5px 0;
}

.addToCartContainer span.addToCartDropdownIcons,
.addToCollectionContainer span.addToCartDropdownIcons {
    margin-left:-18px;
    padding:0;
    margin-top: 3px;
}

.addToCart:hover .addToCartDropdownIcons,
.addToCollection:hover .addToCartDropdownIcons,
.addToNewCollection:hover .addToCartDropdownIcons {
    color: #ffffff;
}

.formatPreviewDialog.ui-dialog-content.ui-widget-content a img {
    position: relative;
    top: 3px;
}

.formatPreviewDialog.ui-dialog-content.ui-widget-content a {
    margin-right: 6px;
}

/********************************* END SEARCH RESULTS */


/********************************* BEGIN TITLE DETAILS */
#top, #bottom {
    float: left;
    padding: 10px 0;
    width: 100%;
    position: relative;
}

    #top h1, #top div.left h2 {
        margin: 0;
    }

    #top .addToCartContainer input[type="text"] {
        float: left;
        margin: 2px 6px 0px 6px;
        width: 25px;
        height: 21px;
    }

#prevNext {
    width: 200px;
}

.cell input[type="button"].BtnAction.right,
.cell input[type="submit"].BtnAction.right,
.cell input[type="button"].BtnAction,
.cell input[type="submit"].BtnAction {
    margin: 3px 20px 0px 4px;
}

.coverJacket {
    width: 250px;
    border: 1px solid #e2e2e2;
    }

.result-num {
    float: right;
    margin-left: 10px;
}

.details.right .detailsList li.right {
    margin: 15px 0;
}

table.titleDetails {
    margin: 10px 0 0 0;
    padding: 0;
}

    table.titleDetails td {
        vertical-align: top;
    }

        table.titleDetails td:first-child {
            width: 120px;
        }

.weeded, .unavailable {
    margin-top: 7px;
}

.cell {
    display: inline;
    margin-left: 100px;
    padding: 0;
}

    .cell img {
        margin-right: 6px;
    }

#top {
    display: flex;
    flex-wrap: wrap;
}

#top .details.left, #top .details.right {
    margin: 0 10px 0 0;
}

#top .details.left {
    margin: 0;
    flex: 1 1 0px;
}

#top .details.right {
    margin: 0;
}

.details.right {
    margin: 10px 0 0 0;
    width: auto;
}

.details.left .detailsList {
    margin: 10px 0 0 0;
}

.special-feature {
    color: #FF8000;
}

.btn-group-specialFeature .btn.specialFeature {
    float: left;
    margin: 5px 0 0 0;
    padding: 4px 8px ;
    height: auto ;
    /* width: 200px; */
    border: 1px solid #FF8000;
    background: #fff ;
    color: #FF8000;
    text-align: center ;
    font-size: 12px ;
    font-weight: normal;
    text-shadow: none ;
    box-shadow: none ;
    border-radius: 3px ;
    white-space: normal ;
    line-height: 12px ;
    text-transform: uppercase;
    transition: all 0.2s ease-in-out;
}

.btn-group-specialFeature .btn.specialFeature span {
    font-size: 14px;
    vertical-align: text-bottom;
}

.total-checkouts-toggle.specialFeature {
margin: -2px 0 1px 0;
width: 170px;
border: none !important;
text-align: center !important;
font-size: 12px !important;
font-weight: bold;
text-shadow: none !important;
box-shadow: none !important;
border-radius: 0 !important;
white-space: normal !important;
line-height: 14px !important;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

.btn-group-specialFeature .btn.specialFeature:hover {
    background-color: #FF8000;
    color: #FFF;
}

.btn-group-specialFeature.btn-group.open .btn.dropdown-toggle.specialFeature {
    background-color: #FF8000;
    color: #FFF;
}

.btn-group-specialFeature.btn-group.open .dropdown-menu {
    left: 0;
    right: inherit;
}

.showMeInline .btn-group-specialFeature.btn-group.open .dropdown-menu {
    width: 170px;
    right: -10%;
    left: inherit;
    top: 20px;
}

.lendingExpire {
    display: inline-block;
    margin: 0;
    padding: 4px;
    background: #FFF;
    color: #006693;
    text-align: center;
    font-weight: normal;
    font-size: 12px;
    border: 1px solid #006693;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

.lendingExpire:hover {
    color: #FFF;
    background: #006693;
}

    .lendingExpire.textLeft {
        float: left;
        padding: 2px 5px;
        width: 175px;
        border: none;
        background: #fef1ec;
        color: #d02c50;
        margin-top: 6px;
    }

    .lendingExpire.textLeft.is-preorder {
        width: 75%;
        min-width: 450px;
        cursor: auto;
    }

    .lendingExpire.textLeft.is-preorder.preorder-text-results {
        width: 100%;
        min-width: 280px;
    }


.single-lending-model {
    color: #FFF;
    background: #006693;
    cursor: default;
}

.multi-lending-model {
    margin-left: 4px;
}

.lendingExpire :where(span) {
    display: block;
    clear: both;
}

.lendingExpire.special-message {
    margin: 0 0 10px 0;
    background: #FFF;
    color: #FF8000;
    font-weight: bold;
    font-size: 14px;
    border: none;
    text-align: right;
}

.details .lendingExpire.special-message {
    margin-top: -10px;
}

.lendingExpire.special-message:hover {
    color: #FF8000;
    background: #FFF;
}

div.macmillan-terms__unit-restriction {
    margin: 5px 0 5px 0;
    padding: 3px 8px 2px 8px;
    max-width: 150px;
    height: auto;
    white-space: normal;
}

.macmillan-terms__unit-restriction-note {
    margin: 0 0 10px 10px;
}

.macmillan-terms__unit-restriction-note a.macmillan-terms__link {
    padding: 0;
    color: #006693;
    display: inline;
}

.macmillan-terms__unit-restriction-note a.macmillan-terms__link:hover {
    text-decoration: underline;
}

.limitedKindle {
    color: #AD8B35;
}

.btn-group > .btn.limitedKindle:first-child {
    margin: 5px 0 5px 0;
    padding: 3px 8px 2px 8px;
    border: 1px solid #AD8B35;
    background-color: #FFF;
    color: #AD8B35;
    text-align: center;
    font-size: 12px;
    font-weight: normal;
    text-shadow: none;
    box-shadow: none;
    transition: all 0.2s ease-in-out;
}
.btn-group > .btn.limitedKindle:first-child:hover {
    text-decoration:none;
    background-color: #AD8B35;
    color: #FFF;
}

.lcFailedMessage, .lcProcessingMessage {
    float: right;
    margin: 10px 0 5px 0;
    padding: 2px;
    width: 200px;
    text-align: left;
    font-weight: normal;
    font-size: .85em;
    border: 1px solid #E6C87E;
    background: #FCE4A9;
    color: #AD8B35;
}

.lcFailedMessage {
    border: 1px solid #d02c50;
    background: #fef1ec;
    color: #d02c50;
}

.holdings {
    clear: both;
    width: 100%;
    text-align: right;
}

#bottom .ui-widget-content {
    border: none;
    color: #333333;
    font-size: 1em;
    margin-top: 10px;
}

#bottom #TitleDetailsTabs.ui-widget-content {
    margin-right: 320px;
    min-height: 250px;
}

#bottom .ui-tabs-nav.ui-helper-reset.ui-helper-clearfix.ui-widget-header.ui-corner-all,
#bottom .ui-state-default.ui-corner-top.ui-tabs-selected.ui-state-active,
#bottom .ui-state-default.ui-corner-top {
    margin: 0 0 1px 0;
    padding-left: 0;
    background: none;
    border-bottom: 1px solid #dddddd;
}

#bottom .ui-tabs .ui-tabs-nav li.ui-tabs-selected a,
#bottom .ui-tabs .ui-tabs-nav li.ui-state-disabled a,
#bottom .ui-tabs .ui-tabs-nav li.ui-state-processing a,
#bottom .ui-state-default.ui-corner-top a {
    display: inline-block;
    float: left;
    margin: 0px;
    padding: 4px 20px;
    color: #333333;
    transition: all 0.1s ease-in-out;
    border: 0;
    vertical-align: middle;
    text-align: center;
    text-decoration: none;
    line-height: 20px;
    cursor: default;
    border-bottom: 2px solid #ffffff;
}

    #bottom .ui-state-default.ui-corner-top a {
    padding: 4px 20px;
    border: none;
    background: none;
    box-shadow: none;
    color: #3399C6;
    text-shadow: none;
    font-weight: bold;
    font-size: 1.4em;
    border-bottom: 2px solid #ffffff;
    }

        #bottom .ui-state-default.ui-corner-top a:hover {
            color:#006693;
        }

        #bottom .ui-tabs .ui-tabs-nav li.ui-tabs-selected a,
        #bottom .ui-tabs .ui-tabs-nav li.ui-state-active a {
            background: none;
            color: #006693;
            border-bottom: 2px solid #006693;
        }

#bottom li.ui-tabs-selected:first-child a,
#bottom li.ui-state-disabled:first-child a,
#bottom li.ui-state-processing:first-child a,
#bottom .ui-tabs-nav li:first-child a {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
}

#bottom li.ui-tabs-selected:last-child a,
#bottom li.ui-state-disabled:last-child a,
#bottom li.ui-state-processing:last-child a,
#bottom .ui-tabs-nav li:last-child a {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}

#bottom li.ui-tabs-selected,
#bottom li.ui-state-disabled,
#bottom li.ui-state-processing,
#bottom .ui-tabs-nav li {
    margin: 0;
}

#bottom .ui-tabs .ui-tabs-nav li.ui-tabs-selected a.foreverAlone {
    padding: 0px;
    border: none;
    background: none;
    box-shadow: none;
    color: #003D86;
    text-shadow: none;
    font-weight: bold;
    font-size: 1.2em;
}

.panel strong {
    font-weight: normal;
}

/********************************* END TITLE DETAILS */


/********************************* BEGIN LOGIN */
#LoginApply, .resetPassword {
    clear: both;
    margin: auto;
    width: 600px;
}

#Apply h1, #Login h1, #Reset h1 {
    color: #d02c50;
    font-weight: bold;
    font-size: 2em;
}

#Login {
    float: left;
    padding-right: 50px;
    width: 250px;
    border: none;
}

#Reset .inputField {
    width: 250px;
}

#LoginApply #Login {
    border-right: 1px solid #cccccc;
}

.loginField {
    margin: 10px 0 25px 0;
}

.detailForm.login .inputLabel {
    width: 110px;
}

.detailForm.login .inputField {
    padding: 0;
}

.detailForm.login input[type="text"], .detailForm.login input[type="password"] {
    float: right;
    margin: 0 0 2px 0 !important;
    width: 130px;
    border: 1px solid #CCCCCC;
}

#Login .inputControl {
    margin-bottom: 5px;
    width: 130px;
    height: 36px;
}

#BtnLogin {
    float: right;
    margin: 0px;
    padding: 0px;
    width: 250px;
}


.field-validation-valid {
    display: none;
}

.validation-summary-valid {
    display: none;
}

.field-validation-error {
    color: #AE4646;
    font-size: 11px;
}

.detailForm.login input[type="text"].input-validation-error,
.detailForm.login input[type="password"].input-validation-error,
.detailForm input.input-validation-error,
.checkboxList.input-validation-error,
.input-validation-error {
    border: 1px solid #AE4646 !important;
    background-color: #fef1ec !important;
}

.validation-summary-errors {
    color: #AE4646;
    font-weight: bold;
}

.featuredList .price {
    color: #0A2240;
    font-weight: bold;
}

.featuredList .format {
    margin-top: 4px;
    color: #7E7E7E;
    font-size: 1em;
    line-height: 20px;
}

.featuredList .formatClass {
    float: left;
    width: 31px;
    height: 24px;
    opacity: .6;
}

#Apply {
    float: right;
    width: 250px;
}

    #Apply ul {
        margin: 0px;
        padding-left: 0px;
        list-style: none;
    }

    #Apply li {
        display: inline-block;
        float: left;
        padding-right: 15px;
    }
/********************************* END LOGIN */


/********************************* BEGIN BUTTONS */

/*** button reset ***/
.button {
    display: inline-block;
    margin: 0;
    padding: 0;
    text-decoration: none;
    text-align: center;
    letter-spacing: initial;
    cursor: pointer;
    text-transform: uppercase;
    transition: all 0.1s ease-in-out;
}
.button:hover {
    text-decoration: none;
}
.button:focus {
    outline: #74CEE2 auto 4px;
}
/*** button reset ***/


.button, .ui-dialog-buttonset button {
    margin: 0 2px;
    padding: 6px 10px;
    border-radius: 3px;
    font-family: 'proxima-nova', 'Segoe UI', 'Open Sans', Tahoma, Helvetica, sans-serif;
    text-transform: uppercase;
    color: #FFF;
    transition: all 0.1s ease-in-out;
}

.button span[class^="icon-"] {
    vertical-align: text-top;
}



.button:first-child {
    margin-left: 0px;
}

.ui-dialog .ui-dialog-titlebar-close span {
    position: relative;
    z-index: 9999;
}

.normalBtn, .normalBtn.disabled:not(.search, .save, .delete, .arrowBtn):hover, .ui-dialog-buttonset button {
    background-color: #e2e2e2;
    color: #333333;
    text-transform: uppercase;
    border: 1px solid #AAAAAA;
    transition: var(--transition-color);
}
.deleteBtn.disabled.normalBtn.delete:hover {
    color: #FFF !important;
}
a.normalBtn {
    color: #333;
}

.normalBtn.search {
    background-color: #006693;
    border: 1px solid #0A2240;
}

.normalBtn.search:hover {
    background-color: #0A2240;
}

.normalBtn.search.disabled:hover {
    background-color: #006693;
}

.normalBtn.save {
    background-color: #8EC549;
    border: 1px solid #75AC30;
}

.normalBtn.save:hover {
    background-color: #75AC30;
}

.normalBtn.save.disabled:hover {
    background-color: #8EC549;
}

.normalBtn.save, .normalBtn.search {
    color: #FFF;
    font-weight: 500;
    text-transform: uppercase;
}

.normalBtn.clear {
    font-weight: 500;
}

.normalBtn:hover {
    background: #999999;
    color: #ffffff;
    text-decoration: none;
    cursor: pointer;
    transition: var(--transition-color);
}


#bottom .ui-state-default.ui-corner-top a:hover,
.ui-dialog-buttonset button:hover {
    color: #006693;
    text-decoration: none;
    cursor: pointer;
    transition: all 0.1s ease-in-out;
}

.normalBtn:hover, .ui-dialog-buttonset button:hover
    /*Moved this out of the definition above, as it was only needed for .normalBtn and not for Title Details' lone "Description" button.*/ {
    color: #FFF;
}


.button--standard,
a.button--standard {
    background-color: #e2e2e2;
    color: #333333;
    text-transform: uppercase;
    border: 1px solid #AAAAAA;
}

.button--standard:hover {
    background-color: #999999;
    color: #ffffff;
    text-decoration: none;
}

.button--standard.disabled:hover,
a.button--standard.disabled:hover {
    background-color: #e2e2e2;
    color: #333333;
}

.button--primary,
a.button--primary {
    background-color: #8EC549;
    color: #FFFFFF;
    text-transform: uppercase;
    border: 1px solid #75AC30;
}

.button--primary:hover,
a.button--primary:hover {
    background-color: #75AC30;
    text-decoration: none;
}

.button--primary.disabled:hover,
a.button--primary.disabled:hover {
    background-color: #8EC549;
}

.button--large {
    padding: 9px 30px;
}

.button--transparent,
a.button--transparent {
    background-color: transparent;
    color: #333333;
    text-transform: uppercase;
    border: 1px solid #AAAAAA;
}

    .button--transparent:hover {
        background-color: #999999;
        color: #ffffff;
        text-decoration: none;
    }

    .button--transparent.disabled:hover,
    a.button--transparent.disabled:hover {
        background-color: #e2e2e2;
        color: #333333;
    }

.newBlock-gray {
    color: #FFFFFF;
    background-color: #A9A9A9;
    font-size: .8em;
    padding: 3px 6px 3px 6px;
    margin-left: 8px;
}

.generalContainer .normalBtn:hover, .reportsContainer .normalBtn:hover {
    background: #999999;
}

.formatIconBtn {
    margin: 0 0 0 6px;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.5), 0 1px 2px rgba(0, 0, 0, 0.2);
}

    .formatIconBtn:hover {
        box-shadow: inset 0 1px 0 rgba(255, 255, 255, 1), 0 1px 2px rgba(0, 0, 0, 0.5);
        opacity: .8;
    }

.blueBtn {
    padding: 6px 10px;
    border-radius: 3px !important;
    background: #006693;
    border: 1px solid #006693;
    color: #ffffff !important;
    text-align: center;
    cursor: pointer;
}

.deleteBtn, .deleteBtn.disabled:hover {
    padding: 6px 10px !important;
    background: #AE4646 !important;
    border: 1px solid #7B1313 !important;
    color: #ffffff !important;
}

    .deleteBtn:hover {
        background: #7B1313 !important;
        border: 1px solid #7B1313 !important;
        color: #ffffff;
    }

    .deleteBtn.disabled, .deleteBtn.disabled:hover {
        background: rgba(174,70,70, .5) !important;
        border: 1px solid rgba(123, 19, 19, .2) !important;
        font-weight: normal !important;
        font-style: normal !important;
        font-variant: normal !important;
        opacity: 1 !important;
    }

.searchBtn {
    text-transform: none;
    font-weight: normal !important;
}

.iconBtn {
    float: left;
    text-transform: uppercase;
    border: 1px solid #AAAAAA;
}

.iconBtn span[class^="icon-"], .iconBtn span[class*=" icon-"] {
    font-size: 16px;
    vertical-align: text-top;
}

a.BtnCancel, input[type="submit"].BtnCancel, input[type="button"].BtnCancel, input[type="submit"].BtnCancel, button.BtnCancel,
a.BtnAction, input[type="submit"].BtnAction, input[type="button"].BtnAction, input[type="submit"].BtnAction, button.BtnAction {
    position: relative;
    display: inline-block;
    margin: 0 5px 0 0;
    padding: 7px;
    min-width: 70px;
    border: none;
    border-radius: 4px;
    background: #006693;
    color: #ffffff;
    text-align: center;
    text-decoration: none;
    line-height: 12px;
    cursor: pointer;
}

.blueBtn:hover {
    background: #0668B9;
}

a.BtnCancel, a.BtnAction /*For some reason the a's are showing up 2 pixels shorter than the inputs, so I'm bulking them up here.*/ {
    padding: 5px 7px;
}

a.BtnCancel, input[type="submit"].BtnCancel, input[type="button"].BtnCancel, button.BtnCancel {
    background: #999999;
}

    a.BtnCancel:hover, input[type="submit"].BtnCancel:hover, input[type="button"].BtnCancel:hover, button.BtnCancel:hover {
        background: #cccccc;
    }

    a.BtnCancel:active, input[type="submit"].BtnCancel:active, input[type="button"].BtnCancel:active, button.BtnCancel:active {
        background: #666666;
    }

a.BtnAction, input[type="submit"].BtnAction, input[type="button"].BtnAction, button.BtnAction {
    background: #d02c50;
}

    a.BtnAction:hover, input[type="submit"].BtnAction:hover, input[type="button"].BtnAction:hover, button.BtnAction:hover {
        background: #ff3366;
    }

    a.BtnAction:active, input[type="submit"].BtnAction:active, input[type="button"].BtnAction:active, button.BtnAction:active {
        background: #AD2543;
    }

.deleteUser.disabled {
    background: #e2e2e2;
    cursor: default !important;
}

.arrowBtn {
    display: inline-block;
    background-color: #fff;
    margin: 0 0 -2px 0;
    padding: 0;
    width: 20px;
    height: 20px;
    vertical-align: text-bottom;
    border: none;
}

.arrowBtn.disabled {
    background: #fff;
}

.viewAllCartsBtn {
    display: inline-block;
    text-transform: uppercase;
    background: #FFAE00;
    border: 1px solid #E69500;
    color: #ffffff !important;
    border-radius: 3px;
    margin-right: 8px;
}

.viewAllCartsBtn:hover {
    text-decoration: none;
    background-color: #E69500;
}

.viewAllCartsBtn span.icon-manage-carts {
    font-size: 16px;
    vertical-align: text-top;
}

.addToCartContainer span.button.button--standard {
    padding: 6px 10px;
    float: right;
}

input.searchBarInput {
    margin: 0;
    padding: 0;
    width: 50%;
    height: 38px;
}

.searchBarBtn.button {
    margin: 0 0 0 -2px;
    height: 40px;
    border: 1px solid;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    background: #f2f2f2;
}

.button.normalBtn.preview.btnPreview, .button.normalBtn.titlePreviewLink {
    margin: 0;
}

#BtnLogin .button.normalBtn {
    line-height: 14px;
}

.button--toggle-state {
    margin: 0 0 0 5px;
    padding: 4px;
    background: #FFF;
    color: #006693;
    text-align: left;
    font-weight: normal;
    font-size: 12px;
    border: 1px solid #006693;
    border-radius: 3px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

.button--toggle-state.column-show-hide:hover {
    background: #006693;
    color: #FFF;
}

.active-model,
.active-model:focus {
    color: #FFF;
    background: #006693;
}

.active-model.column-show-hide:hover {
    color: #006693;
    background: #FFF;
}

/**** BOOTSTRAP BUTTONS CSS ****/


/*!
 * Bootstrap v2.1.1
 *
 * Copyright 2012 Twitter, Inc
 * Licensed under the Apache License v2.0
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Designed and built with all the love in the world @twitter by @mdo and @fat.
 */
.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
  line-height: 0;
}
.clearfix:after {
  clear: both;
}
.hide-text {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}
.input-block-level {
  display: block;
  width: 100%;
  min-height: 30px;
  box-sizing: border-box;
}

.btn
{
    min-height: 20px;
    line-height: 20px;
    display: inline-block;
    padding: 6px 10px 5px 10px;
    margin-bottom: 0;
    font-size: 1em;
    text-align: center;
    cursor: pointer;
    color: #333333;
    background-color: #ddd;
    border: 1px solid #AAA;
}

a.btn:hover {
    text-decoration:none;
}
.btn:hover {
    color:#ffffff;
    background: #999999;
}
.open > a.btn.dropdown-toggle.left {
    background: #cccccc;
}
.open > a.btn.dropdown-toggle.left:hover {
    background: #999999;
}
.btn:focus {
  outline: 5px auto currentColor;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.btn.disabled,
.btn[disabled] {
    cursor: default;
    background-color: #e6e6e6;
    background-image: none;
    opacity: 0.65;
    filter: alpha(opacity=65);
    box-shadow: none;
    text-shadow: none;
    color: #333333;
}
.btn.disabled:hover
{
    color: #333333;
}

.btn.disabled:hover .caret, .btn[disabled]:hover .caret
{
    border-top: 4px solid #000000;
}
.btn-large {
  padding: 9px 14px;
  font-size: 16px;
  line-height: normal;
  border-radius: 5px;
}
.btn-large [class^="icon-"] {
  margin-top: 2px;
}
.btn-small {
  padding: 3px 9px;
  font-size: 12px;
  line-height: 18px;
}
.btn-small [class^="icon-"] {
  margin-top: 0;
}
.btn-mini {
  padding: 2px 6px;
  font-size: 11px;
  line-height: 17px;
}
.btn-block {
  display: block;
  width: 100%;
  padding-left: 0;
  padding-right: 0;
  box-sizing: border-box;
}
.btn-block + .btn-block {
  margin-top: 5px;
}
input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%;
}
.btn-primary.active,
.btn-warning.active,
.btn-danger.active,
.btn-success.active,
.btn-info.active,
.btn-inverse.active {
  color: rgba(255, 255, 255, 0.75);
}
.btn-primary {
  color: #ffffff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: #006dcc;
}
.btn-primary:hover,
.btn-primary:active,
.btn-primary.active,
.btn-primary.disabled,
.btn-primary[disabled] {
  color: #ffffff;
  background-color: #0044cc;
}
.btn-primary:active,
.btn-primary.active {
  background-color: #003399 \9;
}
.btn-search {
  height: 25px;
  color: #ffffff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: #006693;
}
.btn.btn-search.dropdown-toggle, .btn.btn-search { height: 36px; }
.btn.btn-search.dropdown-toggle:hover, .btn.btn-search:hover {
    background-color: #0668B9;
    border:none;
}
.btn-group.open .btn.btn-search.dropdown-toggle { background-color: #043a67; }
.btn-search:hover,
.btn-search:active,
.btn-search.active,
.btn-search.disabled,
.btn-search[disabled] {
    color: #ffffff;
    background-color: #006693;
    background-position: 0 15px !important;
    background-repeat:repeat-x !important;
}
.btn-search:active,
.btn-search.active {
  background-color: #006693 \9 !important;
}
.btn-group.open .btn-search.dropdown-toggle {
  border: 1px solid #02233E;
  background: #02233E; /* Old browsers */
  background-image: linear-gradient(to bottom, #043a67, #02233E) !important;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.5), 0 1px 2px rgba(0, 0, 0, 0.05) !important;
}
.btn.btn-search:active .caret { border-top: 4px solid #ffffff; }
.btn-warning {
  color: #ffffff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: #faa732;
  border-color: #f89406 #f89406 #ad6704;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
}
.btn-warning:hover,
.btn-warning:active,
.btn-warning.active,
.btn-warning.disabled,
.btn-warning[disabled] {
  color: #ffffff;
  background-color: #f89406;
}
.btn-warning:active,
.btn-warning.active {
  background-color: #c67605 \9;
}
.btn-danger {
  color: #ffffff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: #da4f49;
  border-color: #bd362f #bd362f #802420;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
}
.btn-danger:hover,
.btn-danger:active,
.btn-danger.active,
.btn-danger.disabled,
.btn-danger[disabled] {
  color: #ffffff;
  background-color: #bd362f;
}
.btn-danger:active,
.btn-danger.active {
  background-color: #942a25 \9;
}
.btn-success {
  color: #ffffff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: #5bb75b;
  border-color: #51a351 #51a351 #387038;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
}
.btn-success:hover,
.btn-success:active,
.btn-success.active,
.btn-success.disabled,
.btn-success[disabled] {
  color: #ffffff;
  background-color: #51a351;
}
.btn-success:active,
.btn-success.active {
  background-color: #408140 \9;
}
.btn-info {
  color: #ffffff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: #49afcd;
  border-color: #2f96b4 #2f96b4 #1f6377;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
}
.btn-info:hover,
.btn-info:active,
.btn-info.active,
.btn-info.disabled,
.btn-info[disabled] {
  color: #ffffff;
  background-color: #2f96b4;
}
.btn-info:active,
.btn-info.active {
  background-color: #24748c \9;
}


/*TOP PROFILE DROPDOWN*************************************************/
.btn-group-topProfile { margin-right: 5px; }
.btn-topProfile,
.btn-topProfile:hover,
.btn-topProfile:active,
.btn-topProfile:focus,
.btn-topProfile.active,
.btn-topProfile.disabled,
.btn-topProfile[disabled],
.btn-group.open .btn-topProfile.dropdown-toggle {
    margin: -1px 0 -3px 0 !important;
    padding: 0 0 0 4px !important;
    background-image: none !important;
    background-color: transparent !important;
    border: none !important;
    box-shadow: none !important;
    border-radius: 0 !important;
    font-weight: bold !important;
    text-shadow: none !important;
    color: #006693;
}
    .btn-topProfile:hover {
        color: #006693 !important;
        text-decoration: underline !important;
    }
    .btn-topProfile .caret {
        margin: 7px 0px 6px 6px !important;
        border-top: 4px solid #006693 !important;
    }
    .btn-topProfile:hover .caret {
        border-top: 4px solid #006693 !important;
    }
.btn-group-topProfile > .dropdown-menu { margin: 2px -13px 0 0; }
.btn-group-topProfile > .dropdown-menu:before {
  position: absolute;
  top: -7px;
  right: 9px;
  display: inline-block;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #ccc;
  border-left: 7px solid transparent;
  border-bottom-color: rgba(0, 0, 0, 0.2);
  content: '';
}
.btn-group-topProfile > .dropdown-menu:after {
  position: absolute;
  top: -6px;
  right: 10px;
  display: inline-block;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #ffffff;
  border-left: 6px solid transparent;
  content: '';
}
li.logOut a { font-weight: bold; }
/**********************************************************************/

/*KINDLE DEVICE USAGE MESSAGE DROPDOWN*********************************/
.btn-group-Kindle {
    font-weight:normal !important;
    text-align: right;
    clear:both;
}
.btn-group-Kindle .dropdown-menu {
    padding: 10px;
    text-align: left;
    white-space: normal;
}
/**********************************************************************/


/*SPECIAL FEATURES MESSAGE DROPDOWN*********************************/
.btn-group-specialFeature {
    font-weight:normal !important;
    text-align: right;
}
.btn-group-specialFeature .dropdown-menu {
    padding: 10px;
    text-align: left;
    white-space: normal;
}
/**********************************************************************/

/****  BUTTON COLORS  ****/

.button--blue, a.button--blue { background-color: #006693; border: 1px solid #0A2240; color: #FFF; }
.button--blue:hover { background-color: #0A2240; }
.button--blue.disabled:hover { background-color: #006693; }

.button--green, a.button--green { background-color: #8EC549; border: 1px solid #75AC30; color: #FFF; }
.button--green:hover { background-color: #75AC30; }
.button--green.disabled:hover { background-color: #8EC549; }

.button--yellow, a.button--yellow { background-color: #FFAE00; border: 1px solid #E69500; color: #FFF; }
.button--yellow:hover { background-color: #E69500; }
.button--yellow.disabled:hover { background-color: #FFAE00; }

.button--orange, a.button--orange { background-color: #FF8000; border: 1px solid #E66700; color: #FFF; }
.button--orange:hover { background-color: #E66700; }
.button--orange.disabled:hover { background-color: #FF8000; }

.button--red-preorder, a.button--red-preorder { background-color: #D02C50; border: 1px solid #B71337; color: #FFF; }
.button--red-preorder:hover { background-color: #B71337; }
.button--red-preorder.disabled:hover { background-color: #D02C50; color: #FFF; }

.button--red, a.button--red { background-color: #AE4646; border: 1px solid #7B1313; color: #FFF; }
.button--red:hover { background-color: #7B1313; }
.button--red.disabled:hover { background-color: #AE4646; }

.button--brown, a.button--brown { background-color: #AD8B35; border: 1px solid #7A5802; color: #FFF; }
.button--brown:hover { background-color: #7A5802; }
.button--brown.disabled:hover { background-color: #AD8B35; }

/* Hollow buttons */
.button--hollow { background-color: #FFF; color: #AAA; border: 1px solid #AAA; }
.button--hollow:hover { background-color: #AAA; color: #FFF; }

.button--hollow--blue { background-color: #FFF; color: #006693; border: 1px solid #006693; }
.button--hollow--blue:hover { background-color: #006693; color: #FFF; }

.button--hollow--green { background-color: #FFF; color: #8EC549; border: 1px solid #8EC549; }
.button--hollow--green:hover { background-color: #8EC549; color: #FFF; }

.button--hollow--gray { background-color: #FFF; color: #aaaaaa; border: 1px solid #aaaaaa; }
.button--hollow--gray:hover { background-color: #FFF; color: #aaaaaa; }

.button--hollow--yellow { background-color: #FFF; color: #FFAE00; border: 1px solid #FFAE00; }
.button--hollow--yellow:hover { background-color: #FFAE00; color: #FFF; }

.button--hollow--orange { background-color: #FFF; color: #FF8000; border: 1px solid #FF8000; }
.button--hollow--orange:hover { background-color: #FF8000; color: #FFF; }

.button--hollow--red-preorder { background-color: #FFEAEE; color: #D02C50; border: 1px solid #D02C50; }
.button--hollow--red-preorder:hover { background-color: #D02C50; color: #FFF; }

.button--hollow--red { background-color: #FFEAEA; color: #AE4646; border: 1px solid #AE4646; }
.button--hollow--red:hover { background-color: #AE4646; color: #FFF; }

.button--hollow--brown { background-color: #FFF; color: #AD8B35; border: 1px solid #AD8B35; }
.button--hollow--brown:hover { background-color: #AD8B35; color: #FFF; }

a.button--hollow--blog { background-color: #FFF; color: #f26522; border: 1px solid #f26522; }
a.button--hollow--blog:hover { background-color: #f26522; color: #FFF; }

/****  BUTTON COLORS  ****/

/****  BUTTON MODS  ****/
.button.button--left {
    float: left;
    margin-right: 10px;
}

.button--first {
    margin-left: 0;
}

/*MAIN CART DROPDOWN***************************************************/
a.btn.btn-mainCart, span.btn.btn-mainCart {
    width: 120px;
    height: 30px;
    padding: 3px 7px !important;
    line-height: 12px;
    text-align: left;
    color: #333333 !important;
    text-shadow: none !important;
}
.open a.btn.btn-mainCart, .open span.btn.btn-mainCart { color: #ffffff !important; }
ul.btn-mainCart {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-top: none;
}
.btn-mainCart {
  height: 25px;
  color: #ffffff;
}
.btn.btn-mainCart.dropdown-toggle {
    height: 38px;
    margin-left: -5px !important;
}

.dropdown-toggle .icon-small-arrow-down {
    font-size: 12px;
    font-weight: bold;
    margin: 0px;
    padding: 4px 0px 0px 0px;
}
.addToCartContainer .dropdown-toggle, .addToCollectionContainer .dropdown-toggle {
    padding-left: 6px !important;
    padding-right: 6px !important;
}

.btn.btn-mainCart.dropdown-toggle:hover, .btn.btn-mainCart:hover {
    background-color: #dddddd;
}
.btn-group.open .btn.btn-mainCart.dropdown-toggle {
    background-color: #006693;
    box-shadow:none !important;
}
.btn-group.open .btn.btn-mainCart.dropdown-toggle:hover {
    background-color: #06467c;
}
.btn-group-mainCart:hover .btn-mainCart, .btn-group-mainCart:hover .btn-mainCart.dropdown-toggle { background-color: #d2d2d2; }
.btn-group-mainCart:hover .btn-mainCart:hover, .btn-group-mainCart:hover .btn-mainCart.dropdown-toggle:hover { background-color: #c2c2c2; }
.btn-mainCart:active,
.btn-mainCart.active,
.btn-mainCart[disabled]
{
    color: #ffffff;
    background-color: #006693;
    background-position: 0 15px !important;
    background-repeat:repeat-x !important;
}
.btn-group.open .btn-mainCart.dropdown-toggle,
.btn-group.open a.btn-mainCart,
.btn-group.open span.btn-mainCart,
.open.btn-group-mainCart:hover .btn-mainCart:active {
    color:#ffffff;
    background: #006693;
}
.btn-group.open .btn-mainCart.dropdown-toggle:hover,
.btn-group.open a.btn-mainCart:hover {
    background: #06467c !important;
}
.open .btn.btn-mainCart, .open .btn-mainCart.dropdown-toggle {
    border-bottom-left-radius:0 !important;
    border-bottom-right-radius:0 !important;
}
.btn-group-mainCart .dropdown-menu {
    margin-top: -2px;
    width: 158px;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    border-top: none;
    background: #efefef;
    border: none;
}
.btn.btn-mainCart:hover .caret { border-top: 4px solid #000000; }
.btn.btn-mainCart:active .caret, .open .btn.btn-mainCart .caret, .open .btn.btn-mainCart:hover .caret { border-top: 4px solid #ffffff; }
.btn.btn-mainCart.disabled,
.btn-group.open .btn.btn-mainCart.dropdown-toggle.disabled {
    cursor: default !important;
    background-color: #e6e6e6 !important;
    background-image: none !important;
    opacity: 0.65 !important;
    filter: alpha(opacity=65) !important;
    box-shadow: none !important;
    text-shadow: none !important;
    color: #333333 !important;
}

.btn.btn-mainCart .caret,
.open .btn.btn-mainCart .caret {
    margin-top: 6px;
}

/**********************************************************************/

.btn-inverse {
  color: #ffffff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: #363636;
  border-color: #222222 #222222 #000000;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
}
.btn-inverse:hover,
.btn-inverse:active,
.btn-inverse.active,
.btn-inverse.disabled,
.btn-inverse[disabled] {
  color: #ffffff;
  background-color: #222222;
}
.btn-inverse:active,
.btn-inverse.active {
  background-color: #080808 \9;
}
button.btn::-moz-focus-inner,
input[type="submit"].btn::-moz-focus-inner {
  padding: 0;
  border: 0;
}
.btn-link,
.btn-link:active,
.btn-link[disabled] {
  background-color: transparent;
  background-image: none;
  box-shadow: none;
}
.btn-link {
  border-color: transparent;
  cursor: pointer;
  color: #006693;
  border-radius: 0;
}
.btn-link:hover {
  color: #006693;
  text-decoration: underline;
  background-color: transparent;
}
.btn-link[disabled]:hover {
  color: #333333;
  text-decoration: none;
}
.btn-group {
  position: relative;
  vertical-align: top;
  white-space: nowrap;
}
.btn-group + .btn-group {
  margin-left: 5px;
}
.btn-toolbar {
  font-size: 0;
  margin-top: 10px;
  margin-bottom: 10px;
}
.btn-toolbar .btn-group {
  display: inline-block;
}
.btn-toolbar .btn + .btn,
.btn-toolbar .btn-group + .btn,
.btn-toolbar .btn + .btn-group {
  margin-left: 5px;
}
.btn-group > .btn {
  position: relative;
}
.btn-group > .btn:first-child {
  margin-left: 0;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
.btn-group > .btn:last-child,
.btn-group > .dropdown-toggle {
  border-radius:0;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}
.btn-group > .btn.large:first-child {
  margin-left: 0;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
.btn-group > .btn.large:last-child,
.btn-group > .large.dropdown-toggle {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}
.btn-group > .btn:hover,
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active {
  z-index: 2;
}
.btn-group .dropdown-toggle:active,
.btn-group.open .dropdown-toggle {
  outline: 0;
}
.btn-group > .btn + .dropdown-toggle {
  padding-left: 8px;
  padding-right: 8px;
  vertical-align: top;
  border: 1px solid #AAA;
  margin-left: -1px;
  border-radius: 0;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}
.btn-group > .btn-mini + .dropdown-toggle {
  padding-left: 5px;
  padding-right: 5px;
}
.btn-group > .btn-large + .dropdown-toggle {
  padding-left: 12px;
  padding-right: 12px;
}
.btn-group.open .dropdown-toggle {
  background-image: none;
}
.btn-group.open .btn.dropdown-toggle {
  background-color: #e6e6e6;
}
.btn-group.open .btn-primary.dropdown-toggle {
  background-color: #006693;
}
.btn-group.open .btn-warning.dropdown-toggle {
  background-color: #FFAE00;
}
.btn-group.open .btn-danger.dropdown-toggle {
  background-color: #AE4646;
}
.btn-group.open .btn-success.dropdown-toggle {
  background-color: #8EC549;
}
.btn-group.open .btn-info.dropdown-toggle {
  background-color: #74CEE2;
}
.btn-group.open .btn-inverse.dropdown-toggle {
  background-color: #0A2240;
}
.btn .caret {
  margin-top: 8px;
  margin-left: 0;
}
.btn-mini .caret,
.btn-small .caret,
.btn-large .caret {
  margin-top: 6px;
}
.btn-large .caret {
  border-left-width: 5px;
  border-right-width: 5px;
  border-top-width: 5px;
}
.dropup .btn-large .caret {
  border-bottom: 5px solid #000000;
  border-top: 0;
}
.btn-primary .caret,
.btn-search .caret,
.btn-warning .caret,
.btn-danger .caret,
.btn-info .caret,
.btn-success .caret,
.btn-inverse .caret {
  border-top-color: #ffffff;
  border-bottom-color: #ffffff;
}
.btn-group-vertical {
  display: inline-block;
}
.btn-group-vertical .btn {
  display: block;
  float: none;
  width: 100%;
  border-radius: 0;
}
.btn-group-vertical .btn + .btn {
  margin-left: 0;
  margin-top: -1px;
}
.btn-group-vertical .btn:first-child {
  border-radius: 4px 4px 0 0;
}
.btn-group-vertical .btn:last-child {
  border-radius: 0 0 4px 4px;
}
.btn-group-vertical .btn-large:first-child {
  border-radius: 6px 6px 0 0;
}
.btn-group-vertical .btn-large:last-child {
  border-radius: 0 0 6px 6px;
}
.dropup,
.dropdown {
  position: relative;
}
.dropdown-toggle {
  height: 20px;
}
.dropdown-toggle:active,
.open .dropdown-toggle {
  outline: 0;
}
.caret {
    display: inline-block;
    width: 0;
    height: 0;
    vertical-align: top;
    border-top: 4px solid #000000;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
    content: "";
}

:where(.btn:hover .caret) {
  border-top: 4px solid #fff;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
  content: "";
}

:where(.btn:active .caret) {
  border-top: 4px solid #000000;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
  content: "";
}
.dropdown .caret {
  margin-top: 8px;
  margin-left: 2px;
}
.dropdown-menu {
    position: absolute;
    top: 100%;
    right: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 100%;
    max-width: 245px;
    margin: 0;
    padding: 0;
    list-style: none;
    background-color: #f8f8f8;
    border-radius: 6px;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-clip: padding-box;
}
.dropdown-menu li, .dropdown-menu li:first-child {
    float: none;
    overflow:hidden;
    margin: 0px;
    width: auto;
    transition: all 0.1s ease-in-out;
}
.btn-group-search .dropdown-menu li,
.btn-group-mainCart .dropdown-menu li:first-child > a,
.btn-group-mainCart .dropdown-menu li:first-child {
    border-radius:0 !important;
}

.dropdown-menu li:first-child, .dropdown-menu li.dropdown-submenu:first-child,
.dropdown-menu li:first-child > a {
    border-top-left-radius: 3px !important;
    border-top-right-radius: 3px !important;
}
.dropdown-menu li:last-child, .dropdown-menu li.dropdown-submenu:last-child,
.dropdown-menu li:last-child > a, .dropdown-menu li.dropdown-submenu:last-child > a {
    border-bottom-left-radius: 3px !important;
    border-bottom-right-radius: 3px !important;
}
.dropdown-menu li.manageCartsLink > a, .dropdown-menu li.manageCartsLink:first-child {
    border-radius: 0px 0px 3px 3px !important;
}
.dropdown-menu li.dropdown-submenu > .dropdown-menu li:first-child a {
    border-top-left-radius:0 !important;
}
.dropdown-menu.pull-right {
    right: 0;
    left: auto;
}

.pinnedCarts__dropDown {
    display: inline;
}

.dropdown-menu li.pinnedCartBorder {
    border-top:1px solid rgba(0,0,0,0.1);
}

.dropdown-menu li.pinnedCartBorder:hover:first-child {
    border-top:1px solid #06467c;
}

.dropdown-menu .divider {
    height: 1px;
    margin: 0;
    overflow: hidden;
    background-color: #dddddd;
    border: none;
}
    .dropdown-menu a {
        display: block;
        padding: 4px 7px;
        clear: both;
        font-weight: normal;
        line-height: 20px;
        color: #333333;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
.dropdown-menu li > a:hover,
.dropdown-menu li > a:focus,
.dropdown-submenu:hover > a,
.dropdown-menu li > label:hover,
.dropdown-menu li > label:focus,
.dropdown-submenu:hover > label {
    text-decoration: none;
    color: #ffffff !important;
    background: #006693;
    border-radius: 0;
    transition: all 0.1s ease-in-out;
}
.dropdown-menu li > a,
.dropdown-submenu > a {
    transition: all 0.1s ease-in-out;
}

.dropdown-menu .disabled > a,
.dropdown-menu .disabled > a:hover {
  color: #999999;
}
.dropdown-menu .disabled > a:hover {
  text-decoration: none;
  background-color: transparent;
  cursor: default;
}
.open  > .dropdown-menu {
  display: block;
}
.pull-right > .dropdown-menu {
  right: 0;
  left: auto;
}
.dropup .caret,
.navbar-fixed-bottom .dropdown .caret {
  border-top: 0;
  border-bottom: 4px solid #000000;
  content: "";
}
.dropup .dropdown-menu,
.navbar-fixed-bottom .dropdown .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-bottom: 1px;
}
.dropdown-submenu {
  position: relative;
}
.dropdown-submenu > .dropdown-menu {
  top: 0;
  left: 100%;
  margin-top: 4px;
  margin-left: -1px;
  border-radius: 0 6px 6px 6px;
}
.dropdown-submenu:hover > .dropdown-menu {
  display: block;
}
.dropdown-submenu > a:after {
  display: block;
  content: " ";
  float: right;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  border-width: 5px 0 5px 5px;
  border-left-color: #cccccc;
  margin-top: 5px;
  margin-right: -10px;
}
.dropdown-submenu:hover > a:after {
  border-left-color: #ffffff;
}
.dropdown .dropdown-menu .nav-header {
  padding-left: 20px;
  padding-right: 20px;
}
.typeahead {
  margin-top: 2px;
  border-radius: 4px;
}

.dropdown-menu label {
    display: block;
    padding: 4px 7px;
    clear: both;
    font-weight: normal;
    line-height: 20px;
    color: #333333;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.dropdown-menu li > label:hover, .dropdown-menu li > label:focus, .dropdown-submenu:hover > label {
    text-decoration: none;
    color: #ffffff;
    background: #006693;
    border-radius: 0;
}

.formatList label.btn {
    text-align: left;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    border: 1px solid #AAA;
    border-right: none;
    text-transform: uppercase;
    line-height: 18px;
}

.formatList .btn-group > .btn + .dropdown-toggle {
    margin-left: -4px;
}

/* CUSTOM BOOTSTRAP CSS VIA STYLE GUIDE */

.btn--format-text {
    display: inline-block;
    vertical-align: top;
    margin-top: 1px;
}

.format label.button {
    padding: 6px 10px 6px 10px;
}

.format .button span[class^="icon-"] {
    vertical-align: initial;
}


/**** END BOOTSTRAP CSS ****/


.header__button--lrg {
    line-height: 24px;
    padding-right: 16px;
    padding-left:16px;
}

/********************************* END BUTTONS */


/********************************* BEGIN LEFT SIDEBAR */
.pinnedSearch, .sideBarBtns, .ActiveCarts,
.ui-accordion-header.ui-helper-reset.ui-state-default.ui-corner-all,
.ui-accordion-header.ui-helper-reset.ui-state-active.ui-corner-top {
    background: #fff;
    transition: all 0.1s ease-in-out;
}

.accordion {
    display: none;
}

    .accordion .accordionContent {
        overflow: hidden;
        width: 195px;
        border: none;
        border-radius: 0;
        background: none;
    }

    .accordion div li {
        margin: 5px 0 2px 0;
        line-height: 16px;
    }

.mustHaves li, .savedSearches li, .piManagerPlans li {
    padding: 4px 2px 4px 0.7em;
}

div.savedSearches ul, div.piManagerPlans ul {
    margin-left:10px;
    margin-top: -5px;
}

.accordion div li.format {
    border: 0;
}

.accordion div li.al-value {
    border-bottom: 1px solid #CCCCCC;
}

.accordion input[type="checkbox"] {
    margin: 4px 0 0 0;
}

.savedSearch .controls {
    margin: 5px;
}

    .savedSearch .controls a {
        margin: 2px 4px 0 0;
        color: #999999;
    }

    .savedSearch .controls a [class^="icon-"] {
        font-size: 12px;
    }

    .savedSearch .controls a:hover {
        text-decoration: none;
        color: #006693;
    }

.pageHeading {
    position: relative;
    float: left;
    margin: 0 0 20px 0;
    padding-bottom: 5px;
    width: 100%;
    border-bottom: 1px solid #E3E3E3;
    background-color: transparent;
}

    .pageHeading h1 {
        float: left;
        margin: 0;
        padding: 0;
        font-family: 'FreightTextProBook-Regular', sans-serif;
    }

#savedSearches span.message {
    display: block;
    padding: 10px;
}

#OnSaleDate, #Holdings, #HoldsRatio {
    display: none;
    margin: 0px 0px 7px 5px;
    width: 185px;
}

#leftSideBar input[type="text"] {
    overflow: hidden;
    margin: 5px 5px 2px 5px;
    padding: 0 5px;
    width: 173px;
    height: 20px;
}

.sideBarBtns {
    display: block;
    margin: 0;
    padding: 5px 5px 5px 10px;
    color: #000;
    text-decoration: none;
    font-weight: 500;
    font-size: 1em;
}

a.sideBarBtns,
a.ActiveCarts {
    height: 17px;
    color: #000;
    font-size: 1em;
}

a.ActiveCarts.create-cart {
    color: #006693;
    font-size: 1.2em;
    font-weight: bold;
}

a.ActiveCarts.create-cart:hover {
    text-decoration: none;
}

a.ActiveCarts.curated-carts {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.sideBarBtns:hover,
a.ActiveCarts:hover,
.searchButton:hover {
    text-decoration: none;
    font-weight: 500;
}

a.ActiveCarts:hover {
    text-decoration: underline;
    font-weight: bold;
}

.sideBarBtns:active a.ActiveCarts:active, .pinnedSearches li a.sideBarBtns {
    color: #006693;
    text-decoration: none;
    font-weight: 500;
    font-size: .9em;
}

.marginTop5px {
    margin-top: 5px;
}

.pinnedSearches {
    list-style: none;
}

#subaccordion .ui-accordion-header,
#subaccordion .ui-accordion-header:hover,
#subaccordion .ui-state-active,
#subaccordion .ui-accordion-header:active,
#subaccordion .ui-state-active,
#subaccordion .ui-widget-content .ui-state-active,
#subaccordion .ui-widget-header .ui-state-active,
#subaccordion .ui-state-active:hover {
    color: #666666;
    text-decoration: none;
    font-weight: 500;
    font-size: .9em;
}

#subaccordion h2, #subaccordion h2 a {
    color: #888888;
}

#subaccordion .accordionContent {
    border-right: none;
    border-radius: 0px;
}

#subaccordion {
    padding-top: 2px;
    font-size: 1.2em;
}

    #subaccordion div li {
        border: 0;
    }

.insights-sidebar-h2 {
    margin: 0;
    padding: 5px 5px 0px 10px;
    font-family: 'FreightTextProBook-Regular', sans-serif;
    font-size: 1.5em;
}

.od-logo-h2 {
    height: 18px;
    width: 18px;
    margin-right: 4px;
}

.label__inlineblock-narrow {
    display: inline-block;
    width: 165px;
    vertical-align: top;
    line-height: normal;
    margin: 5px 0 5px 0;
}

/********************************* END LEFT SIDEBAR */


/********************************* BEGIN SEARCH CONTROLS */

.clearable {
    position: relative;
}

span.icon_clear {
    z-index: 9;
    display: none;
    width: 20px;
    height: 20px;
    cursor: pointer;
    color: #999999;
    font-size: .85em;
    padding-left: 5px;
    margin-bottom: -37px;
}

    span.icon_clear:hover { color: #ae4646; }

    span.icon_clear.merch {
        left: -22px;
        margin-left: 0;
    }

    span.unobtrusive {
        margin: 0;
        padding: 0;
        float: inherit;
    }

#frmSearchWithin {
    position: relative;
}

    #frmSearchWithin span.icon_clear {
        right: 5px;
    }

#btnSubmitSearchWithin {
    margin-left: 5px;
}

input[type="search"]::-webkit-search-cancel-button {
    -webkit-appearance: none;
    width: 20px;
    height: 20px;
    background: url('/Content/images/searchFieldCloseBtn.png') no-repeat;
    cursor: pointer;
    /* Remove default */
}

#button.normalBtnSearch {
    float: left;
}


#savedSearches a:hover {
    color: #29AAE1;
}

/* -- Basic Search CSS -- */
.basicSearch .subaccordion .accordionContent {
    padding: 0px !important;
    width: auto !important;
}

.basicSearch .accordionContent.formats {
    padding: 6px 0 0 5px !important;
}

.basicSearch .formats .child {
    padding-left: 13px;
}

/********************************* END SEARCH CONTROLS */


/********************************* BEGIN PAGING AND SORTING */
.sorting__results-header {
    height: 33px;
    white-space: nowrap;
    width: 90%;
    float: left;
    margin-right: 18px;
}

.sorting__results-header ul {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.sorting__results-header ul li {
    list-style: none;
    flex-grow: 1;
    min-width: 0;
    overflow: hidden;
}

.visibleSortingTabs {
    width: 100%;
}

#hiddenTabs {
    position: absolute;
    margin-top: 35px;
    z-index: 500;
    box-shadow: 0px 5px 10px #333333;
    display: block;
    text-align: left;
}

.visibleTab {
    border-right: 1px #DDDDDD solid;
    height: 33px;
    text-align: center;
    vertical-align: middle;
    line-height: 33px;
    color: #006693;
    cursor: pointer;
}

.hiddenTab {
    background-color: #ffffff;
    border: 1px #DDDDDD solid;
    border-bottom: none;
}

.hiddenTab:hover {
    background: #006693;
    color: #FFFFFF;
    border-color: #006693;
    cursor: pointer;
}

.hiddenTab:last-child {
    border-bottom: 1px #DDDDDD solid;
}

.hiddenTab:last-child:hover {
    border-bottom: 1px #006693 solid;
}

#hiddenTabs li:first-child {
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
}

#hiddenTabs li:last-child {
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
}

.sorting__sorting-bar {
    height: 33px;
    border: 1px #DDDDDD solid;
    border-radius: 2px;
}

#sortingBarShowMore {
    color: #666666;
    font-size: 13px;
    outline: none;
    cursor: pointer;
    float: left;
    padding-left: 18px;
}

#sortingBarShowMore span {
    height: 33px;
    text-align: center;
    vertical-align: middle;
    line-height: 33px;
    position: relative;
    overflow: visible;
}

.sortingDropdown {
    height: 33px;
    text-align: center;
    vertical-align: middle;
    line-height: 33px;
    cursor: pointer;
    display: inline-block;
}

.sortingDropdown .icon-small-arrow-down {
    float: right;
    margin: 0;
    height: 33px;
    font-weight: bold;
    padding-left: 14px;
    padding-right: 18px;
}

    .sortingDropdown .icon-small-arrow-down:before {
        color: #666666;
        font-size: 14px;
        text-align: center;
        line-height: 33px;
    }

.dropdownContent {
    position: absolute;
    margin-top: 2px;
    z-index: 100;
    background-color: #ffffff;
    border-radius: 2px;
    color: #333333;
    text-align: left;
    box-shadow: 0px 5px 10px #333333;
}

.dropdownContent span {
    padding-left: 12px;
    white-space: nowrap;
    border: solid 1px #DDDDDD;
    border-radius: 2px;
}

.dropdownContent span:first-child {
    border-bottom: none;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
}

.dropdownContent span:last-child {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
}


.dropdownContent span:hover {
    background: #006693;
    color: #FFFFFF;
    cursor: pointer;
    border-color: #006693;
}

.dropdownContent .selectedDropdownOption {
    color: #006693;
}

.activeTab {
    background-color: #006693;
    color: #ffffff;
}

.resultsPerPageDropdown #activePagingOption {
    color: #006693;
}

.resultsPerPageDropdown #activePagingOption:hover {
    color: #ffffff;
}

.resultsPerPageVisible {
    cursor: pointer;
}

.hiddenTab {
    padding: 0px 12px 0px 12px;
}

.sortDisplayOption .icon-small-arrow-down {
    float: right;
    background-color: #03557e;
    margin: 0;
    height: 33px;
    width: 30px;
    opacity: 1;
}

.sortDisplayOption .icon-small-arrow-down:before {
    color: #ffffff;
    opacity: 1;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    line-height: 33px;
}

.resultsPerPage {
    float: right;
    width: 70px;
    border: solid 1px #DDDDDD;
    border-radius: 2px;
    margin: 0 0 0 18px;
    padding: 0;
    line-height: 33px;
    white-space: nowrap;
    text-align: center;
}

.resultsPerPageDropdown {
    display: block;
    position: absolute;
    margin-top: 2px;
    z-index: 100;
    color: #333333;
    display: block;
    float: left;
    box-shadow: 0px 5px 10px #333333;
    border-radius: 2px;
}

.resultsPerPageOption {
    width: 54px;
    border: 1px solid #DDDDDD;
    border-bottom: none;
    background-color: #ffffff;
    padding-left: 16px;
    text-align: left;
    cursor: pointer;
}

.resultsPerPageOption:hover {
    background: #006693;
    color: #FFFFFF;
    border-color: #006693;
    cursor: pointer;

}

.resultsPerPageDropdown li:first-child {
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
}

.resultsPerPageDropdown li:last-child {
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
}

.resultsPerPageDropdown .selectedDropdownOption {
    color: #006693;
}

.selectedDropdownOption .icon-checkmark-heavy {
    float: right;
    display: inline;
    text-align: center;
    vertical-align: middle;
    line-height: 33px;
    font-size: 10px;
    margin-left: 1px;
    margin-right: 12px;
    padding: 0px;
    border: none !important;
}

.resultsPerPageDropdown .icon-checkmark-heavy {
    display: inline;
    text-align: center;
    vertical-align: middle;
    line-height: 33px;
    font-size: 10px;
    margin: 0px;
    margin-left: 12px;
}

.resultsPerPage .icon-small-arrow-down {
    float: right;
    margin: 0;
    height: 33px;
    width: 25px;
    margin-left: -8px;
    color: #333333;
    font-size: 14px;
}

.resultsPerPage .icon-small-arrow-down:before {
    color: #333333;
    font-size: 14px;
    text-align: center;
    line-height: 33px;
    font-weight: bold;
    margin-right: 12px;
}

.searchText {
    font-weight: 500;
}

.searchTerm label.left {
    margin: 0 4px 0 0;
}

.searchPhrase {
    margin: 0px 0px 24px 0px;
    font-family: proxima-nova;
    font-size: 22px;
    color: #000000;
}

.searchPhrase b {
    font-family: proxima-nova-semibold;
    font-weight: normal;
}

.search__edit-search {
    color: #137BA8;
    font-size: 15px;
    margin-left: 4px;
    white-space: nowrap;
}

.search__edit-search:hover {
    cursor: pointer;
}

#search__suggested-list-results {
    font-size: 19px;
    margin-top: 5px;
}

.pager form, .pager label {
    display: block;
    float: left;
}

.paging li {
    margin-left: 8px;
    padding-left: 8px;
    height: 17px;
    border-left: 1px solid #999999;
}


.pager form, .pager label {
    display: block;
    float: left;
}

.pager form, .pager label {
    display: block;
    float: left;
}

.sortBar {
    position: relative;
    float: left;
    margin: 24px -1px 24px 0;
    min-height: 50px;
    width: 100%;
    background: #F0F0F0 0% 0% no-repeat padding-box;
    border: 1px solid #DDDDDD;
    border-radius: 3px;
}

.search__select-bar {
    float: left;
    margin-left: 2px;
    padding-bottom: 6px;
}

.search__select-bar label {
    margin-top: 6px;
    margin-left: 8px;
    color: #0A2240;
}

input[type="checkbox"].selectAllCheckbox {
    margin: 0;
    padding: 0;
}

.sort.endOfList .sortBar {
    margin: -1px 0 24px 0;
    min-width: 584px;
}

.DataTables_sort_wrapper {
    padding: 0 8px;
}

.orderBy {
    float: left;
    margin-top: 2px;
    width: 59px;
}

.pageLink {
    border-left: 1px solid #DDDDDD;
    background-color: transparent;
    text-decoration: none;
    font-size: 13px;
    width: 36px;
    flex: 1 1 auto;
}

.pageLink:hover {
    cursor: pointer;
}

    .paging-controls .icon-small-arrow-left, .paging-controls .icon-small-arrow-right {
        margin: 0px;
        line-height: inherit;
        font-weight: bold;
        font-size: 14px;
        flex: 1 1 auto;
    }

.readingInfo {
    margin-top: 1px;
    display: inline-block;
    text-decoration: none !important;
}

    .readingInfo span[class^="icon-"] {
        font-size: 1em;
    }

.pageLink:hover, .pageLink:active {
    background-color: #006693;
    color: #ffffff;
    text-decoration: none;
}

.pageLink:active {
    background-color: #006693;
}
/*Current pager link style*/
.pageLink.current {
    position: relative;
    background-color: #006693;
    color: #ffffff;
    text-decoration: none;
    cursor: default;
}

.pageLink.disabled {
    background: linear-gradient(to bottom, #ffffff, #ffffff) !important;
    color: #cccccc;
}

span.squeeze {
    border-left: 1px solid #DDDDDD;
    font-size: 13px;
    width: 36px;
    flex: 1 1 auto;
}

input[type="text"].txtJumpTo {
    z-index: 4;
    margin: 5px 5px 5px 12px;
    width: 48px;
    text-align: right;
    font-size: 13px;
    border: 1px solid #DDDDDD;
    border-radius: 2px;
    color: #333;
}

    input[type="text"].txtJumpTo:focus {
        border-color: #9490FF;
        box-shadow: 1px 1px 3px #9490FF;
        width: 48px;
    }

.jumpToSubmit {
    padding: 0;
    width: 18px;
    height: 18px;
    color: #006693;
    font-size: 13px;
    cursor: pointer;
    margin-right: 12px;
}

.jumpToSubmit:focus {
    outline: none;
}

.jumpTo {
    height: 33px;
    border: 1px solid #DDDDDD;
    border-radius: 2px;
    display: inline-block;
}

    .jumpTo label {
        color: #000000;
        font-family: proxima-nova;
        font-size: 13px;
        text-align: center;
        vertical-align: middle;
        line-height: 33px;
        margin-left: 12px;
    }

.jumpToErr {
    position: absolute;
    bottom: 110px;
    padding: 4px;
    border: 1px solid #D02C50;
    border-radius: 2px;
    background-color: #FEF1EC;
    color: #D02C50;
    text-align: center;
    font-size: .85em;
}

    .jumpToErr.hideMe {
        display: none !important;
    }

    .jumpToErr.showMe {
        display: block;
    }

.paging-counter {
    display: inline-block;
    height: 33px;
    border: 1px solid #DDDDDD;
    border-radius: 2px;
    float: right;
    text-align: center;
    vertical-align: middle;
    line-height: 33px;
    overflow: hidden;
}

    .paging-counter p {
        margin: 0px 12px 0px 12px;
        float: left;
        display: inline;
    }

.paging-controls {
    float: right;
    display: flex;
    color: #006693;
}

.tristate:hover {
    cursor: pointer;
}

.tristate, .tristateImg {
    margin: 0;
}
/********************************* END PAGING */


/********************************* BEGIN ADD TO CART SPLIT BUTTON */
.addToCartContainer .btn-group,
.addToCollectionContainer .btn-group {
    letter-spacing: initial;
}

.addToCollectionContainer {
    margin-top: 5px;
}

.sortBar .addToCollectionContainer {
    margin-top: 0;
}

.addToCartContainer ul li a.disabled,
.addToCartContainer ul li a.disabled:hover,
.addToCollectionContainer ul li a.disabled,
.addToCollectionContainer ul li a.disabled:hover,
.dropdown-submenu:hover > a.disabled:hover,
.addToCart.disabled:hover .addToCartDropdownIcons
 {
    background-color: #E6E6E6;
    background-image: none;
    color: #333333 !important;
    cursor: default;
}

.addToCartContainer input[type="text"] {
    float: left;
    margin: 2px 12px 0px 0px;
    width: 25px;
    height: 21px;
}

.addToCartContainer ul li a .cartDropdownIcon {
    float: left;
    margin: -2px 3px 0 -26px;
    width: 23px;
    height: 23px;
}

a.addToCart, a.addToCollection, a.addToNewCollection {
    color: #333333;
}

a.mainCartAddBtn, a.primaryAddToCollection {
    color: #0A2240;
}

a.addToCart:hover, a.addToCollection:hover, a.addToNewCollection:hover {
    color: #ffffff;
}

.btn-group > a.addToCart,
.btn-group > a.addToCollection,
.btn-group > a.addToNewCollection,
.atc-complete-inactive .btn-group > .btn:first-child {
    font-size: 13px;
    text-transform: uppercase;
}

.btn-group > a.addToCart
.btn-group > a.addToCollection {
    text-transform: uppercase;
}

.addToCartContainer ul li a,
.addToCollectionContainer ul li a {
    padding-left: 28px;
}
/********************************* END ADD TO CART SPLIT BUTTON */


/********************************* BEGIN CARTLIST */
div.message {
    margin: 1px 3px 0 0;
    padding: 10px 15px;
    border: 3px dashed #cccccc;
    border-radius: 8px;
}

.cartDragDropList ul {
    float: right;
    width: 99%;
    margin: 0;
    padding: 0 2px 0 0;
    list-style-type: none;
}

.cartDragDropList li {
    position: relative;
    display: block;
    float: left;
    margin: 0 0 10px 0;
    padding: 0;
    height: 122px;
    min-width: 147px;
    width: 100%;
    border: 1px solid #AAAAAA;
    background: #FCFCFC;
    box-shadow: 0 1px 3px rgba(0,0,0,0.06), 0 1px 2px rgba(0,0,0,0.14);
    font-weight: normal;
    overflow: hidden;
    transition: all 0.2s ease-in-out;
}

.cartDragDropList li:hover {
    box-shadow: 0 4px 12px rgba(0,0,0,0.08), 0 4px 4px rgba(0,0,0,0.13);
}

.cartDragDropList li.periodical-cart {
    height: 100px;
}

.cartNameLabel {
    clear: both;
    height: 28px;
    width: 100%;
    background-color: #EEEEEE;
    border-bottom: 1px solid #AAAAAA;
}

.cartNameLabel span {
    display: inline-block;
}

.cartNameLabel a span {
    color: #444444;
}

.cartDragDropList li.preorderCart .cartNameLabel {
    border-bottom: 1px solid #D02C50;
    background-color: #D02C50;
}

.cartDragDropList li:first-child .cartNameLabel {
    border-bottom: 1px solid #ffae00;
    background-color: #ffae00;
}

.cartDragDropList li:first-child .cartNameLabel a span {
    color: #FFF;
}

.cartDragDropList li.preorderCart .cartNameLabel a span {
    color: #FFF;
}

.cartNameLabel .locked-status-contain {
    float: right;
}

.cartDragDropList li.preorderCart  {
    border: 1px solid #D02C50;
}

.cartDragDropList.curate-ddl li {
    height: 100px;
}

.cart-quantity {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 3px;
}

#pinnedCarts li .cart-quantity,
#unpinnedCarts li .cart-quantity,
.cartDragDropList li .cart-quantity {
    background-color: #666666;
}

#pinnedCarts li.preorderCart .cart-quantity,
#unpinnedCarts li.preorderCart .cart-quantity,
.cartDragDropList li.preorderCart .cart-quantity {
    background-color: #D02C50;
}

#pinnedCarts li.ODcreated .cart-quantity,
#unpinnedCarts li.ODcreated .cart-quantity {
    background-color: #006693;
}

#pinnedCarts li:first-child .cart-quantity,
.cartDragDropList li:first-child .cart-quantity {
    background-color: #ffae00;
}

.cart-quantity.bar-cart00,
.cart-quantity.bar-periodicalsCart00,
.cart-quantity.bar-list0 {
    width: 10%;
}

.cart-quantity.bar-cart01,
.cart-quantity.bar-periodicalsCart01 {
    width: 20%;
}

.cart-quantity.bar-cart02,
.cart-quantity.bar-periodicalsCart02,
.cart-quantity.bar-list1 {
    width: 30%;
}

.cart-quantity.bar-cart03,
.cart-quantity.bar-periodicalsCart03 {
    width: 40%;
}

.cart-quantity.bar-cart04,
.cart-quantity.bar-periodicalsCart04,
.cart-quantity.bar-list2 {
    width: 50%;
}

.cart-quantity.bar-cart05,
.cart-quantity.bar-periodicalsCart05 {
    width: 60%;
}

.cart-quantity.bar-cart06,
.cart-quantity.bar-periodicalsCart06,
.cart-quantity.bar-list3 {
    width: 70%;
}

.cart-quantity.bar-cart07,
.cart-quantity.bar-periodicalsCart07 {
    width: 80%;
}

.cart-quantity.bar-cart08,
.cart-quantity.bar-periodicalsCart08,
.cart-quantity.bar-list4 {
    width: 90%;
}

.cart-quantity.bar-cart09,
.cart-quantity.bar-periodicalsCart09,
.cart-quantity.bar-list5 {
    width: 100%;
}

@media only screen and (max-height: 420px) {
    .cartDragDropList li:nth-child(1) ~ li {
        display: none;
    }
}
@media only screen and (max-height: 540px) {
    .cartDragDropList li:nth-child(2) ~ li {
        display: none;
    }
}
@media only screen and (max-height: 660px) {
    .cartDragDropList li:nth-child(3) ~ li {
        display: none;
    }
}
@media only screen and (max-height: 780px) {
    .cartDragDropList li:nth-child(4) ~ li {
        display: none;
    }
}
@media only screen and (max-height: 900px) {
    .cartDragDropList li:nth-child(5) ~ li {
        display: none;
    }
}
@media only screen and (max-height: 1020px) {
    .cartDragDropList li:nth-child(6) ~ li {
        display: none;
    }
}
@media only screen and (max-height: 1140px) {
    .cartDragDropList li:nth-child(7) ~ li {
        display: none;
    }
}
@media only screen and (max-height: 1260px) {
    .cartDragDropList li:nth-child(8) ~ li {
        display: none;
    }
}
@media only screen and (max-height: 1380px) {
    .cartDragDropList li:nth-child(9) ~ li {
        display: none;
    }
}
@media only screen and (max-height: 1500px) {
    .cartDragDropList li:nth-child(10) ~ li {
        display: none;
    }
}
@media only screen and (max-height: 1620px) {
    .cartDragDropList li:nth-child(11) ~ li {
        display: none;
    }
}
@media only screen and (max-height: 1740px) {
    .cartDragDropList li:nth-child(12) ~ li {
        display: none;
    }
}
@media only screen and (max-height: 1860px) {
    .cartDragDropList li:nth-child(13) ~ li {
        display: none;
    }
}
@media only screen and (max-height: 1980px) {
    .cartDragDropList li:nth-child(14) ~ li {
        display: none;
    }
}
@media only screen and (max-height: 2100px) {
    .cartDragDropList li:nth-child(15) ~ li {
        display: none;
    }
}


.search-sidebar__scrolling p.cartUnits {
    color: initial;
    font-size: .9em;
    margin: 0;
    padding: 2px 0 1px 6px;
}

.search-sidebar__scrolling p.cartUnits.cart-description {
    display: block;
    font-weight: bold;
    margin-right: 6px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.search-sidebar__scrolling p.cartUnits.curate-cu {
    font-size: .9em;
    font-weight: 500;
}

.search-sidebar__scrolling p.cartUnits.curator {
    margin-top: 4px;
}

.cartDragDropList .cartPrice {
    color: initial;
    font-size: .9em;
    font-weight: bold;
    margin: 0;
    padding: 2px 0 1px 6px;
}

.cartDragDropList li a {
    display: block;
    float: left;
}

.cartDragDropList li a.cartListDetailsBlock {
    font-size: 1.2em;
    font-weight: bold;
    margin: 4px 4px 0 6px;
    position: absolute;
    left: 0;
    right: 24px;
}

.cartDragDropList li a.cartListDetailsBlock span {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.cart-list-details-content-link {
    width: 100%;
}

.cartDragDropList li:first-child {
    font-weight: normal;
    border: 1px solid #ffae00;
}

    /*Preorder pinned carts (sidebar)*/
    .cartDragDropList li.preorderCart .cartSizeIcon,
    .cartDragDropList li.preorderCart .lockStatusIcon,
    .cartDragDropList li.preorderCart .lockStatusTxt,
    .cartDragDropList li.preorderCart .curateSizeIcon{
        color: #D02C50 !important;
    }
    .cartDragDropList li.preorderCart .lockStatus .Unlocked:hover,
    .cartDragDropList li.preorderCart .lockStatus .Private:hover,
    .cartDragDropList li.preorderCart .lockStatus .Unlocked:active,
    .cartDragDropList li.preorderCart .lockStatus .Private:active {
        cursor: pointer;
    }

    .cartDragDropList li:first-child .cartSizeIcon,
    .cartDragDropList li:first-child .lockStatusIcon,
    .cartDragDropList li:first-child .lockStatusTxt,
    .cartDragDropList li:first-child .curateSizeIcon{
        color: #FFAE00 !important;
    }
    .cartDragDropList li:first-child .lockStatus .Unlocked:hover,
    .cartDragDropList li:first-child .lockStatus .Private:hover,
    .cartDragDropList li:first-child .lockStatus .Unlocked:active,
    .cartDragDropList li:first-child .lockStatus .Private:active {
        cursor: pointer;
    }

.cartDragDropList li a .lockStatus {
    margin: 12px 3px -6px -2px;
    width: 150px;
}

#FloatingCartContainer, .box, .box2 {
    position: fixed;
    float: right;
    width: 15%;
}

#floatdiv, .box2 {
    position: absolute;
    top: 0px;
    min-width: 145px;
    width: 100%;
    height: 110px;
}

.cartDragDropList {
    margin-top: 1px;
}

.ActiveCarts {
    display: block;
    margin: 0;
    padding: 4px 2px;
    min-width: 145px;
    color: #ffffff;
    text-align: right;
    text-decoration: none;
    font-weight: 500;
    font-size: .9em;
}

    .ActiveCarts span {
        margin: -1px 3px 0 3px;
    }

    .ActiveCarts li a {
        color: #ffffff;
        font-size: .85em;
        cursor: pointer;
    }

        .ActiveCarts li a:hover {
            text-decoration: underline;
        }

    .ActiveCarts span a {
        display: inline;
        font-style: italic;
        cursor: pointer;
}

.cartSizeIcon {
    position: absolute;
    top: -39px;
    left: 0px;
    font-size: 4em;
    color: #aaaaaa;
}
.curateSizeIcon {
    position: absolute;
    top: 0px;
    left: 0px;
    font-size: 3em;
    color: #aaaaaa;
}
.cartTile .cartSizeIcon,
.cartTile .curateSizeIcon {
    position: relative;
    float:left;
    margin: 0 5px;
    font-size: 5.5em;
    color: #999999;
    line-height: 140px;
    z-index: 1;
}

#cartSummary.presale .cartSizeIcon,
#cartSummary.presale .curateSizeIcon
 {
    color: #B9193C;
}

#cartSummary.presale .cartHeader, #cartSummary.presale .cartHeader h2 a{
    background: #B9193C;
    border: 1px solid #B9193C;
    color: #ffffff;
}

#cartSummary.presale {
    border: 1px solid #B9193C;
    background: #ffffff;
}

/*pinned carts sidebar cart icon*/
.cartListDetailsBlock .cartSizeIcon {
    top: -8px;
}

#pinnedCarts .cartTile:first-child .cartSizeIcon,
#pinnedCarts .cartTile:first-child .curateSizeIcon {
    color:#FFAE00;
}

#pinnedCarts .cartTile:first-child hr {
    background-color:#FFAE00;
}

.cartTile hr {
    margin: 5px 10px 5px 0;
    background-color:#D02C50;
}

.cartTile.ODcreated .cartSizeIcon,
.cartTile.ODcreated .curateSizeIcon {
    color:#006693;
}

.cartTile.preorderCart .cartSizeIcon,
.cartTile.preorderCart .curateSizeIcon {
    color:#D02C50;
}
.search-sidebar__scrolling p.cartSimUsePubName {
    font-size: .9em;
    font-weight: bold;
    margin: 0;
    padding: 2px 0 1px 6px;
}

#salesRunThroughEndDates {
    max-height: 48px;
    margin-top: 24px;
    font-size: 13px;
}
/********************************* END CARTLIST */
/*****  BEGIN CART CSS IMPORT  *****/

.manageBtnsContainer {
    float: right;
}

div.selectAllCarts {
    float: right;
    margin: 12px 0 0 0;
}

    div.selectAllCarts input[type="checkbox"] {
        margin: 1px 3px 0 0 !important;
    }

#pinnedCarts, #unpinnedCarts {
    float: left;
    margin: 10px 0 0 0;
    padding: 0 0 25px 0;
    width: 100%;
    transition: all 0.1s ease-in-out;
}

.rule {
    clear: both;
    height: 1px;
    background: #3396C6;
}

/* ---- BEGIN Cart Item ---- */

.cartTile {
    position: relative;
    float: left;
    margin: 10px 20px 10px 0px;
    width: 280px;
    min-height: 280px;
    background: #FFF;
    box-shadow: 0 1px 3px rgba(0,0,0,0.06), 0 1px 2px rgba(0,0,0,0.14);
    border: 1px solid #cccbcb;
    transition: box-shadow 0.1s ease-in-out;
}

.cartTile.confirmation {
    min-height: 0;
}

.cartTileWithDisclaimer {
    min-height: 260px;
}

    .cartTile img {
        border: 0;
    }

.cartTile:hover {
    box-shadow: 0 4px 12px rgba(0,0,0,0.08), 0 4px 4px rgba(0,0,0,0.13);
}

#pinnedCarts li.cartTile.selected.ODcreated:hover, li.cartTile.selected.ODcreated:hover {
    background: rgba(232,247,254,0.5);
}

#pinnedCarts li.cartTile.selected:first-child {
    border: 1px solid rgba(255, 174, 0, 1);
    box-shadow: 0 4px 12px rgba(0,0,0,0.08), 0 4px 4px rgba(0,0,0,0.13);
}

#pinnedCarts li.cartTile.selected:first-child:hover {
    background: rgba(255,255,204,0.5);
}

.cartTile.selected {
    border: 1px solid rgba(0, 0, 0, .5);
    box-shadow: 0 4px 12px rgba(0,0,0,0.08), 0 4px 4px rgba(0,0,0,0.13);
}

.cartTile.selected:hover {
    background: #F6F6F6;
}

li.cartTile.preorderCart.selected:hover {
    background: rgba(250,234,238,0.5);
}

#pinnedCarts li.cartTile.ODcreated, li.cartTile.ODcreated {
    border: 1px solid #006693;
    background: #ffffff;
}

#pinnedCarts li.cartTile:first-child {
    border: 1px solid #ffae00;
    background: #ffffff;
}

 li.cartTile.preorderCart, li.cartTile.ODcreated:first-child .preorderCart, li.cartTile.ODcreated.preorderCart  {
    border: 1px solid #D02C50;
    background: #ffffff;
}

.cartHeader {
    height: 28px;
}

.cartTile .cartHeader {
    border: 1px solid #aaaaaa;
    background-color: #eeeeee;
    z-index: 99;
    position: absolute;
    left: 0;
    right: 0;
    margin: -1px -1px 0px -1px;
}

.cartPageTotals {
    background: #999999;
    color: #ffffff;
    vertical-align: top;
    text-align: center;
}

    .cartPageTotals .cartTotalPrice, .cartPageTotals .cartTotalUnits {
        color: #ffffff;
        font-size: 1em;
    }

#pinnedCarts .cartTile .cartHeader:hover { cursor: move; }

#pinnedCarts li.cartTile:first-child .cartHeader, #pinnedCarts li.cartTile.ODcreated:first-child .cartHeader, #pinnedCarts li.cartTile.preorderCart:first-child .cartHeader {
    border: 1px solid #FFAE00;
    background: #FFAE00;
}

li.cartTile.ODcreated .cartHeader {
    border: 1px solid #006693;
    background: #006693;
}

li.cartTile.preorderCart .cartHeader {
    border: 1px solid #D02C50 ;
    background: #D02C50 ;
}


.cartHeader h2 {
    float: left;
    overflow: hidden;
    margin: 4px 8px 0 0;
    width: 178px;
    text-overflow: ellipsis;
    font-weight: bold;
    font-size: 1.2em;
    font-family: 'proxima-nova', 'Segoe UI', 'Open Sans', Tahoma, Helvetica, sans-serif;
}

.maincartTile .cartHeader h2 a {
    color: #2f8bba;
    color: #fff !important;
}

.cartTile .cartHeader h2 a {
    color: #333;
}

#pinnedCarts .cartTile:first-child .cartHeader h2 a,
.cartTile.ODcreated .cartHeader h2 a,
.cartTile.preorderCart .cartHeader h2 a {
    color: #ffffff;
}

.cartHeader h2 a:hover {
    text-decoration: underline;
}

.cartTile input[type='checkbox'] {
    float: left;
    margin: 7px 6px 0 6px;
    padding: 0px;
}


    #pinnedCarts li.cartTile:first-child:hover .cartHeader, #pinnedCarts li.cartTile:first-child:focus .cartHeader {
        background-color: #FFAE00 !important;
    }


.cartDetails {
    display: block;
    color: #333 !important;
    margin-top: 28px;
    padding: 8px;
    min-height: 236px;
}

a.cartDetails {
    text-decoration: none !important;
}

a.cartDetails.confirmation {
    min-height: 0;
}

.cartTile p {
    margin: 0;
}

.cartTile p.description {
    clear: both;
    padding: 0;
    color: #333;
    font-size: 12px;
    width: 90%;
    font-weight: bold;
}

.cartTile p.description.parentAccountText {
    font-weight: 500;
}

p.description {
    clear: both;
    margin: 0;
    padding: 0;
    color: #333;
    font-size: 12px;
}

.parentAccountText.description.plusSimUse {
    margin: -5px 0 5px 7px;
}

.cartPriceInfo {
    margin: 10px auto;
}

p.units {
    font-size: 1.1em;
}
 label.presaleCopy {
     font-size: 12px;
     letter-spacing: -0.3px;
 }

p.totalPrice {
    font-size: 1.4em;
    font-family: 'proxima-nova', 'Segoe UI', 'Open Sans', Tahoma, Helvetica, sans-serif;
    font-weight: bold;
}

p.lastEdited {
    margin-top: 8px;
    color: #666;
    font-size: .9em;
    position: absolute;
    bottom: 12px;
    white-space: initial;
    overflow: hidden;
    text-overflow: ellipsis;
}

.cartDetails p.lastEdited {
    max-width: 160px;
}

p.lastEdited.cart-pre-margin-65 {
    position: relative;
    bottom: 0;
    padding-top: 16px;
    clear: both;
}

#cartSummary p.lastEdited {
    margin-bottom: 8px;
}


.cartImage {
    float: left;
    margin: 0px 20px 10px 0px;
    width: 80px;
    height: 80px;
}


.cart-po-units,
.cart-po-price,
.cart-po-label{
    color: #D02C50;
}

.cartDetails .units {
    padding-top: 16px;
}

.cartDetails .units.confirmation {
    padding-top: 4px;
}

.cartDetails .units.confirmation.preorder {
    color: #D02C50;
}

.cart-container p.units.confirmation.total-units {
    font-size: 14px;
    font-weight: bold;
}

label.confirmation {
    display: block;
    padding-top: 16px;
}

/* ---- END Cart Item ---- */

/* BEGIN CART ACTION BUTTONS */

ul.cartActions {
    float: right;
    width: 54px;
}

.cartActions li {
    float: left;
    width: 27px;
}

.cartActions li a {
    float: left;
    margin: 0;
    width: 26px;
    height: 26px;
    border: none;
    background-color: none;
}

.cartActions li a.lockStatusIcon span {
    display: block;
    margin-top: 4px;
}
.togglePinBtn a span {
    display: block;
    margin-top: 1px;
}

.editInfoBtn {
    position: absolute;
    right: 6px;
    top: 34px;
}

.editInfoBtn a {
    color: #444;
    font-size: 16px;
    text-decoration: none;
}

.cartActions .togglePinBtn, .cartActions .editInfoBtn {
    text-align: center;
}

.cartActions a,
#pinnedCarts .togglePinBtn a {
    color: #666666;
}

#unpinnedCarts .cartTile.ODcreated .togglePinBtn a,
#pinnedCarts .cartTile.ODcreated .togglePinBtn a,
#unpinnedCarts .cartTile.preorderCart .togglePinBtn a,
#pinnedCarts .cartTile.preorderCart .togglePinBtn a,
#pinnedCarts .cartTile:first-child .togglePinBtn a {
    color: #FFF;
}


.cartActions .togglePinBtn a span, .cartActions .editInfoBtn a span {
    text-align: center;
    font-size: 1.25em;
    line-height: 25px;
}

.cartActions .togglePinBtn a:hover, .cartActions .editInfoBtn a:hover, .cartActions .togglePinBtn a span, .cartActions .editInfoBtn a span {
    text-decoration: none !important;
}

li.editInfoBtn a {
    color: #666666;
}

li.editInfoBtn a:hover, li.editInfoBtn a:focus {
    background: #b2b2b2;
}

#pinnedCarts li.cartTile:first-child:hover li.editInfoBtn a:hover,
#pinnedCarts li.cartTile:first-child:hover li.editInfoBtn a:focus {
    background: #dc9702;
}

li.editInfoBtn a:active { cursor: pointer; }

li.editInfoBtn a.Locked { visibility: hidden; }

/* ---- END Cart Action Buttons ---- */

/* ---- BEGIN Main Cart Notification ---- */

#pinnedCarts li.cartTile:first-child .mainCart {
    display: block !important;
    float: left;
    clear: both;
    padding: 5px 0 0 27px;
    height: 25px;
    color: #FFAE00;
    font-weight: bold;
}


#pinnedCarts li.cartTile:first-child .mainCartIcon {
    width: 25px;
    height: 25px;
}

/* ---- END Main Cart Notification ---- */

#pinnedCarts li.cartTile:first-child .purchaseBtn {
    color: #FFF;
    background: #FFAE00;
    transition: all 0.1s ease-in-out;
    border: 1px solid #E69500;
}

#pinnedCarts li.cartTile:first-child .purchaseBtn:hover {
    border: 1px solid #E69500;
    background: #E69500;
}
li.cartTile.preorderCart .purchaseBtn:hover {
    background: #B9193C;
}

#modal .button {
    float: none;
    cursor: pointer;
    margin-bottom: 4px;
}

#modal .button:first-of-type {
    margin-left: 0;
}

.cartTile .purchaseBtn {
    position: absolute;
    bottom: 14px;
    right: 8px;
    z-index: 999;
    padding: 6px 10px;
    border-radius: 3px;
    background-color: #eeeeee;
    background-repeat: repeat-x;
    color: #444444;
    text-align: center;
    font: 9pt;
    cursor: pointer;
    transition: all 0.1s ease-in-out;
    text-transform: uppercase;
    font-size: 1em;
    font-weight: bold;
    letter-spacing: .5px;
    border: 1px solid #aaaaaa;
}

.cartTile .purchaseBtn.cart-button-wide {
    max-width: 110px;
    padding: 6px;
}

.cartTile .purchaseBtn:hover {
    background-color: #aaaaaa;
    color: #ffffff;
}

.cartTile.preorderCart .purchaseBtn {
    background-color: #D02C50;
    border: 1px solid #D02C50;
    color: #ffffff;
}

.cartTile.ODcreated  .purchaseBtn {
    background-color: #006693 ;
    border: 1px solid #006693;
    color: #ffffff;
}

.purchaseCartBtn {
    display: inline-block;
    border: 0;
    background: #8EC549;
    border: 1px solid #75AC30;
    color: #ffffff !important;
    transition: all 0.1s ease-in-out;
    text-transform: uppercase;
    /* Chrome 26, Firefox 16+, IE 10+, Opera 12.50+ */
}

    .purchaseCartBtn:hover {
        background: #75AC30;
        border: 1px solid #75AC30;
        /* Chrome 26, Firefox 16+, IE 10+, Opera 12.50+ */
    }

    .purchaseCartBtn.disabled:hover {
        background: #8EC549;
        border: 1px solid #75AC30;
        /* Chrome 26, Firefox 16+, IE 10+, Opera 12.50+ */
    }

/*---- BEGIN Modal Styles ----*/

iframe body {
    background: rgba(33, 33, 33, .05);
}

#modal h1 {
    display: block;
    margin: 0;
    padding: 3px 10px;
    width: 90%;
    font-size: 1.5em;
}

#modal h2 {
    margin: 0 0 10px 0;
    padding: 0;
    color: #000;
    font-size: 1em;
}

h2.subTitle {
    float: left;
    clear: both;
    margin-bottom: 0;
    width: 80%;
}

h2.subTitle span {
    font-size: .8em;
}

#modal .modalContent {
    padding: 10px;
}

#modal .requiredFields {
    margin-bottom: 10px;
}

#modal fieldset {
    margin: 3px 0px 10px;
    padding: 10px;
    border: 1px solid #999;
    border-radius: 3px;
    box-shadow: 0px 0px 1px 1px rgba(255, 255, 255, .5);
}

#modal ul.selectedCarts {
    display: block;
    margin: 0 35px;
    list-style-type: disc;
}

#modal form {
    overflow: hidden;
}

#modal .editor-label {
    margin-top: 15px;
}

#modal p {
    clear: both;
    margin: 0;
    padding: 0;
}

#modal p.field-validation-error {
    float: none;
    margin-bottom: 0px;
}

#modal span.field-validation-error {
    float: none;
}


#modal label {
    display: inline;
}

/* make sure labels for checkboxes stay flex */
#modal label.iconInput__container--checkbox,
#modal label.iconInput__container--radio-button {
    display: inline-flex;
}

#modal label.disabled {
    background: none !important;
    opacity: .5;
}

#modal .x-panel label {
    display: none;
}

#modal input[type="radio"] {
    vertical-align: top;
}

#modal span.disabled {
    font-weight: normal;
}

#modal span.note {
    padding-left: 24px;
    font-weight: normal;
}

#modal input[type="text"] {
    width: 312px;
}
#modal .x-panel input[type="text"], #modal .x-panel textarea {
    width: 100%;
}

#modal .jq-date {
    width: 134px !important;
}

#modal textarea {
    height: 40px;
    resize: none;
    width: 312px;
}

#modal .editor-field input[type="checkbox"] {
    margin: 7px 0 0;
}

#step1 .editor-field {
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #DDD;
    border-radius: 5px;
    background: white;
}

.editor-field {
    margin-bottom: 10px;
}

#modal .ln__decrease-units label input.quantityBox {
    width: 50px;
    float: right;
    margin-top: -4px;
}

#modal .curate__create-collection-form.detailForm .inputField {
    margin-bottom: 18px;
}

#modal .curate__create-collection-form.detailForm .inputField .inputLabel {
    display: block;
    margin-bottom: 2px;
}

#modal .curate__create-collection-form.detailForm .inputField input[type="text"] {
    display: block;
}

#modal .curate__create-collection-form span.field-validation-error {
    margin-bottom: 0;
}

#modal .curate__create-collection-form.detailForm .requiredAsterisk {
    left: 0;
}

.curate__create-collection-form .inputField.required .inputLabel {
    margin-left: 8px;
}

#modal form .inputField {
    clear: both;
}

/*---- END Modal Styles ----*/

/*---- BEGIN Tooltips (TippyJs) ----*/

.tippy-content {
    padding: 0px;
}

.tooltip__content {
    color: #ffffff;
    text-align: left;
}

.tooltip__wrapper h2 {
    font-family: proxima-nova;
    font-weight: bold;
    font-size: 18px;
    color: #ffffff;
    margin: 0px;
    padding-left: 18px;
    padding-top: 18px;
    white-space: nowrap;
}

.tooltip__wrapper h1 {
    font-family: proxima-nova;
    font-size: 18px;
    color: #ffffff;
    padding: 18px;
    margin: 0px;
}

.tippy-tooltip.dark-blue-theme {
    background: #0A2240;
    border: 1px solid #006693;
    border-radius: 5px;
    padding: 0px;
}

.dark-blue-theme .tooltip__icon-close {
    margin: 12px 12px 0px 0px;
    font-size: 18px;
    cursor: pointer;
}

.dark-blue-theme .tooltip__wrapper p {
    font-size: 16px;
    font-family: proxima-nova;
    margin: 0px;
    margin-top: 10px;
    padding-left: 18px;
    padding-right: 48px;
    padding-bottom: 18px;
    width: 290px;
}

.tooltip__wrapper .icon-close {
    float: right;
    color: #DDDDDD;
    cursor: pointer !important;
}

.light-green-theme .tooltip__wrapper .icon-close {
    margin-top: 3px;
}

.tippy-popper[x-placement^=top] .dark-blue-theme .tippy-arrow {
    border-top-color: #0A2240 !important;
    transform: scale(2.3) !important;
    left: 138px !important;
}

.tippy-popper[x-placement^=right] .dark-blue-theme .tippy-arrow {
    border-right-color: #0A2240 !important;
    transform: scale(1.8) !important;
    top: 50px !important;
}

.tippy-popper[x-placement^=bottom] .light-green-theme .tippy-arrow {
    border-bottom-color: #8EC548 !important;
    transform: scale(2.3) !important;
}

.tippy-tooltip.light-green-theme {
    background: #8EC548;
    border: 1px solid #87B150;
    border-radius: 5px;
    text-shadow: 0px 1px 1px #333333;
    padding: 0px;
}

.tooltip__ext-worksheet-btn-theme .tooltip__icon-close {
    margin-top: 3px;
}

/*---- END Tooltips (TippyJs) ----*/
/*---- BEGIN Cart Details ----*/
.purchaseBtnGroup {
    float: right;
    margin: 0;
    text-align: right;
}

.purchaseConfirmGroup {
    clear: both;
    margin: 20px 0;
}

#cartSummary h1.cartName {
    overflow: hidden;
    margin-bottom: 5px;
    color: #19303D;
    text-overflow: ellipsis;
    font-weight: bold;
}

#cartSummary .editCart {
    float: right;
    margin: 8px;
}

#cartSummary {
    margin: 0 20px 0 0;
    color: #333;
}

    #cartSummary .cartHeader h2 {
        margin-left: 7px;
        width: 265px;
    }

.purchaseConditions {
    float: left;
    margin: 0 0 0 20px;
    padding: 0;
    min-width: 520px;
    width: 60%;
}

    .purchaseConditions p {
        margin: 0;
        padding: 0;
    }

.purchase-confirmation__modal-price {
    color: #0A2240;
    font-weight: 700;
    font-size: 1.8em;
    margin: 0;
}

.pageHeading .billing-account-info {
    font-size: 1.1em;
    padding: 12px 36px 12px 18px;
    border: 1px solid #ddd;
    border-radius: 3px;
}

p.confirmationHeader {
    margin-bottom: 30px;
    font-weight: bold;
    font-size: 1.2em;
    clear: both;
}

.purchaseConditions .editor-field {
    clear: both;
    margin: 15px;
}

.purchaseConditions .specialConditions .editor-field {
    clear: both;
    margin: 15px 15px 15px 2px;
}

.purchaseConditions .editor-field input[type="radio"],
.purchaseConditions .editor-field input[type="checkbox"] {
    float: left;
    margin: 3px 10px 40px 0;
}

.purchaseConditions .orderID {
    display: block;
    margin-top: 20px;
}

    .purchaseConditions .orderID label {
        font-weight: bold;
    }

    .purchaseConditions .orderID input[type="text"] {
        display: block;
        margin-top: 10px;
        width: 360px;
    }


h1.cartName {
    float: left;
    margin: 0;
    padding: 0;
}

h2.cartDescription {
    clear: both;
    margin: 0 0 10px 0;
    padding: 0;
    height: 20px;
    color: #333;
    font-size: 11px;
}

#CartDetailsTable .title, #CartDetailsTable .creators, #CartDetailsTable .subjects, #CartDetailsTable .formats, #CartDetailsTable .audience {
    text-align: left;
}

span.CartDisclaimer {
    color: #666;
    font-size: 11px;
    margin: 6px 0;
    display: block;
}

td.priceInfo {
    margin: 0 9px;
    padding: 13px 0;
    text-align: right;
}

    td.priceInfo form {
        display: inline-block;
    }

    td.priceInfo p {
        margin: 0;
        padding: 0;
    }

.usageNote {
    font-size: 10px;
    margin: 2px 0;
}

.cartInfo {
    float: left;
}

.cartTotals {
    float: right;
    font-weight: bold;
}

    .cartTotals.left {
        float: left !important;
    }

    .cartTotals p {
        margin: 0;
        padding: 0;
    }

.cartTotalUnitsLabel, .cartTotalUnits {
    font-weight: normal;
    font-size: 11pt;
}

.cartTaxLabel, .cartTax {
    font-weight: normal;
    font-size: 11pt;
}

.cartPriceLabel, .cartPrice {
    font-weight: normal;
    font-size: 11pt;
}

.cartTotalPriceLabel, .cartTotalPrice, .cartTotalUnits {
    font-size: 1.8em;
}

.cartTotalUnitsLabel, .cartTotalPriceLabel {
    padding-right: 10px;
    text-align: right;
}

.cartTaxLabel, .cartTaxLabel {
    padding-right: 10px;
    text-align: right;
}

.cartTotalUnits {
    text-align: left;
    font-weight: normal;
}

#PageContent .cartTotalUnits { font-size: 11pt; }
#PageContent .cartTotalPriceLabel, #PageContent .cartTotalPrice, .cartTotalPreorderPrice  { font-size: 1.5em; }

.cartTotalPrice, .cartTotalPreorderPrice {
    color: #D02C50;
    text-align: left;
}

p.creator {
    margin: 0;
    padding: 0;
}

.DataTables_sort_wrapper {
    text-align: center;
}

.cartName.ellipsis {
    width: auto;
    font-size: 2.5em;
    margin: 8px 0;
    color: #0A2240;
    font-family: 'FreightTextProBook-Regular', sans-serif;
}

.cartSizeIconInCartDetails {
    margin: -10px 10px 0 0;
    font-size: 5em;
    color: #999999;
    height: 80px;
}

.preorder-tax-total, .preorder-total {
    margin-top: 10px;
    text-align: right;
}

.add-grid__container {
    text-align: right;
}

.manually-load-cart__container {
    padding: 10px;
    display: inline-block;
    border: 1px solid #AE4646;
}

.manually-load-cart__container p {
    margin: 0 0 10px 0;
}

/* BEGIN GRID COLUMN HELPER CSS on CART DETAILS*/

.gch__grid-col-helper {
    border: 1px solid #AAAAAA;
    border-radius: 4px;
    margin-top: 10px;
    padding-top: 10px;
    opacity: 1;
    transition: all 0.3s ease-in-out;
}

.gch__grid-col-helper ul {
    margin: auto;
    padding: 0;
    list-style: none;
    margin-left: 16px;
}

.gch__grid-col-helper ul li {
    font-weight: bold;
}

.gch__grid-col-helper ul li ul li {
    display: inline;
    text-transform: none;
}

.gch__grid-col-helper ul li ul li:first-child {
    margin-left: 10px;
}

.gch__grid-col-helper ul li ul li a {
    text-transform: none;
    vertical-align: middle;
}

.columnGroupLabel {
    float:left;
    width: 50px;
    padding: 4px 0 0 0;
    margin: 0;
}

.gch__heading {
    cursor: pointer;
    margin-left: 0;
}

.gch__contain {
    margin-bottom:20px;
    overflow: hidden;
    max-height: 164px;
    transition: all 0.3s ease-in-out;
}

.gch__icon--arrow-down {
    display: inline-block;
    font-size: 12px;
    font-weight: bold;
    margin-left: 5px;
    margin-right: 0;
    transition: transform 0.3s ease-in-out;
}

.gch--hide {
    max-height: 0;
}

.gch--hide .gch__grid-col-helper {
    opacity: 0;
}


/*---- END Cart Details ----*/



/********************************* BEGIN EDGE-CASE FIXES */
@-moz-document url-prefix() {
    .cartTile p.description.simUse {
        height: 32px !important;
    }

    .cartTile {
        padding-bottom: 10px;
    }

}

/****  reports summary  ****/
.report-input-display {
    border-spacing: 0;
    border-collapse: collapse;
    margin: 10px 0;
}

.report-input-display td {
    border: 1px solid #ffffff;
    background: #f0f0f0;
    padding: 5px;
}

.report-input-display .label {
    font-weight: bold;
}

div.grid__container-div.report__input-display {
    margin: 10px 0;
}

.report__input-display .grid__column span {
    display: block;
    border: 1px solid #ffffff;
    background: #f0f0f0;
    padding: 5px;
    min-height: 1rem;
}

.report__input-display--label {
    font-weight: bold;
}

#tblConsResults tr .label,
#tblAdvResults tr .label {
    width: 60%;
}


.floatingControls
{
    clear: both;
}

.lockStatus
{
    position: relative;
}

.reports-wrapper
{
    clear: both;
}

.setAll input.normalBtn.button
{
    padding: 4px 10px;
    background-color: #e2e2e2;
    border: 1px solid #AAA;
    color: #333333 !important;
}

.setAll input.normalBtn.button:hover
{
    background-color: #999;
    border: 1px solid #999;
    color: #FFF !important;
}
.setAll label
{
    display: inline-block;
    margin: 5px 5px 5px -2px;
}

.setAll label.left
{
    margin: 5px 0 5px;
}

.updateQuantityContainer
{
    clear: both;
    float: left;
    margin: 10px 0;
    width: 78%;
    max-width: 1135px;
}

.cartTotalPriceBeforeTax
{
    color: #333333;
    font-weight: normal;
    font-size: 11pt;
    text-align: left;
}

.additionalSortColumns {
    float: left;
    margin-bottom: 5px;
}

#CartDetails .x-grid-cell-inner
{
    white-space: normal;
}

#CartDetails .titlestate-preorder .x-grid-cell {
    background-color: rgba(250, 95, 95, 0.4);
}

#CartDetails .titlestate-preorder.x-grid-row-selected .x-grid-cell {
    background-color: rgba(250, 95, 95, 0.65);
}

/*Cart row highlight for titles with invalid distribution model */
#CartDetails .titlestate-invalidDisModel .x-grid-cell {
    background-color: rgba(232, 228, 14, 0.4);
}

#CartDetails .titlestate-invalidDisModel.x-grid-row-selected .x-grid-cell {
    background-color: rgba(232, 228, 14, 0.65);
}


/********************************* BEGIN MARKETPLACE PLUS DROPDOWN */

#carousels h2 a span, #carousels h2 a.disabled:hover span {
    color: #333333 !important;
    display: block;
    margin-left: 2px;
    font-size: 1em;
    border: none;
}

#carousels h2 a:hover span {
    color: #ffffff !important;
}

#suggestedAd-homepage-secondary {
    padding-top: 60px;
    padding-bottom: 120px;
}

.suggestedAd-rotating [class^="icon-"] {
    font-size: 1em;
}

.arrowNext.upDown, .arrowPrev.upDown, .arrowNext.upDown.disabled, .arrowPrev.upDown.disabled {
    margin: 0;
    padding: 0;
    height: 10px;
    border: none;
    border-radius: 0;
    background: none;
    box-shadow: none;
    text-shadow: none;
}
/********************************* END MARKETPLACE PLUS DROPDOWN */

/********************************* BEGIN CART PAGE */
.cartCheckbox:hover {
    cursor:pointer;
}

.cartCheck {
    float: left;
    margin: 0 5px 0 0;
    padding: 0px;
    width: 13px;
}

#navBar .cartUnits {
    margin: 0 5px 0 0;
    padding: 0 5px 0 0;
    border-right: 1px dotted #777777;
}

.lndng-mdl-inactive {
    display: none;
}

.lending-model-atc,
.lending-model-prices {
    float: right;
    margin-bottom: 5px;
    clear: both;
    text-align: right;
}

.lending-model-labels {
    display: flex;
    flex-direction: row;
    min-width: 160px;
    margin-bottom: 5px;
    clear: both;
}

.lending-model-labels .lendingExpire {
    flex: 1 0 auto;
    display: flex;
    flex-direction: row;
    margin-left: 0 !important;
    margin-bottom: 12px;
}

.atc-complete-inactive,
.atc-complete-inactive a,
.atc-complete-inactive a span.caret {
    color: #999;
    cursor: default;
}

.atc-complete-inactive a span.caret,
.atc-complete-inactive a span.caret:hover {
    border-top-color: #999;
}

.atc-complete-inactive a:hover {
    background-color: #e2e2e2;
    color: #999;
}

.atc-complete-inactive a:hover span.caret {
    border-top-color: #999;
}

.titlePrice {
    display: inline-block;
    margin: 0 0 0 5px;
    color: #0A2240;
    font-weight: 700;
    font-size: 1.8em;
}

.price-complete .price-model {
    display: inline-block;
    margin: 5px 0 0px 10px;
    vertical-align: top;
}

.price-complete {
    position: relative;
}

.lending-model-labels p {
    margin: 0 0 5px 0;
    font-size: 12px;
    color: #666;
    align-self: flex-end;
}

.title-note__status {
    color: #666666;
    letter-spacing: -0.32px;
    border: 1px solid #AAAAAA;
    border-radius: 5px;
    text-transform: uppercase;
    padding: 5px 8px;
    margin-left: 2px;
}

#fancybox-left span {
    left: 15px;
}

#fancybox-right span {
    right: 15px;
    left: auto;
}

#fancybox-overlay {
    min-height: 100% !important;
    position: fixed !important;
}

.mp-fancybox-loading{
    background:#fff url("/Content/images/working.gif") no-repeat 50% 50% !important;
    min-height: 100px;
    z-index: 1110;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

#fancybox-wrap #fancybox-close {
    position: absolute;
    z-index: 1120; /*Value must be greater than .mp-fancybox-loading's*/
    top: -5px;
    right: 5px;
}

.fancybox__borderless-rounded #fancybox-outer {
    border: none;
    box-shadow: none;
    border-radius: 4px;
    padding: 10px 10px 6px 10px;
    width: auto;
}

.fancybox__borderless-rounded .mp-fancybox-loading,
.fancybox__borderless-rounded #fancybox-content {
    border-radius: 4px;
}

.fancybox__borderless-rounded #fancybox-close {
    margin-top: 5px;
}

.fancybox__position-fixed--south-west#fancybox-wrap {
    position: fixed;
    top: auto !important;
    left: 1% !important;
    bottom: 12px !important;
}

.fancybox__overlay-hidden #fancybox-outer {
    border: 1px solid #DDDDDD;
    box-shadow: -4px 4px 18px rgba(0, 0, 0, .15);
}

.fancybox__overlay-hidden #fancybox-outer .fancybox-bg {
    display: none;
}

.fancybox__overlay-hidden#fancybox-overlay {
    background-color: transparent !important;
}

.fancybox__outer-padding--none #fancybox-outer,
.fancybox__outer-padding--none #modal .modalContent {
    padding: 0;
}

.titlePreviewLink {
    top: -1px;
    font-weight: normal;
}

.cart-itemtotals-total {
    font-weight: 500;
}

.premium-review__button-group {
    margin-top: 6px;
}

.premium-review__button-group a:hover {
    text-decoration: underline;
}

.premium-review__button-group .icon-awards {
    font-size: 16px;
    margin-right: 0;
    vertical-align: text-top;
}

#listViewTitles .premium-review__button-group .dropdown-menu {
    min-width: 100px;
    text-align: left;
    right: initial;
    top: 22px;
    border-radius: 3px;
    left: 22%;
}

/********************************* END CART PAGE */


/*********************************BEGIN SU MENU FLYOUT */
.simuse-plan-info {
    font-size: 11px;
    letter-spacing: initial;
}

/*********************************END SU MENU FLYOUT   */

/********************************* BEGIN STANDARD FORMS (AUTOMATED CARTS, ETC.) */
/* -- dataTables -- */
.dataTables_wrapper table, .jqSortableTable table, table.jqSortableTable {
    width: 100%;
    border: 1px solid #999999;
    table-layout: fixed;
}

    table.jqSortableTable th:last-child {
        border-right: 2px solid #666666;
    }

.dataTables_wrapper th, .jqSortableTable th {
    padding-top: 5px;
    padding-bottom: 5px;
}

.dataTables_wrapper td, .jqSortableTable td {
    overflow: hidden;
    padding: 2px;
    border-right: 1px solid #cccccc;
    vertical-align: middle;
    text-overflow: ellipsis;
}

.jqSortableTable td.ISBN {
    word-wrap: break-word;
}

    .dataTables_wrapper td.noEllipsis, .noEllipsis {
        text-overflow: clip !important;
    }

    .dataTables_wrapper td:last-child, .jqSortableTable td:last-child {
        border-right: none;
    }

.button.normalBtn.downloadLink.right {
    margin-bottom: 10px;
}

.textLeft {
    text-align: left !important;
}

.textRight {
    text-align: right !important;
}

.textCenter {
    text-align: center !important;
}

.planValue {
    width: 60px;
    text-align: right;
}
/* -- General Form CSS -- */
.detailForm :where(h2) {
    border-bottom: 1px solid #e2e2e2;
}

.inputSectionHeader {
    margin: 12px auto;
}

.placeholder {
    color: #aaa;
}

.requiredFields {
    margin: 0 0 20px 0;
    padding: 0;
    font-size: .9em;
}

.detailForm .inputField {
    position: relative;
    margin-bottom: 10px;
}

    .detailForm .inputField .inputLabel, .detailForm .inputField .inputControl {
        display: inline-block;
        margin-bottom: 5px;
        padding: 0 0 0 2px;
        vertical-align: top;
    }

    .detailForm .inputField .inputControl {
        margin-left: 15px;
    }


.detailForm .inputField .inputControl p {
    max-width: 800px;
}

.detailForm .inputField .input--lrg-box {
    width: 250px;
}

.detailForm .inputLabel {
    margin-bottom: 10px;
    width: 165px;
}

.detailForm .widthFull .inputLabel {
    margin-bottom: 10px;
    width: 250px;
}

.detailForm .labelTextSub {
    display: block;
    width: 60%;
    color: #999999;
    word-wrap: break-word;
    font-weight: normal;
    font-size: .7em;
}
/*got rid of margin-bottom:-10px; does not working ie7*/
.detailForm .inputControl label {
    margin: 0 5px;
}

.detailForm .widthFull {
    width: 100%;
}

.detailForm .labelText {
    display: block;
    margin-top: 3px;
}

.detailForm :where(input[type="checkbox"]) {
    margin-right: 8px;
}

.inputControl :where(input[type="text"], input[type="password"], input[type="number"], textarea) {
    margin-top: 2px;
}

.detailForm :where(textarea) {
    height: 100px;
}

.detailForm .cc-savecard-contain .checkboxList {
    margin-left: -4px;
}

.inputField.cc-radio {
    margin-bottom:15px;
}

.inputField.cc-radio input[type="radio"] {
    margin: 3px 10px 0 0;
}

.inputField.cc-savedCard label,
.inputField.cc-radio label {
    color: #bbb;
}

.inputField.cc-savedCard input[type="radio"]:checked + span,
.inputField.cc-radio input[type="radio"]:checked + span,
.inputField.cc-savedCard input[type="radio"]:checked ~ span,
.inputField.cc-radio input[type="radio"]:checked ~ span,

.inputField.cc-savedCard input[type="radio"]:checked + label,
.inputField.cc-radio input[type="radio"]:checked + label,
.inputField.cc-savedCard input[type="radio"]:checked ~ label,
.inputField.cc-radio input[type="radio"]:checked ~ label{
    color: #333;
}

#savedCardSection .cc-savedCard {
    margin-left: 20px;
}

.cc-labelTextSub {
    display: block;
    color: #999999;
    word-wrap: break-word;
    font-weight: normal;
    font-size: .7em;
}

#newAccountSection select {
    width: 134px;
}

.detailForm .required {
    color: #333333;
}

.detailForm .requiredAsterisk, #accountSettingsForm .requiredAsterisk {
    position: absolute;
    top: 2px;
    left: -7px;
    color: var(--asterisk-red);
}

#accountSettingsForm .requiredAsterisk {
    top: 0px;
}

#profileEditForm {
    margin-left: -10px;
}

#accountSettingsForm {
    padding-left: 10px;
}

.editor-field.required {
    position: relative;
}

.asterisk,
.asterisk--static {
    position: relative;
}

.asterisk::before,
.asterisk--static::before {
    content: '*';
    color: var(--asterisk-red);
    font-weight: 500;
    font-size: 1em;
    position: absolute;
    transform: translateX(-160%);
}

.asterisk--static::before {
    position: static;
}

.superscriptNumeral {
    font-weight: 500;
}

.detailForm .orderPlan {
    margin: 10px 0;
}

.detailForm :where(select) {
    margin: 5px 0;
    width: 450px;
    border: 1px solid #AAA;
    overflow-x: auto;
}

.detailForm select.pl-select--lrg-box {
    width: 100%;
}

.detailForm select.select--sml-box {
    width: 60px;
}

.detailForm select.select--med-box {
    width: 20px;
}

.toolTipLink {
    margin-left: 4px;
    font-weight: normal;
    font-size: 8pt;
}

.jquiCalendar {
    margin-right: 4px !important;
}

.plControl {
    width: 700px;
}

/*Password Reset*/
#Reset .customReset {
    width: 340px !important;
}

.detailForm .customTop {
    clear: both;
    margin-bottom: 25px !important;
}

.detailForm .customLeft {
    float: left;
    clear: both;
    width: 180px !important;
}

.detailForm .customRight {
    float: left;
    width: 130px;
}

.passwordResetError {
    width: 330px;
}

/* -- Control Specific CSS -- */
.detailForm .inputText, .detailForm :where(textarea) {
    width: 443px;
}

/* -- Confirmation Window CSS -- */
.confirmDialog .inputField {
    margin-bottom: 6px;
    padding-left: 12px;
}

.confirmDialog .inputLabel {
    display: inline-block;
    width: 30%;
    vertical-align: top;
    font-weight: 500;
}

.confirmDialog .inputControl {
    display: inline-block;
    width: 65%;
}

.plControl button {
    width: 32px;
}

.ui-datepicker-trigger {
    position: relative;
    top: 4px;
}

#ui-datepicker-div {
    border: 1px solid #999999;
    background-color: white !important;
    background-image: linear-gradient(to bottom, #fefefe, #eeeeee);
}

#from, #to {
    margin: 0 5px 0 0;
    width: 103px;
}

#to {
    margin-right: 5px;
}

label.hintFrom {
    position: relative;
    top: -18px;
    left: -139px;
    color: #999999;
    font-size: .7em;
}

label.hintTo {
    position: relative;
    top: -18px;
    left: -139px;
    color: #999999;
    font-size: .7em;
}

.cap {
    width: 150px !important;
}

label.hintCap {
    position: relative;
    margin-right: -83px;
    color: #999999;
    font-size: .7em;
    /*left: -156px;*/
    /*top: -18px;*/
}

.usernameField {
    width: 170px;
    text-align: left;
}

.userEditField {
    width: 350px;
}

.checkboxList {
    padding-top: 5px !important;
    width: 650px;
}

.checkboxError {
    margin-left: 170px !important;
}

.permissionsChecklist {
    padding: 0px 0px 12px 20px !important;
    width: 220px;
}

.permissionsChecklist div.iconInput--container {
    float: left;
    width: 26px;
    top: -3px;
}

.roleCheckbox {
    margin-bottom: 9px !important;
}

label.disabled {
    opacity: 0.5;
}

.confirmToast {
    clear: both;
    margin-bottom: 8px;
}

.simulated.confirmToast {
    margin: 8px 0;
}

.confirmToast p {
    margin: 0;
    padding: 3px 0;
    border: 1px solid #008000;
    background: #affaaf;
    color: #008000;
    text-align: center;
}

.warningToast p {
    border-color: #D02C50 !important;
    background: #FEF1EC !important;
    color: #d02c50 !important;
}


.toast.errorToast p {
    border-color: #D02C50;
    background: #FEF1EC;
    color: #d02c50;
}

.disabled {
    opacity: .50;
    filter: alpha(opacity=50);
    cursor: default !important;
    text-shadow: none !important;
}

#modal .button.disabled {
    opacity: .50;
    filter: alpha(opacity=50);
    cursor: default !important;
    text-shadow: none !important;
}

.disabled textarea, textarea.disabled {
    resize: none;
}

#Description, #CartName, #InternalPurchaseOrderId, #FrequencySimple, #RuleNextRunDate, #RuleStartDate, #InternalPurcaseOrderID, #PlanName, #NextRunDate, #PODisplayName, #CartDisplayName {
    width: 350px;
    font-size: 1em;
}

#FrequencySimple, #BillingID, #BillingAccountCountry, #AdvantageAccountSelect {
    width: 356px;
}

#StartDate, #Inputs_StartDate, #EndDate,
#Inputs_EndDate, #Inputs_CheckOutMin,
#Inputs_CheckOutMax, #LibraryCardNumber,
#Title, #CheckoutID, #SpecialInstructions,
 #Name, #Email,
#CustomerId, #InvoicingNumbers {
    width: 444px;
}

#Name.save-search-name-field {
    width: 96%;
}

#Email.userEditField.email {
    width: 350px;
}

#CreditAmount_Currency, #creditCurrency {
    width: 280px;
}

#CreditAmount_Amount {
    width: 160px;
}

#OrderType {
    width: 220px;
}

#ResultsPerPage, #SortField {
    margin: -3px 0 0 5px;
}

.current-waiting-list__holds-by-branch--table {
    margin-bottom: 10px;
}
/********************************* END STANDARD FORMS (AUTOMATED CARTS, ETC.) */


/********************************* BEGIN REPORTS */
.filters .label {
    width: 100px;
    font-weight: 500;
}

.table .label {
    font-weight: 500;
}

.reportsOutput p.smallGrayTxt.hrSub {
    margin: -10px 0 10px 0;
}

.reportsOutput table, .table {
    border: 1px solid #999999;
    border-spacing: 0;
    border-collapse: collapse;
}

.reportsOutput th {
    padding: 2px 4px;
    background: #666666;
    color: #ffffff;
    text-align: left;
}

.reportsOutput tr.total {
    font-weight: 500;
}

.fg-toolbar.ui-toolbar.ui-widget-header.ui-corner-tl.ui-corner-tr.ui-helper-clearfix {
    background: white;
}

.head {
    margin-bottom: -30px;
}

.head h2 {
    padding-bottom: 10px;
}

.dgControl.dgFixed, .dockedHeader {
    margin: 0 !important;
    padding: 12px 0 0 0;
    border-width: 0px !important;
    border-bottom-style: none !important;
    box-shadow: none !important;
}

.text-right.ui-widget-header {
    padding-right: 3px;
}

.dtControlSet.dockedElement, .floatingControls.dockedElement {
    width: 100% !important;
}

.od-logo-h1 {
    margin-right: 4px;
    margin-top: 3px;
}

/********************************* END REPORTS */

.manageBtns {
    float: right;
    clear: right;
    margin: 10px 0;
}

    .manageBtns .button {
        min-width: 0 !important;
    }

    .manageBtns input {
        display: block;
        float: left;
        margin: 0;
        padding: 0 27px 0 25px;
        border: none;
        cursor: pointer;
    }

        .manageBtns input:hover {
            color: #fff;
        }

    .manageBtns .button:hover input:hover {
        color: #fff;
    }

.manageBtnsBottom {
    margin: 20px 0;
}

.manageBtns li {
    float: left;
}

/********************************* BEGIN CART DETAILS LEGEND */

.buttonLineUp {
    float: right;
    margin: 5px 0;
}

.dtControlCaddy .buttonLineUp {
    margin: 5px 0 2px -1px;
}

/********************************* END CART DETAILS LEGEND */

/********************************* BEGIN WEED COLLECTION */
.modal__weed-collection {
    width: 500px;
}

.report__weedcollection .grid__column span {
    min-height: 16px;
}

.weedCollectionReport .dynamic-container {
    display: flex;
    width: 100%;
}

.weedCollectionReport .dynamic-container__left {
    width: 77%;
    float: left;
    display:inline-flex;
}

.weedCollectionReport .dynamic-container__right {
    width: 22%;
    margin-left: 1%;
    display: inline-flex;
    flex-direction: row-reverse
}

.weedCollectionReport .dynamic-container__right .dynamic-container__right--flex {
    display: flex;
    flex-direction: column-reverse;
    float: right;
    height: 100%;
}

/* this ensures in firefox that the grid rows are lined up across the fixed columns and the side-scrollable columns*/
.weedCollectionReport .x-grid-cell {
    height: 24px;
}

/* this ensures in IE that the grid rows are lined up across the fixed columns and the side-scrollable columns*/
.weedCollectionReport .x-grid-with-row-lines .x-grid-item {
    border-top-width: 0.5px;
}

/********************************* BEGIN CIRCULATION ACTIVITY */

.circulationActivityReport .reportFilter select.detailReportFilterField {
    display: inline-block;
    margin: 0 5px !important;
    height: 28px;
    background: #fefefe;
    box-shadow: 0px 6px 6px -6px #cccccc inset;
    border: 1px solid #999999;
}

.circulationActivityReport .reportFilter input.detailReportFilterValue {
    height: 24px;
    margin: 0 5px !important;
}

.circulationActivityReport .reportFilter a {
    padding-top: 5px;
}

.circulationActivityReport .reportFilter span {
    display: inline-block;
    line-height: 28px;
}

/********************************* BEGIN CURATE */

.curate-display-options label {
    padding-bottom: 10px;
    display: block;
}

.cur-dsply-optn {
    margin-bottom: 10px;
    float: left;
    clear: both;
}

.curate-display-options .curate-display-rank label {
    margin: 12px -14px 0 26px;
    float: left;
}

.field .curate-display-rank select {
    margin: 6px 6px 0 0;
}

.curate-bangskin-h3 {
    float: left;
}

.curate-bangskin-help {
    float: right;
    margin-top: 8px;
}

.btn-group-mainCart.curate-btn-group .dropdown-menu {
    width: 177px;
    margin-right: 1px;
}

.btn-group-mainCart.curate-btn-group .pinnedCartName,
.btn-group-mainCart.curate-btn-group .pinnedCartPrice {
    max-width: 140px;
}

.btn-group > .btn.curate-mainCart:first-child,
.btn-group-mainCart.curate-btn-group a.btn.btn-mainCart {
    width: 140px;
}

.btn-group-mainCart.curate-btn-group .dropdown-menu .manageCartsLink a {
    font-size: 1em;
    padding-left: 15px !important;
}

.curate__landing-page--container .lucky-day__plan--link {
    vertical-align: middle;
    font-size: 14px;
    text-decoration: none;
}

#fancybox-content > div {
    overflow-x: hidden !important;
}

/********************************* END CURATE */

/********************************* BEGIN USER SETTINGS MODAL */
.settingsModalSubmitted {
    margin: 30% auto;
    text-align:center;
}

/********************************* END USER SETTINGS MODAL */

/********************************* BEGIN TITLE OWN MODALS */

.title-own__title-assignments--modal {
    margin-bottom: 15px;
    margin-left: 35px;
}

.title-own__title-assignments--modal ul li {
    list-style-type: disc;
}

/********************************* BEGIN MERGE CARDS */
.merge-barcode-form {
    min-width: 1000px;
}

.merge-barcode-results {
    margin-top: 20px;
    float: left;
    min-width: 1000px;
}

.merge-original-bc {
    width:480px;
}

.merge-new-bc {
    width:480px;
}

.merge-barcode-form input.bcSearchBox {
    margin: 6px 0 0 20px;
}

.merge-barcode-form.detailForm .inputField .inputLabel {
    width: 220px;
}

.merge-bc-buttons {
    margin-top: 20px;
}

    .originalBarcodes {
          width: 480px;
          position: relative;
          float: left;
          clear: left;
    }

    .newBarcodes {
        position: relative;
        width: 480px;
        float: left;
    }

    .originalBarcodes p,
    .newBarcodes p {
        margin: 0;
    }

    .original-barcode__result--not-found {
        width: 95%;
    }

/********************************* END MERGE CARDS */

/********************************* BEGIN SEARCH CRITERIA */
.merchandisingFlags {
    color: #FFF;
    padding-top: 6px;
    padding-bottom: 16px;
    padding-left: 42px;
    min-width: 1090px;
}

input.merchFlag {
    display: inline-block;
    vertical-align: middle;
    margin-top: -3px;
}

.btn-group.btn-group-search .dropdown-toggle {
    float: right;
    margin-right: -77px;
    height: 38px !important;
}

/********************************* END SEARCH CRITERIA */

/***** Search Autocomplete *****/

.ui-autocomplete-category {
    float: left;
    padding: 5px 0 0 0;
    margin: 5px;
    font-weight: bold;
    font-size: 1.2em;
}

.ui-autocomplete-category .smallGrayTxt {
    font-size: .80em;
}

.ui-autocomplete .ui-menu-item .smallGrayTxt {
    font-size: 1em;
}

fieldset {
    margin-bottom: 20px;
    border: 1px solid #000;
}

    fieldset legend {
        font-weight: 500;
    }

.alertIcon {
    float: left;
    padding: 7px;
}


/********************************* BEGIN ERROR PAGE INSTRUCTIONS */
.errorInstructions li {
    margin-left: 16px;
    list-style: disc;
}

.errorInstruction {
    margin-top: 10px;
}

    .errorInstruction img {
        padding-top: 4px;
    }

#stackTrace {
    margin-top: 10px;
}
/********************************* END ERROR PAGE INSTRUCTIONS */


/* ---- BEGIN CART TOAST CSS ---- */
#cartToastList, #LCAutoSave {
    position: fixed;
    top: 0px;
    right: 0;
    z-index: 90000;
    width: 400px;
}

    #cartToastList li, #LCAutoSave li {
        display: none;
        float: left;
        clear: both;
        margin: 2px 0;
        padding: 8px 8px 8px 8px;
        width: 361px;
        border: 1px solid #f6f6f6;
        border-radius: 3px;
        background: #ffffff;
        background: linear-gradient(to bottom, #ffffff 0%,#f6f6f6 47%,#ededed 100%);
        background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2ZmZmZmZiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjQ3JSIgc3RvcC1jb2xvcj0iI2Y2ZjZmNiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNlZGVkZWQiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
        box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, .3);
        color: #333;
        opacity: .98;
    }

    #cartToastList p, #LCAutoSave p {
        margin: 0;
        padding: 0;
    }

    #cartToastList .toastContent {
        float: left;
        margin-top: 5px;
        width: 300px;
        font-size: .97em;
    }

    #cartToastList .toastTitle {
        font-weight: 500;
        font-size: 1em;
    }

    #cartToastList .cartName {
        clear: both;
        margin-bottom: 5px;
        color: #666;
        font-size: .9em;
    }

    #cartToastList .toastError {
        float: left;
        margin-right: 10px;
        width: 39px;
        height: 33px;
        background: url('/Content/images/alertIcon.png') no-repeat;
    }

    #cartToastList .toastPrice {
        color: #D02C50;
        font-weight: 500;
    }

    #cartToastList a.close {
        float: right;
        margin-top: -10px;
        color: #000000;
        font-weight: 500;
        font-size: 24px;
        opacity: .3;
    }

        #cartToastList a.close:hover {
            color: #006693;
            opacity: 1;
        }
/* ---- END CART TOAST CSS ---- */


.addToCart, .addToCollection, .addToNewCollection {
    height: 20px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

.btn-group .disabled {
    height: 20px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

.addToCartSpinner img {
    float: none;
    width: 16px;
    height: 16px;
    border: 0;
}

.add-to-cart-spinner__button {
    max-height: 16px;
    min-width: 0;
}

.cartSpinnerSpacing {
    margin: 10px 18px 0 0;
}

/* ---- BEGIN Lock States ---- */

.lockStatus a {
    margin: 0;
}

.cartTile .lockStatus {
    bottom: 0px;
    left: 4px;
}
.cartID {
    float: right;
    margin-top: 23px;
    width: 70px;
    color: #aaaaaa;
    text-align: right;
    font-size: 8pt;
}

.lockStatus a:hover {
    color: #aaaaaa;
    text-decoration: none;
}

.lockStatus a:active {
    color: #aaaaaa;
    cursor: pointer;
}

.lockStatus a.Locked:active {
    background-color: transparent;
    cursor: pointer;
}

.lockStatusIcon {
    float: left;
    font-size: 1.4em;
    line-height: 1em;
    width: 1em;
    margin: 3px;
}

#pinnedCarts li.cartTile:first-child p.lockStatusTxt, #pinnedCarts li.cartTile:first-child .lockStatusIcon {
    color: #FFF !important;
}

li.cartTile.ODcreated .lockStatusIcon,
li.cartTile.ODcreated p.lockStatusTxt,
li.cartTile.preorderCart .lockStatusIcon,
li.cartTile.preorderCart p.lockStatusTxt {
    color: #FFF !important;
}

p.lockStatusTxt {
    margin: 0 !important;
    padding: 9px 4px 0 0 !important;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.dynamicCartContent span.lockStatusTxt {
    display: inline-block;
    margin: 6px 0 0 0;
    max-width: 77.5%;
    font-size: .9em;
}

.lockStatus .Locked {
    cursor: default;
}

#pinnedCarts li.cartTile:first-child .lockStatus .Unlocked:hover,
#pinnedCarts li.cartTile:first-child .lockStatus .Private:hover {
    cursor: pointer;
}

#pinnedCarts li.cartTile:first-child .lockStatus .Unlocked:active,
#pinnedCarts li.cartTile:first-child .lockStatus .Private:active {
    cursor: pointer;
}

.lockStatus .Unlocked:hover, .lockStatus .Private:hover {
    cursor: pointer;
}

.lockStatus .Unlocked:active, .lockStatus .Private:active {
    cursor: pointer;
}

.cartDragDropList .lockStatusIcon, .floatingControls .lockStatusIcon {
    margin-left: 0;
}

.Private {
    margin-right: 4px;
}

.pageHeading .lockStatus.left {
    clear: none;
    width: auto;
    margin: 13px;
    bottom: 0;
}
.pageHeading .lockStatus.left p.lockStatusTxt { display: inline-block; }

.lockStatus.cart-details-grid-footer {
    clear: left;
}

.lockStatus.cart-details-grid-footer ,
.lockStatus.cart-details-lock  {
    white-space: nowrap;
}

.lockStatus.cart-details-grid-footer sup ,
.lockStatus.cart-details-lock sup  {
    vertical-align: text-top;
}

.lockStatus.cart-details-grid-footer a,
.lockStatus.cart-details-lock a {
    color: #666666;
    margin: 6px 6px 0 0;
}

.lockStatus.cart-details-grid-footer a:hover,
.lockStatus.cart-details-lock a:hover {
    color: #AAAAAA;
}

.lockStatus.cart-details-grid-footer span.CartDisclaimer,
.lockStatus.cart-details-lock span.CartDisclaimer {
    display: block;
    margin-top: 4px;
}

.pageHeading .lockStatus.cart-details-lock p.lockStatusTxt {
    display: block;
}


/* ---- END Lock States ---- */

.redText { color: #F07C60 !important; }
#ebibliofileDescription {
    float: left;
    margin-right: 20px;
    width: 35%;
}
#ebiblioForm {
    float: left;
    padding-left: 20px;
    border-left: 1px solid #CCCCCC;
}
    #ebiblioForm .editor-field {
        clear: both;
        position:relative;
        margin-bottom: 10px;
    }
        #ebiblioForm .editor-field label {
            float: left;
            width: 100px;
        }
        #ebiblioForm .editor-field span { font-weight: 500; }
        #ebiblioForm input[type="text"] { width: 260px; }
    #ebiblioForm .field-validation-error span { font-weight: normal; }
    #ebiblioForm .requiredAsterisk {
        position: absolute;
        top: 0px;
        left: -7px;
        color: var(--asterisk-red);
    }
ul.rtlRecap { margin: 8px 0; }
    ul.rtlRecap li {
        margin-left: 50px;
        list-style-image: url('/Content/images/smallGreenCheck.png');
        line-height: 30px;
    }
ul.rtlRecap li ul.rtl-sub li {
    list-style-image: none;
    list-style-type: disc;
}
/* -- Search Facet Control CSS -- */

.accordion { background: #ffffff; }
.lcFormat, .lcTitle { white-space: nowrap; border: none !important; }
.lcFormat:hover, .lcTitle:hover { border: none !important; }
.localContentActions .right a { display: inline-block; }
.collapsed {
    overflow: hidden;
    height: 0px;
}
.meteredItemizedList { width: 590px !important; }
    .meteredItemizedList tr:last-child { border-bottom:none; }
.meteredItemizedList #modal .modalContent { padding:0 !important; }

/* -- End Search Facet Control CSS -- */


/********************************* BEGIN LOADING SPINNER */

.loadingIcon {
    background-color: #EBEBEB;
    opacity: 0.3;
    filter: Alpha(opacity=30); /* IE8 and earlier */
    padding: 8px;
    width: 100%;
    max-width: 175px;
}

/********************************* END LOADING SPINNER */

/********************************* BEGIN EDGE-CASE FIXES */
@-moz-document url-prefix() {
    /* Firefox exceptions */
    .dgTable { width: auto !important; }
    .odDataTable th:first-child, th.holdingsKey:first-child, .jqSortableTable th:first-child {
        box-shadow: 0px 2px 2px -1px rgba(0, 0, 0, 0.35);
    }
    .btn-search .caret.right { margin-top: -10px; }
    a.button.normalBtn, input.button .normalBtn[type="submit"],
    input.button.normalBtn[type="button"], button.button.normalBtn,
    a.BtnCancel, input.BtnCancel[type="submit"], input.BtnCancel[type="button"],
    button.BtnCancel, a.BtnAction, input.BtnAction[type="submit"],
    input.BtnAction[type="button"], button.BtnAction { padding: 6px 10px; }
    .deleteBtn, .deleteBtn:hover, .deleteBtn.disabled:hover { padding: 6px 10px !important; }
    .cartPageSize.right { margin-top: 5px; }
    .setAll input.normalBtn.button { padding: 3px 10px; }
    select { margin: -2px 1px 0 0; }
    .arrowBtn { padding: 0 !important; }
    .pager { margin: 15px -1px 15px 0; }
    .cartTile p.description.simUse { height: 32px !important; }
}

.waiver {
    margin-top: 15px;
    text-align: center;
}
.waiver a.button {
    width: 100px;
    display: inline-block;
}

/********************************* END EDGE-CASE FIXES */


/********************************* CSS for grid on featured page */
.middleContentRow {
    width:100%;
    display: flex;
    justify-content: center;
    gap: 24px;
    height:auto;
    margin-top: 48px;
}

.middleContent.featured {
    min-width:934px;
    max-width:1300px;
    margin-top:20px;
}

/*********************** Local content admin page */
.localContentHr {
    margin: 0 0 20px 0;
    display: inline-block;
}

/* BEGIN common dashboard styles */

.overdrive__charts div.dashboard-chart-container {
    display: block;
    float: left;
    border-style: solid;
    border-width: 1px;
    border-color: #d8d8d8;
    border-radius: 3px;
    padding: 1.25rem;
    margin-right: 20px;
    margin-bottom: 20px;
}

.overdrive__charts div.trends-chart-container {
    display: block;
    float: left;
    border: 1px solid #ddd;
    border-radius: 3px;
    margin-right: 20px;
    margin-bottom: 36px;
}

.overdrive__charts .dashboard-chart-container h3 {
    font-size: 16pt;
    margin-bottom: 5px;
    font-weight: 600;
}

.overdrive__charts div.charts {
    width: 1210px;
}

.overdrive__charts div.loading-error {
    text-align: center;
    font-size: 16pt;
}

.overdrive__charts .chart-loader {
    margin: 0 auto;
    display: block;
    width: 100px;
    margin-bottom: -169px;
    padding-top: 160px;
}

.overdrive__charts .custom-tooltip {
    border: 1px solid #000;
    display: inline-block;
    padding: 7px;
    background: #fff;
    display: none;
    position: absolute;
}

/* BEGIN Sora Activity */

.sora-activity__dashboard-grid-summary {
    min-height: 145px;
    margin-bottom: 20px;
}

.sora-activity__dashboard div.charts {
    width: 100%;
}

.sora-activity__dashboard .charts .notes {
    margin-top: -23px;
}

.sora-activity__dashboard .charts .loading-error {
    margin-top: 150px;
    font-weight: 400;
}

.sora-activity__graph-summary {
    color: #666;
    font-size: 1.125em;
    margin-bottom: 10px;
    margin-top: -7px;
}

.sora-activity__graph-summary-link {
    color: #006693;
}

.sora-activity__dashboard h3.sora-activity__graph-summary-value {
    font-weight: 600;
    color: #0A2240;
    font-size: 1.25em;
    display: inline;
}

/* In place of a margin-left on this class (above) use content with &nbsp; */
/* This way, if there is a link, there is no break in the <a> unerline for summary-link & summary-value classes */
.sora-activity__graph-summary-value:before {
    content: "\00a0\00a0";
}

.sora-activity__dashboard .dashboard-chart-container {
    width: 500px;
    height: 438px;
}

.dashboard-carousel__container {
    border: 1px solid #d8d8d8;
    border-radius: 4px;
    display: inline-block;
    padding-left: 20px;
}

.dashboard-carousel__header {
    display: inline-block;
    width: 100%;
    margin-top: 17px;
    margin-bottom: 8px;
}

.dashboard-carousel__header [class^="icon-"] {
    font-size: 24px;
}

.sora-carousel-card {
    display: inline-block;
}

.dashboard-carousel__container {
    border: 1px solid #d8d8d8;
    border-radius: 4px;
    display: inline-block;
    padding-left: 20px;
}

.dashboard-carousel__header {
    display: inline-block;
    width: 100%;
    margin-top: 21px;
    margin-bottom: 6px;
}

.dashboard-carousel__header [class^="icon-"] {
    font-size: 24px;
}

.sora-carousel__move-option:not(.disabled) {
    cursor: pointer;
}

.sora-carousel-card {
    display: inline-block;
    height: 270px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15)
}

.sora-carousel-card__title-link,
.sora-carousel-card__image {
    min-height: 237px;
    max-height: 237px;
    min-width: 178px;
    max-width: 178px;
}

.sora-carousel-card__title-link {
    margin-bottom: 4px;
}

.sora-carousel-card__details {
    padding: 5px 10px;
    color: #0A2240;
    font-weight: 600;
}

.sora-carousel-card__details-type {
    color: #666;
    font-size: .80em;
    margin-right: 4px;
    font-weight: 400;
}


/***** Dashboard charts page */
@media screen and ( max-width: 1250px ) {
    div.overdrive__charts div.charts {
        width: auto;
    }

    .overdrive__charts div.dashboard-chart-container, div.dashboard-chart-container.float-right {
        display: inline-block;
    }

    .overdrive__charts div.dashboard-chart-container:not(.holds-by-format), div.dashboard-chart-container.float-right {
        float: none !important;
    }

    .overdrive__charts div.dashboard-chart-container.top {
        width: 382px;
    }

    .overdrive__charts div.dashboard-chart-container.top canvas {
        margin-left: 43px;
        height: 380px;
    }

    .overdrive__charts div.trends div.dashboard-chart-container {
        width: 533px;
    }

    .overdrive__charts div.currentActivity div.dashboard-chart-container.not-loaded:nth-of-type(1), div.currentActivity div.dashboard-chart-container.not-loaded:nth-of-type(2), div.currentActivity div.dashboard-chart-container.not-loaded:nth-of-type(3) {
        width: 380.5px !important;
    }

    .overdrive__charts div.trends div.dashboard-chart-container.not-loaded:nth-of-type(1), div.trends div.dashboard-chart-container.not-loaded:nth-of-type(3), div.trends div.dashboard-chart-container.not-loaded:nth-of-type(2), div.trends div.dashboard-chart-container.not-loaded:nth-of-type(4) {
        width: 532px !important;
    }
}

/*** BEGIN CLASS SETS, TITLE ASSIGNMENTS ***/

.cs-ta__field--user-ids .inputControl {
    max-width: 700px;
}

.inputField.cs-ta__field--user-ids {
    position: relative;
    padding: 6px 12px;
    min-height: 320px;
}

.cs-ta__form .button--toggle-state:first-child {
    margin-left: 0;
}

.detailForm .cs-ta__form .inputField {
    margin-bottom: 15px;
}

.detailForm .cs-ta__field--auth-name .inputControl,
.detailForm .cs-ta__field--emails .inputControl {
    margin-bottom: 0;
}

.inputField.cs-ta__field--title .inputControl {
    max-width: 480px;
}

.cs-ta__user-id-icon {
    vertical-align: text-top;
    margin-top: -1px;
    display: inline-block;
    margin-right: 5px;
    margin-left: 0;
}

.userIdCopy .cs-ta__user-id-icon {
    margin-right: 0;
}

.cs-ta_button--add-user-id {
    display: inline-block;
    margin-bottom: 10px;
}

.cs-ta__user-id-entries-contain {
    border: 1px solid #CCC;
    padding: 10px 12px 10px 10px;
    background-color: #FFF;
    max-height: 400px;
    overflow-y: scroll;
}

.cs-ta__user-id-entries-contain li {
    padding-bottom: 5px;
}

.cs-ta__user-id-entries-contain li:last-child {
    padding-bottom: 0;
}

.cs-ta__field--emails .mb-input-list .mb-token {
    margin-right: 3px;
    background-color: #006693;
}

.inputField.cs-ta__field--emails .inputLabel {
    margin-top: 10px;
}

.inputField.cs-ta__field--emails .inputLabel .requiredAsterisk {
    top: 12px;
}

.inputField.cs-ta__field--user-ids .inputLabel {
    width: auto;
}

.inputField.cs-ta__field--user-ids .inputLabel .requiredAsterisk {
    top: 8px;
    left: 6px;
}

.inputLabel.labelText.payment__checkbox-label {
    width: 420px;
}

.payment__error {
    color: #AE4646;
    font-weight: bold;
}

.cs-ta__confirm-cs-purchase {
    padding: 10px;
}

.cs-ta__confirm-cs-purchase p {
    max-width: 600px;
}

.cs-ta__modal-price {
    color: #0A2240;
    font-weight: 700;
    font-size: 1.8em;
    margin: 0;
}

.cs-ta__purchase-confirmation .purchaseConditions {
    margin-left: 0;
}

.cs-ta__confirmation-page-total {
    color: #0A2240;
    font-weight: 700;
    font-size: 1.8em;
    margin: 10px 0;
}

.cs-ta__form-contain--right {
    width: auto;
    height: 100%;
    background: #f6f6f6;
    overflow: hidden;
    border: 1px solid #ccc;
    border-radius: 3px;
}
.cs-ta__form-contain--left {
    height:100%;
    width:640px;
    float:left;
    margin-right: 10px;
}

.detailForm .cs-ta__form-contain--left .inputField {
    margin-bottom: 14px;
}

.detailForm .cs-ta__form-contain--left .inputField .inputControl {
    max-width: 440px;
}

.cs-ta__form .inputControl .lending-model-labels {
    float: left;
}

.cs-ta__form .lendingExpire.special-message {
    padding-left: 0;
}

.actionButtons {
    height: 32px;
    width: 120px;
}
.cs-ta__example-user-id {
    font-weight: bold;
    font-size: 14px;
}

.cs-ta__example-user-id--modal--container {
    min-width: 300px;
}

.cs-ta__example-user-id--modal {
    display: inline-block;
}

#modal .cs-ta__small-gray-text--modal {
    height: auto;
}

#modal textarea.cs-ta__user-id-entry-textarea {
    height: 120px;
    width: 100%;
}

.cs-ta__button--primary {
    margin-left: 0;
}

.cs-ta__success-details {
    color: #0A2240;
    font-weight: bold;
    font-size: 14px;
}

.cs-ta__title-assignment-search-modal .inputControl {
    position: relative;
    margin: 10px 0 0 10px;
    width: 400px;
}

#modal input[type="text"].cs-ta__ta-search-field {
    width: 100%;
}

.cs-ta__title-assignment-search-modal .inputControl .cs-ta__ta-search-field {
    width: 100%;
    margin: 0 auto;
    padding-left: 10px;
    border-radius: 5px;
    height: 30px;
    box-shadow: none;
}

.cs-ta__title-assignment-search-modal .inputControl span.icon-search {
    position: absolute;
    right: -16px;
    top: 6px;
    font-size: 18px;
    padding-right: 7px;
}

.cs-ta__title-assignment-search-modal .inputField {
    padding-bottom: 12px;
    border-bottom: 1px solid #999;
}

.cs-ta__title-link {
    display: inline-block;
    margin-top: 8px;
}

.cs-ta__learnmore--details {
    display: none;
    margin-left: 20px;
}

.cs-ta__learn-more--plus-icon {
    display: inline-block;
    font-size: 12px;
    font-weight: bold;
    margin-left: 5px;
    margin-right: 0;
    transition: transform 0.3s ease-in-out;
}

.ta__title-assignment-grid .x-grid-cell-inner {
    line-height: 26px;
}

/*** One roster, class roster begin ***/

#modal .cs-ta__class-roster-contain {
    position: relative;
    min-width: 300px;
}

#modal .cs-ta__class-roster-contain div {
    margin-bottom: 10px;
}

#modal .cs-ta__class-roster-contain select {
    display: block;
    width: 100%;
}

#modal .cs-ta__class-roster-contain img {
    vertical-align: text-top;
}

#modal .cs-ta__class-roster-contain div:last-child {
    margin-bottom: 0;
}

#modal .cs-ta__class-roster--label {
    display: inline-block;
    line-height: 1.5rem;
}

/*** One roster, class roster end ***/

/*** END CLASS SETS, TITLE ASSIGMNETS ***/

/********************************* Begin title assignments calendar */

#calendar__container {
    width: 100%;
    height: auto;
}

.calendar__buttons--top {
    height: 30px;
    width: 100%;
    margin-bottom: 15px;
    white-space: nowrap;
}

.calendar__buttons--top span {
    white-space: nowrap;
}

.calendar__buttons--top span.viewSelection {
    cursor: pointer;
    color: #006693;
}

.calendar__buttons--top span.viewSelection.selected {
    color: #333;
    cursor: inherit;
}

#calendar__container .calendar__buttons--top div.left  {
    margin-top: 8px;
    min-width: 300px;
}

#calendar__sidebar {
    height:100%;
    width: 20%;
    float:left;
    display: inline-block;
    float: left;
    min-width: 300px;
}

#calendar {
    width:auto;
    height:100%;
    overflow:hidden;
}

#calendar .fc-toolbar, #calendar__sidebar .ta-sidebar__search {
    margin-bottom: 0;
    height: 32px;
}

.ta-sidebar__search {
    border-bottom: 1px solid;
}

.ta-sidebar__search .ta-sidebar__search--inset {
    position: relative;
    margin: 0 10px 0 16px;
    padding-top: 4px;
}

#calendar__container .icon-search,
#calendar__container .icon-close,
#calendar__container .icon-edit {
    cursor: pointer;
}

#calendar__container .ta-sidebar__assignment-container .icon-close,
#calendar__container .ta-sidebar__assignment-container .icon-edit {
    font-size: 1.1em;
}

#calendar__container .ta-sidebar__assignment-container .icon-close {
    margin-right: 8px;
}

#calendar__container .icon-edit {
    margin-right: 3px;
}

.ta-sidebar__search .icon-search {
    font-size: 13px;
    position: absolute;
    margin: 6px 0 0 8px;
    right: 0;
}

.ta-sidebar__search .icon-close {
    position: relative;
    float: left;
    margin: -17px 0 0 -12px;
    font-size: 12px;
    display: none;
    color: #AE4646;
}

.ta-sidebar__search .icon-close:hover {
    color: #7B1313;
}

.ta-sidebar__search input {
    border: none;
    width: 100%;
}

.ta-sidebar__internal-note {
    background: rgba(255, 255, 255, .5) 0% 0% no-repeat padding-box;
    border-radius: 15px;
    display: inline-block;
    max-width: 70%;
    margin-top: 2px;
}

.ta-sidebar__internal-note p {
    color: #303B40;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
    padding: 0 4px 0 4px;
}

#calendar .fc-day-header {
    height: 34px;
}

#calendar_border--wrap {
    border: 1px solid;
    border-radius: 5px;
}

#calendar__container {
    margin: 0 auto;
    position: relative;
}

#calendar__details {
    display: inline-block;
    float: left;
    max-width: 70%;
}

#calendar__sidebar .filterInput {
    outline-width: 0;
}

#calendar .fc-content .ta__internal-note {
    background: rgba(255, 255, 255, .5) 0% 0% no-repeat padding-box;
    border-radius: 15px;
    display: inline-block;
    margin-left: 5px;
    padding: 0 6px 0 4px;
}

#calendar .ta__internal-note span {
    color: #303B40;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
    font-weight: 400;
    font-size: 13px;
}

.cs-ta__assignment-popover .assignment--active,
#calendar__container .assignment--active,
#calendar__container .assignment--active .titleContainer,
#calendar__container .assignment--active a.icon-edit,
#calendar__container .assignment--active a.icon-close {
    background-color: #BDD8E3;
    border-color: #006693;
    color: #303B40;
}

.cs-ta__assignment-popover .assignment--saved,
#calendar__container .assignment--saved,
#calendar__container .assignment--saved .titleContainer,
#calendar__container .assignment--saved a.icon-edit,
#calendar__container .assignment--saved a.icon-close {
    background-color: #E1EfD0;
    border-color: #8EC549;
    color: #234000;
}

.cs-ta__assignment-popover .assignment--needsattention,
#calendar__container .assignment--needsattention,
#calendar__container .assignment--needsattention .titleContainer,
#calendar__container .assignment--needsattention a.icon-edit,
#calendar__container .assignment--needsattention a.icon-close {
    background-color: #FFEBBF;
    border-color: #FFAE00;
    color: #402C00;
}

.cs-ta__assignment-popover .assignment--complete,
#calendar__container .assignment--complete,
#calendar__container .assignment--complete .titleContainer,
#calendar__container .assignment--complete a.icon-edit,
#calendar__container .assignment--complete a.icon-close {
    background-color: #E2E2E2;
    border-color: #AAAAAA;
    color: #4E4E4E;
}

.cs-ta__assignment-popover .assignment--error,
#calendar__container .assignment--error,
#calendar__container .assignment--error .titleContainer,
#calendar__container .assignment--error a.icon-edit,
#calendar__container .assignment--error a.icon-close {
    background-color: #E3BDBD;
    border-color: #930000;
    color: #4E4E4E;
}

#calendar__container .titleContainer,
#calendar__container .ta-cal__assignment,
.cs-ta__assignment-popover .ta-cal__assignment {
    border-radius: 3px 0 0 0;
    border-style: none;
    border-left-width: 4px;
    border-left-style: solid;
}

.key__assignment-text {
    margin-left: 10px;
    border: 1px solid;
    border-radius: 3px;
    padding: 3px 7px;
    cursor: pointer;
    display: inline-flex;
}

.key__assignment-text .status-filter__count {
    margin-left: 4px;
}

.key__assignment-text[data-filterstatus='active'] {
    border-color: #006693;
}

.key__assignment-text[data-filterstatus='saved'] {
    border-color: #8EC549;
}

.key__assignment-text[data-filterstatus='needsattention'] {
    border-color: #FFAE00;
}

.key__assignment-text[data-filterstatus='complete'] {
    border-color: #AAAAAA;
}

.key__assignment-text[data-filterstatus='error'] {
    border-color: #930000;
}

.calendar__buttons--top .icon-close {
    margin-right: 0;
    margin-top: -2px;
}

.calendar__buttons--top .icon-close:before {
    content: "";
}

.calendar__buttons--top .active .icon-close:before {
    content: "\e905";
    margin-left: 3px;
    margin-right: -2px;
    font-size: 11px;
}

.key__assignment-text.inactive {
    border-color: grey;
    color: grey;
}

.fc-event {
    font-size: 1em;
}

#calendar__container .fc-event-container .ta-cal__assignment {
    border-radius: 3px;
    height: 24px;
    margin-bottom: 1px;
    transition: all .3s ease-in-out;
}

.ta-cal__assignment.on {
    height: 20px;
}

.ta-cal__assignment div span.fc-title {
    margin: 4px 0 0 5px;
    display: inline-block;
    font-size: 13px;
}

.ta-sidebar__assignment-container {
    height: 100%;
    margin: 0;
    padding: 0;
    overflow: auto;
}

.ta-sidebar__assignment {
    margin: 6px;
    overflow: hidden;
    height: initial;
    border-radius: 3px;
    opacity: 1;
    transition: all .3s ease-in-out;
}

.ta-sidebar__assignment:first-child {
    margin-top: 6px;
}

.ta-sidebar__assignment .ta-sidebar__summary {
    display: inline-block;
    width: 100%;
}

.calendar__buttons--top a,
.ta-sidebar__assignment .ta-sidebar__summary a:hover {
    text-decoration: none;
}

.ta-sidebar__summary div.titleContainer {
    position: relative;
    float: left;
    width: 75%;
    padding-bottom: 4px;
}

.ta-sidebar__summary div {
    padding: 0 6px 0 4px;
    margin-bottom: -9px;
    text-decoration: none;
}

.ta-sidebar__details {
    margin-top: 4px;
    max-height: 0;
    transition: all .3s ease-in-out;
    overflow: hidden;
}

.ta-sidebar__details ul {
    margin: 1px;
    padding: 0;
    list-style: outside none none;
    background-color: #FFF;
    border-radius: 0 0 3px 3px;
}

.ta-sidebar__details ul li {
    margin: 0;
    padding: 0 0 6px 6px;
}

.ta-sidebar__details ul li:first-child {
    padding-top: 6px;
}

/* allows the view details link to float to the right of this element */
.ta-sidebar__details ul li:nth-last-child(3) {
    display: inline-block;
}

.ta-sidebar__details ul li.viewDetails {
    float: right;
    padding-right: 5px;
    display: inline-block;
}

.ta-sidebar__assignment.on .ta-sidebar__details {
    max-height: 300px;
}

.ta-sidebar__summary .cs-ta__summary-controls {
    min-height: 19px;
    padding-top: 5px;
}

.cs-ta__sidebar-assignment--toggle {
    font-size: 12px;
    position: absolute;
    top: 4px;
    margin-top: 2px;
    cursor: pointer;
}

.cs-ta__sidebar-assignment--toggle.icon-plus {
    opacity: 1;
    transform: rotateZ(0deg);
    transition: all 0.2s ease-in-out;
}

.cs-ta__sidebar-assignment--toggle.icon-minus {
    opacity: 0;
    top: 4px;
    transform: rotateZ(90deg);
    transition: all 0.2s ease-in-out;
}

.on .cs-ta__sidebar-assignment--toggle.icon-plus {
    opacity: 0;
    transform: rotateZ(90deg);
}

.on .cs-ta__sidebar-assignment--toggle.icon-minus {
    opacity: 1;
    transform: rotateZ(180deg);
}

.cs-ta__sidebar-assignment--label {
    margin: 4px 0 1px 21px;
    width: 100%;
    display: inline-block;
    font-size: 13px;
    max-height: 2em;
    line-height: 1em;
}

.cs-ta__sidebar-assignment--text-container {
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
}



#calendar.fc button {
    display: block;
}

#calendar h2 {
    font-family: 'proxima-nova', 'Segoe UI', 'Open Sans', Tahoma, Helvetica, sans-serif;
    font-size: 1.25em;
    margin-top: 5px;
}

.fc-head .fc-row th {
    font-weight: normal;
    vertical-align: middle;
}

#calendar.fc-unthemed .fc-day-number {
    height: 20px;
    padding-left: 2px;
    padding-top: 2px;
    display: block;
    text-align: left;
    margin-bottom: -1px;
}

#calendar.fc-unthemed .fc-today {
    background-color: rgba(0, 0, 0, 0);
}

#calendar.fc-unthemed .fc-today .fc-day-number {
    background-color: #EBEBEB;
    font-weight: bold;
    width: 100%;
    box-sizing: border-box;
}

#calendar.fc-unthemed .fc-other-month {
    color: #AAAAAA;
}

#calendar .fc-toolbar {
    border: hidden;
    border-left: 1px solid;
}

#calendar .fc-toolbar .fc-center h2 {
    color: #333333;
}

.fc-event-container .fc-day-grid-event :hover {
    font-weight: 800;
}

#calendar_border--wrap,
.ta-sidebar__search,
#calendar .fc-toolbar,
#calendar.fc-unthemed th,
#calendar.fc-unthemed td,
#calendar.fc-unthemed thead,
#calendar.fc-unthemed tbody,
#calendar.fc-unthemed .fc-divider,
#calendar.fc-unthemed .fc-row,
#calendar.fc-unthemed .fc-content,
#calendar.fc-unthemed .fc-popover {
    border-color: #DDDDDD;
}

#calendar .fc-head-container.fc-widget-header {
    border-right: none;
}

#calendar .fc-body .fc-widget-content {
    border-right: none;
    border-bottom: none;
}

#calendar .fc-toolbar button {
    background-color:transparent;
    background-image: none;
    box-shadow: none;
    border: none;
    outline: none;
    margin-top: 3px;
}

.fc-popover .fc-event-container .ta-cal__assignment,
.cs-ta__popover-contain .fc-event-container .ta-cal__assignment {
    height: 20px;
    border-radius: 3px;
    margin: 2px auto;
}

.assignmentPopoverContain .fc-content .ta__internal-note {
    display: none;
}

#calendar.fc-unthemed .fc-popover {
    display: none !important;
}

.cs-ta__assignment-popover {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999;
}

.cs-ta__assignment-popover .ta-cal__assignment:hover {
    color: inherit;
}

.cs-ta__popover-contain {
    font-size: 1em;
    box-shadow: 0 2px 6px rgba(0,0,0,.15);
    z-index: 2000;
    width: 220px;
    box-sizing: content-box;
    background-color: #fff;
    border-width: 1px;
    border-style: solid;
    border-color: #cccccc;
}

.cs-ta__popover-contain .fc-header {
    background: #eee;
    padding: 2px 4px;
}

.cs-ta__popover-contain .fc-header .fc-close {
    display: none;
}

.cs-ta__popover-contain .fc-event-container {
    padding: 10px;
}

.cs-ta__popover-contain .fc-event {
    font-size: 12px;
    line-height: 1.1;
}

.cs-ta__popover-contain .fc-event-container .fc-title {
    margin: 3px 0 4px 5px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
}

.cs-ta__assignment-popover--close {
    position: absolute;
    top: 3px;
    font-size: 14px;
    right: 0;
    z-index: 9999;
    cursor: pointer;
}

/********************************* End title assignments calendar */
/********************************* Begin New Curate*/
.navigation__container {
    display: flex;
    float: left;
    color: #006693;
}

.navigation__partial {
    width: 220px;
    margin-top: 0;
}

.viewport__container {
    flex-grow: 1;
    overflow: hidden;
    padding: 0 25px 15px 45px;
}

.navigation__tab--room {
    width: 100%;
    float: right;
}

.navigation__tab--collection {
    float: left;
    width: 100%;
}

.navigation__tab--collection li {
    padding: .5em .5em .5em 1.7em;
    margin: 0 1em;
    font-size: .9em;
    border-bottom: 1px solid #CCCCCC;
}

.navigation__tab--collection li.tab--active {
    color: #0A2240;
    font-weight: bold;
    cursor: auto;
}

.navigation__tab--collection li:last-child {
    border-bottom: none;
}

.navigation__tab--collection li:hover {
    color: #0A2240;
}

.navigation__tab--room, .navigation__tab--collection {
    position: relative;
    background-color: white;
    cursor: pointer;
}

.closed .navigation__tab--room:hover, .closed .navigation__tab--collection:hover {
    color: #0A2240;
}

.open .navigation__tab--room {
    font-weight: bold;
}

.navigation__tab--room-name {
    display: block;
    float: left;
    padding: .5em 0 .5em .7em;
    max-width: 168px;
}

.navigation__tab--room p, .navigation__tab--collection p {
    margin-left: 10px;
    margin-top: 18px;
}

.curate__live-status--icon {
    border-radius: 10px;
    display: inline-block;
    height: 6px;
    min-width: 6px;
    position: absolute;
    left: -2px;
    transition: all 0.2s ease-in-out 0s;
    z-index: 998;

}

.navigation__tab--live {
    top: 9px;
    background-color: #8EC549;
    border: 1px solid #75AC30;
}

.navigation__tab--not-live {
    top: 9px;
    background-color: #FFAE00;
    border: 1px solid #E69500;
}

.curate__p--not-live {
    position: relative;
    padding-left: 8px;
}

.curate__notification-icon--not-live {
    top: 4px;
    background-color: #FFAE00;
    border: 1px solid #E69500;
}

.navigation__room-level-icon {
    font-size: 1em;
    padding: .6em .5em .5em .7em;
    transition: all 0.2s ease-in-out;
}

.navigation__tab--container .navigation__tab--collection {
    display: none;
}

.headerCopy__container {
    margin-bottom: 40px;
}

.headerCopy__container h1 {
    margin-top: 0;
}

.publishedCollections__container .grid__bangWarning,
.publishedCollections__container .report-message,
.draftCollections__container .report-message {
    position: relative;
    top: -45px
}

.curate-actionButtons__container {
    display: inline;
    margin-left: 4px;
    float: right;
}

.menuView__collection {
    width: 100%;
    padding: 0 20px;
    margin-bottom: 10px;
    border: 1px solid #CCCCCC;
    border-radius: 3px;
    background-color: #FFF;
    overflow: auto;
    box-sizing: border-box;
    box-shadow: 0 1px 3px rgba(0,0,0,0.06), 0 1px 2px rgba(0,0,0,0.14);
    transition: box-shadow 0.1s ease-in-out;
}

.menuView__collection:hover {
    box-shadow: 0 4px 12px rgba(0,0,0,0.08), 0 4px 4px rgba(0,0,0,0.13);
}

.menuView__collection .right {
    margin-top: 27px;
}

.menuView__collection .right span {
    margin: 0 10px;
}

.menuView__collection [class^="icon-"], .menuView__collection [class*=" icon-"] {
    cursor: pointer;
    text-decoration: none;
}

.menuView__collection--description {
    max-width: 80%;
}

#dynamicViewPort .menuView__cursor-move {
    cursor: move;
}

.advantageCollections__customize {
    padding: 10px;
    border-radius: 5px;
    background-color: #F4F4F4;
    margin-bottom: 20px;
}

.advantageCollections__customize ul {
    padding-left: 20px;
    list-style: outside disc none;
    margin-bottom: 10px;
}

.advantageCollections__customize ul li {
    margin-left: 10px;
}

.advantageCollections__customize h2 {
    margin-top: 0;
}

.advantageCollections__customize .customize__switch {
    height: 20px;
    width: 100px;
    text-transform: uppercase;
    line-height: 20px;
}

.advantageCollections__customize .customize__switch--option {
    width: 50%;
    height: 100%;
    color: #006693;
    border: 1px solid #aaa;
    background-color: #fff;
    text-align: center;
    cursor: pointer;
}

.advantageCollections__customize .customize__switch--option:hover {
    background-color: #DDD;
}

.advantageCollections__customize .customize__switch--option.active {
    background-color: #006693;
    border: 1px solid #0A2240;
    color: #fff;
    cursor: default;
}

.advantageCollections__customize .customize__switch--option.left {
    border-radius: 5px 0 0 5px;
    border-right: none;
    margin-left: -1px;
}

.advantageCollections__customize .customize__switch--option.right {
    border-radius: 0 5px 5px 0;
    border-left: none;
    margin-right: -1px;
}

.advantageCollections__learn-more {
    color: #006693;
    cursor: pointer;
}

.advantageCollections__learn-more:hover {
    color: #0A2240;
}

.advantageCollections__learn-more--details {
    display: none;
}

.advantageCollections__learn-more--icon {
    font-size: 1em;
    display: inline-block;
    padding: .2em .5em;
    vertical-align: top;
    margin-top: -1px;
    transition: transform ease-in-out 0.2s;
}

#modal h1.advantageCollections__modal--heading {
    padding: 0;
    margin-top: -10px;
    margin-bottom: 10px;
}

.curate__create-collection-form .inputField .inputLabel {
    padding: 0;
}

.curate__create-collection-form .curate__create-collection-form--type-section {
    margin-bottom: 20px;
}

.curate__create-collection-form--type {
    margin-bottom: 6px;
}

#modal .curate__create-collection-form--type input {
    margin-left: 2px;
}


/** curate details/curate **/

.curate-details__left-column {
    position: relative;
    float: left;
    width: 64%;
    margin-right: 2%;
}
.curate-details__right-column {
    position: relative;
    float: right;
    width: 33%;
}

.curate-details__header p.lockStatusTxt {
    padding: 0 0 0 4px !important;
}

.curate-details__header .curate__pin--green {
    text-decoration: none;
    color: #8EC549;
}

.curate-details__header .curate__pin--green:hover {
    color: #75AC30;
}

.pageHeading .curate-details__header .lockStatus {
    margin: 10px;
}

.curate-details__left-column :where(input[type="text"], input[type="password"], input[type="number"], textarea) {
    padding: 1px 2px 1px 4px;
}

.curate-create__available-mapping--textarea textarea {
    width: 100%;
    height: 140px;
}

.curate-create__available-mapping .requiredAsterisk {
    position: absolute;
    top: 42px;
    left: -8px;
}

.curate-details__left-column .detailForm .inputField .inputLabel {
    margin-bottom: 0;
    width: 100%;
}

.curate-details__left-column .detailForm .inputField label,
.curate-details__left-column .detailForm label,
.curate-details__left-column .detailForm .inputField select,
.curate-details__left-column .detailForm .inputField span,
.curate-details__left-column .detailForm .inputField .smallGrayTxt,
.curate-details__left-column .quantityBox {
    clear: both;
    float: left;
}

.curate-details__left-column .detailForm .inputField label {
    margin-bottom: 3px;
}

.curate-details__left-column .detailForm .inputField select {
    margin: 0 !important;
    width: auto;
    min-width: 350px;
}

.curate-details__left-column .quantityBox {
    margin: 0;
    min-width: 50px;
}

/**** curate select2 overrides ****/

.select2-search-choice-close {
    top: 3px;
}


.select2-search-field input[type="text"] {
    height: 15px;
    padding: 0 5px !important;
}

.curate-details__left-column .select2-container {
    width: 100%;
}

.curate-details__left-column .select2-container a {
    float: left;
    height: 22px;
    min-width: 350px;
    padding: 0 0 0 4px;
    border-radius: 0;
    box-shadow: none;
}

.curate-details__left-column .select2-container a.select2-search-choice-close {
    height: 13px;
    min-width: 0;
    top: 5px;
}

.curate-details__left-column .select2-container-multi .select2-choices {
    width: 350px;
    border-radius: 0;
}

.curate-details__left-column .textarea-tall .select2-container-multi .select2-choices {
    min-height: 140px !important
}

.curate-details__right-column .select2-container-multi.select2-container-active .select2-choices,
.curate-details__left-column .select2-container-multi.select2-container-active .select2-choices {
    box-shadow: none;
}

.select2-drop {
    width: auto !important;
    min-width: 356px;
    border-radius: 0 !important;
}

.select2-results .select2-selection-limit {
    background: #FFF !important;
    background-image: none;
}

.curate-details__left-column .select2-dropdown-open .select2-choice {
    background-image: none;
    background: #FFF;
    border-bottom-color: #AAA;
}

.curate-details__left-column .select2-container .select2-choice .select2-arrow {
    background-image: none;
    background: #FFF;
    border-left: none;
}

.curate-details__left-column .select2-container .select2-choice {
    line-height: 24px;
    background-image: none;
}

.curate-details__left-column .select2-container .select2-choice:focus {
    box-shadow: none;
}

.curate-details__left-column .select2-container .select2-choice .select2-arrow b {
    line-height: 24px;
    background: url('/Content/select2/select2-curate.png') no-repeat 0 -1px;
}


.curate-details__right-column .select2-container {
    width: 100%;
}

.curate-details__right-column .select2-container.disabled li:hover {
    background-color: #006693;
    border-color: #006693;
    color: #FFF;
}

/*Curated collections publishing locations - right align*/
.curate-details__right-column .select2-container ul.select2-choices {
    width: 100% !important;
    min-height: 140px !important;
    margin: 0 !important;
    padding: 0 !important;
    border: 1px solid #aaaaaa;
    border-radius: 0;
}



.select2-dropdown-open.select2-drop-above .select2-choices {
    background-color: #FFF !important;
    background-image: none !important;
}
.select2-drop.select2-drop-above {
    border-bottom: 1px solid #AAA !important;
    box-shadow: none !important;
}


/********************************* End New Curate */

/********************************* Begin Advantage Plus */

.adv-plus__info-list {
    list-style: outside disc none;
    padding-left: 20px;
    margin: 10px 0;
}

.adv-plus__warning--icon {
    color: #E69500;
    vertical-align: text-top;
}

.adv-plus__info-contain {
    padding: 10px;
    border-radius: 5px;
    background-color: #F4F4F4;
    margin-bottom: 20px;
}

.adv-plus__info-contain h3 {
    margin-top: 0;
}

.adv-plus__info-contain p {
    display: inline-block;
    margin: 10px 0;
}

.adv-plus__info-contain p.advantageCollections__learn-more {
    margin-left: 10px;
}

.adv-plus__info-contain ul.advantageCollections__learn-more--details {
    list-style: outside disc none;
    padding-left: 20px;
    margin: 0 0 10px 0;
}

.adv-plus__join--checkbox {
    line-height: 1.5rem;
    margin-bottom: 10px;
}

.adv-plus__join--checkbox input {
    float: left;
    margin-top: 5px;
}

.adv-plus__summary-contain {
    display: inline-block;
    padding: 10px 20px 10px 10px;
    border-radius: 5px;
    background-color: #F4F4F4;
    margin-bottom: 10px;
}

.adv-plus__create-contain {
    margin: 0;
}

.adv-plus__create-contain .adv-plus__option-list {
    margin: 5px 0 0 20px;
    float: left;
    clear: both;
}

.adv-plus__create-contain .adv-plus__option-list li {
    margin: 0;
    line-height: 1.2rem;
    clear: both;
}

.adv-plus__create-contain .adv-plus__option-list li p {
    margin: -3px 0 0 18px;
}

.adv-plus__create-contain .adv-plus__option-list li .planValue {
    float: left;
    margin: 3px 6px 0 0;
    width: 12px;
}

.adv-plus__create-contain .detailForm select {
    max-width: 150px;
}

.adv-plus__create-contain .detailForm .labelTextSub {
    width: auto;
}

.adv-plus__create-contain .detailForm .inputField.required .labelText:before {
    content: "* ";
    float: left;
    margin-left: -6px;
    color: #d02c50;
}

.adv-plus__review-titles-contain {
    position: relative;
    margin-bottom: 10px;
}

.adv-plus__return-to-plan--link {
    position: absolute;
    bottom: 0;
    right: 0;
}

.adv-plus__review-titles-grid .x-form-text {
    height: calc(100% - 2px) !important;
}

.adv-plus__review-titles-grid .x-grid-cell-inner {
    line-height: 22px;
}

#modal .adv-plus__move-share-confirm--modal p {
    padding: 0 0 10px 0;
}

#modal .adv-plus__modal--join-proceed-section,
#modal .adv-plus__modal--note {
    margin: 10px 0 0 0;
}

#modal .adv-plus__modal--join-proceed-section p {
    margin-bottom: 10px;
}

#modal .adv-plus__modal--join-proceed-section span.button.button--primary {
    margin-left: 0;
}


/********************************* End Advantage Plus */


/********************************* Begin sale pricing */

.sales-pricing__sale-price {
    color: #AE4646;
    padding-right: 6px;
}

.sales-pricing__sale-price--carousel {
    color: #AE4646;
    font-size: 20px;
    padding-right: 6px;
}

.sales-pricing__original-price {
    position: relative;
}

.sales-pricing__original-price,
.sales-pricing__original-price--carousel {
    position: relative;
    display: inline-block;
}

.sales-pricing__original-price:before,
.sales-pricing__original-price--carousel:before {
    position: absolute;
    content: "";
    left: 0;
    top: 46%;
    right: 0;
    border-top: 2px solid #AE4646;
    transform:rotate(-8deg);
}

.sales-pricing__sale-ends,
.sales-pricing__sale-ends--carousel {
    color: #AE4646;
    font-size: 11px;
    font-weight: 500;
}

/********************************* End sale pricing */


/********************************* Begin Invoicing */

.invoicing__display-inline {
    display: inline-block;
}

.invoicing__preorder-freq,
.invoicing__standard-freq {
    margin-bottom: 20px;
}

.invoicing__invoice-freq-notes-list {
    list-style: outside disc none;
    padding-left: 20px;
    padding-bottom: 10px;
    margin: 0;
}

.invoicing__frequency-choices {
    margin-left: 10px;
    padding: 5px 0;
    line-height: 1.3rem;
}

.invoicing__frequency-choices input {
    margin-right: 5px;
}

.invoicing__frequency-choices span {
    vertical-align: text-bottom;
}

.invoicing__banking-instructions .grid__row:nth-child(even) {
    background-color: #f4f4f4;
}

.invoicing__banking-instructions .grid__column:first-child {
    max-width: 200px;
}

.invoicing__overdrive-details {
    margin-top: 20px;
}

.invoicing__overdrive-details p {
    font-size: .85em;
    padding: 0;
    margin: 0;
}

.invoicing__important-details-contain {
    padding: 10px;
    border-radius: 5px;
    background-color: #F4F4F4;
    margin-bottom: 20px;
}
.invoicing__learn-more--details {
    display: none;
}

.invoicing__important-details-contain h3 {
    margin-top: 0;
}

.invoicing__learn-more--toggle {
    color: #006693;
    cursor: pointer;
}

.invoicing__learn-more--toggle:hover {
    color: #0A2240;
}

.invoicing__learn-more--arrow-icon {
    display: inline-block;
    font-size: 12px;
    font-weight: bold;
    margin-left: 5px;
    margin-right: 0;
    transition: transform 0.3s ease-in-out;
}


/* invoicing details begin */

.invoicing__details-header-info--left {
    margin-bottom: 10px;
}

.invoicing__details-header-info--right {
    text-align: right;
    margin-bottom: 10px;
}

.invoicing__header-image {
    margin-bottom: 10px;
}

.invoicing__remittance-details {
    margin-bottom: 20px;
}

.invoicing__remittance-details--view-invoices-page {
    margin: 10px 0;
}

.invoicing__remittance-details ol li {
    margin: 0;
    line-height: 1.3rem;
}

.invoicing__remittance-details ul li {
    margin-left: 10px;
}

.invoicing__remittance-details ul.withDisc li {
    list-style: outside disc none;
    margin-left: 20px;
}

.invoicing__remittance-details--view-invoices-page ol li {
    margin: 0;
    line-height: 1.3rem;
}

.invoicing__remittance-details--view-invoices-page ul li {
    margin-left: 10px;
}


div.grid__container-div.invoicing__view-invoices-summary {
    max-width: 600px;
    float: left;
}

.invoicing__view-invoices-summary .grid__row {
    background-color: #FFF;
}

.invoicing__view-invoices-summary .grid__column {
    background-color: #f0f0f0;
}

.invoicing__view-invoices-summary .grid__column span {
    display: block;
    padding: 5px;
    border: 1px solid #FFF;
    height: 100%;
}

.invoicing__view-invoices-summary .grid__column:first-child span {
    font-weight: bold;
}

.invoicing__view-invoices-pdfspinner, .invoicing__invoice-details-pdfspinner {
    float: right;
    margin-left: 5px;
}

.invoicing__details-footer .grid__column {
    line-height: 1.8rem;
}

.invoicing__details-footer .grid__column:first-child {
    font-weight: bold;
}

.invoicing__details-footer .grid__row:nth-child(odd) {
    background-color: #f4f4f4;
}

.invoicing__details-footer--payment-history-link {
    position: relative;
    float: right;
    width: 100%;
}

div.grid__row.invoicing__details-footer--amount-due {
    margin-top: 1.8rem;
}

.invoicing__details-footer--payment-history-link span  {
    position: absolute;
    right: 0;
    top: -3.6rem;
    color: #006693;
}

.invoicing-footer__format-table {
    width: 100%;
    margin-bottom: 20px;
}

.invoicing-footer__format-table th {
    padding-bottom: 8px;
}

.invoicing-footer__format-table td {
    padding-top: 8px;
}

.invoicing-footer__format-header {
    border-bottom: 1px solid #f4f4f4;
}

.details-footer--text-20 {
    font-size: 20px;
    line-height: 28px;
    white-space: nowrap;
}

.invoicing__details-footer .grid__row .grid__column:last-child span {
    margin-left: 10px;
}

.invoicing__details-footer .grid__column p.smallGrayTxt {
    line-height: 1rem;
}

.invoicing__credit-card-auth-form .grid__row {
    margin-bottom: 10px;
    line-height: 1.5rem;
}

.invoicing__credit-card-auth-form .grid__row label {
    font-weight: bold;
}

.invoicing__credit-card-auth-form input[type="text"] {
    min-width: 300px;
    max-width: 300px;
    margin: 0;
}

.invoicing__credit-card-auth-form .inputControl textarea {
    min-width: 298px;
    max-width: 298px;
    min-height: 6rem;
    margin: 0;
}

#modal .modalContent[class*="modal__payment-history"] {
    width: 94%;
    min-width: 400px;
}

/* Invoicing ExtJS overrides*/
.x-webkit input[type="text"].x-form-text {
    margin: 0;
    padding: 0;
    height: auto;
}

.invoicing__invoice-d--header-row {
    background-color: rgba(237, 81, 59, 0.1);
}

/* Pay invoices begin */

.payInvoices--section-left {
    float: left;
    width: 280px;
    margin-right: 20px;
}

.payInvoices--section-mid {
    margin-left: 20px;
    float: left;
}

.payInvoices--invoiceSummaries-table .invoiceSummaryRow {
    display: flex;
    flex-direction: row;
    border-top: 1px solid #ffffff;
    border-bottom: 1px solid #ffffff;
}

.payInvoices--invoiceSummaries-table .invoiceSummaryCell {
    background-color: #f0f0f0;
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    flex-shrink: 0;
    padding: 5px;
    flex-basis: 0;
}

.payInvoices--invoiceSummaries-table .invoiceSummaryCell.rightColumn {
    justify-content: flex-end;
    border-left: 1px solid #ffffff;
}

.payInvoices--invoiceSummaries-table .invoiceSumaryCell.leftColumn {
    justify-content: flex-start;
    border-right: 1px solid #ffffff;
}

/* Pay invoices end */
/********************************* End Invoicing */
/********************************* Begin New Ext Report */
.report__container a:hover.x-toolbar-item {
    text-decoration: none;
}
.report__container .x-toolbar-item .x-btn-inner {
    line-height: 24px;
    font-size: 100%;
    font-weight: bold;
    top: 10px !important;
}

.report__container .x-toolbar-item .x-btn-inner ::before {
    vertical-align: middle;
}

.report__container--empty {
    text-align: center;
    margin-top: 5%;
    font-size: 1.4em;
    color: #707070;
    font-family: proxima-nova-semibold;
}

.report__form-header--center .x-title-text {
    text-align: center;
}

.ext-row__checkbox--disabled .x-grid-checkcolumn:after {
    opacity: 0.3;
}

/********************************* End New Ext Report*/

/********************************* Begin New Ext Form */

.x-boundlist,
.x-boundlist-item,
.x-boundlist-floating {
    max-width: 300px !important;
}

.ext__form--search .x-form-item,
.ext__form--search .x-box-inner {
    margin-bottom: 10px;
}

.ext__form--search .x-panel-body-default {
    border: none;
}

.x-webkit .x-toolbar input[type="text"],
.x-webkit .ext__form--search input[type="text"] {
    border: none;
    padding-left: 4px;
}

.ext__form--search input[type='text'],
.ext__form--search input[type='password'],
.ext__form--search input[type='number'],
.ext__form--search textarea {
    border: none;
}

.ext__form--search .x-box-inner {
    margin-bottom: 0;
}

.ext__form--search .x-form-trigger-wrap {
    max-width: 300px;
    float: right;
}

.ext__form--search .input__on-hold .input__checkbox {
    margin-left: 10px;
    margin-bottom: 0px;
}

.ext__form--search .dates__relative--compact.dates__container--relative .x-autocontainer-outerCt {
    width: 500px !important;
}

.ext__form--search .dates__relative--compact .input__date--units,
.ext__form--search .dates__relative--compact .input__date--units .x-form-trigger-wrap {
    width: 140px !important;
    float: left;
    margin-left: 100px;
}

.ext__form--search .dates__relative--compact .input__date--unit,
.ext__form--search .dates__relative--compact .input__date--unit .x-form-trigger-wrap {
    width: 140px !important;
    float: right;
}

.ext__form--search .minmax__container--compact .x-autocontainer-outerCt {
    width: 500px !important;
}

.ext__form--search .minmax__container--compact .input__minmax--unit,
.ext__form--search .minmax__container--compact .input__minmax--unit .x-form-trigger-wrap {
    width: 143px !important;
    float: right;
    margin-left: 14px;
}

.ext__form--search .x-form-text-wrap .x-placeholder-label {
    top: 2px;
    color: gray;
}

.ext__form--search .x-form-text-default {
    background-image: none;
}

.ext__form--search .input__isbn--textarea {
    height: 90px;
}

/*Help text for an ext textarea input the more lines of copy, the less the top value needs to be*/
.ext__form--search .textarea__informational-text-65top {
    /*3 lines of copy*/
    width: 300px;
    left: 200px !important;
    top: 65px !important;
}

.ext__form--search .textarea__informational-text-80top {
    /*2 lines of copy*/
    width: 300px;
    left: 200px !important;
    top: 80px !important;
}


.ext__form--search .x-form-cb-label {
    max-width: 350px;
}

.ext__form--search .input-radio-options .x-form-cb-label {
    max-width: 300px;
}

/*Default help text settings for small gray text under input */
.ext__form--search .input__informational-text-default {
    left: 200px !important;
    top: 28px !important;
}

/* needed else the grey ext form backdrop won't cover the whole page when scrolling */
.x-mask.x-border-box[role=presentation] {
    position: fixed;
}

/************************/
div.grid__container-div.ext__summary {
    max-width: 600px;
    float: left;
}

.ext__summary .grid__row {
    background-color: #FFF;
}

.ext__summary .grid__column {
    background-color: #f0f0f0;
}

.ext__summary .grid__column span {
    display: block;
    padding: 5px;
    border: 1px solid #FFF;
    height: 100%;
}

.ext__summary .grid__column:first-child span {
    font-weight: bold;
}


/********************************* End New Ext Form */

/********************************* Begin New Ext Inputs */

#gridContainer .ext-input__search-within {
    top: 1px !important;
}

.x-gecko #gridContainer .ext-input__search-within {
    top: -1px !important;
}

#gridContainer .ext-input__search-within .x-form-text-wrap-toolbar {
    border: none;
    overflow: visible;
    display: block;
}

#gridContainer .ext-input__search-within .x-form-trigger-wrap-toolbar {
    position: relative;
    display: block;
}

#gridContainer .ext-input__search-within .x-form-text-toolbar {
    height: 30px;
    border-radius: 4px;
    padding-left: 30px;
    padding-right: 23px;
    width: 270px;
}

#gridContainer .ext-input__search-within .x-form-clear-trigger-toolbar,
#gridContainer .ext-input__search-within .x-form-search-trigger-toolbar {
    background: none;
    background-image: none;
    font-family: 'Marketplace';
    font-size: 14px;
    text-transform: none;
    position: absolute;
    overflow: visible;
    border: none;
}

#gridContainer .ext-input__search-within .x-form-search-trigger-toolbar {
    left: 7px;
    top: 15px;
}

#gridContainer .ext-input__search-within .x-form-clear-trigger-toolbar {
    left: 247px;
    top: 15px;
}

#gridContainer .ext-input__search-within .x-form-search-trigger-toolbar:before {
    content: "\e93a";
}

#gridContainer .ext-input__search-within .x-form-clear-trigger-toolbar:before {
    content: "\e905";
}

/********************************* End New Ext Inputs */

/********************************* Begin CPC */

.cpc__add-revoke--button {
    margin: 0;
}

.cpc__add-revoke--button .icon-clock {
    font-size: 11px;
    float: none;
    margin-left: 3px;
}

.cpc__sidebar--link {
    margin: 2px 0 8px 8px;
    display: block;
}

#modal .button.right {
    float: right;
}

.search__cpc-links {
    float: right;
    font-size: 15px;
    color: #137BA8;
    margin-left: 24px;
}

.search__cpc-links ul li {
    display: inline-block;
    height: 20px;
    text-align: center;
    vertical-align: middle;
    line-height: 20px;
}

.search__cpc-links ul li a {
    margin: 0;
}

.search__cpc-links ul li a:hover {
    text-decoration: none;
    color: #137BA8;
    opacity: 1;
}

.search__cpc-links li:last-child {
    border-left: 1px solid #AAAAAA;
    padding-left: 8px;
}

.search__cpc-links li:first-child {
    padding-right: 4px;
}

/********************************* End CPC */
/********************************* Begin News page */
.news__contain {
    position: relative;
}

.news__main-content {
    width: 66%;
    display: inline-block;
    vertical-align: top;
}

.news__social-content {
    width: 30%;
    display: inline-block;
    min-width: 320px;
    vertical-align: top;
}

.news__card-div {
    border: 1px solid #e2e2e2;
    border-radius: 3px;
}

.news__card-div h1 {
    padding: 6px 10px;
    margin: 0;
    background-color: #F4F4F4;
}

.news__card-div ul {
    height: 300px;
    overflow-y: auto;
    padding-top: 10px;
    position: relative;
}

.news__card-div ul li {
    position: relative;
    display: inline-block;
    padding-bottom: 10px;
    width: 100%;
}

.news__card-div ul li:nth-child(even) {
    background-color: #fafafa;
}

.news__product-updates--list li p,
.news__content-sales--list li p {
    vertical-align: top;
    padding: 5px 10px 4px 10px;
    margin: 0;
}

.news__news--date {
    position: absolute;
    top: 6px;
    padding: 0 10px;
}

.news__news-description {
    margin: 6px 10px 6px 10px;
}

.news__no-alerts {
    margin: 6px 10px;
}

.news__content-sales-read-more,
.news__news-read-more,
.news__product-updates-read-more {
    float: right;
    margin-right: 10px;
}

.news__content-sales--list h2,
.news__news--list h2,
.news__product-updates--list h2 {
    font-size: 14px;
    font-family: 'proxima-nova', 'Segoe UI', 'Open Sans', Tahoma, Helvetica, sans-serif;
    font-weight: bold;
    padding: 10px 10px 0 10px;
    margin: 0;
}

.news__news--list h2 {
    margin: 6px 10px 0 10px;
    padding: 0;
}

.news__social-feeds {
    text-align: center;
}

.news__social-feeds h2 {
    text-align: left;
    padding: 6px 10px 0 10px;
    margin: 0;
}

.news__social--facebook,
.news__social--twitter {
    padding: 0 0 20px 0;
    margin: auto;
}

.news__social--blogs-button {
    margin-bottom: 20px;
    margin-top: 10px;
}

.news__social--blogs-button .button span {
    margin: 0 0 0 6px;
}

.news__card-div .news__social--links {
    position: relative;
    height: auto;
    padding: 0;
    margin: 0;
    min-height: 50px;
}

.news__card-div .news__social--links li {
    margin: 10px 5px;
    width: auto;
}

.div--100 {
    width: 98%;
    margin: 0 2% 20px 0;
}

.div--50 {
    width: 48%;
    margin: 0 2% 20px 0;
}
/********************************* End News page */

/********************************* Begin Weeding section */

.weeding__info-list {
    list-style: outside disc none;
    padding-left: 20px;
    margin: 10px 0;
}

.weeding__summary-contain {
    display: inline-block;
    padding: 10px 20px 10px 10px;
    border-radius: 5px;
    background-color: #F4F4F4;
    margin-bottom: 10px;
}

#modal .modal__auto-weeding-confirm p {
    margin-bottom: 10px;
}

/********************************* End Weeding section */

/********************************* Begin Marc Express section */

#frmCreateFile .marcexpress__modal-select, #frmDownloadBackdatedFile .marcexpress__modal-select {
    width: 320px;
}

#frmCreateFile .ui-datepicker-trigger {
    margin-left: 5px;
}

#frmCreateFile textarea {
    resize: vertical;
}

#frmCreateFile #CartIdError,
#frmCreateFile #OrderIdError {
    margin-top: 4px;
}

#modal .modal__marc-express-confirm {
    width: 520px;
}

#modal .modal__marc-express-confirm p {
    margin-bottom: 10px;
}

/********************************* End Marc Express section */

@media screen and (max-width : 1200px) {
    .bannerListWpr {
        display: none !important;
    }
    .bannerListWpr__featured {
        display: block !important;
    }
}


/***** GRID CSS *****/

/* 12 section grid, Invoicing */
div.grid__container-div {
    margin: 0 auto 20px;
    display: block;
    float: left;
    width: 100%;
}

div.grid__row {
    float: left;
    margin: 0 auto;
    line-height: 1rem;
    width: 100%;
    clear: both;
}

div.grid__column {
    display: inline-block;
    float: left;
    padding: 0;
}

.column--width-01 {
    width: 8.33%;
}

.column--width-02 {
    width: 16.66%;
}

.column--width-03 {
    width: 25%;
}

.column--width-04 {
    width: 33.33%;
}

.column--width-05 {
    width: 41.66%;
}

.column--width-06 {
    width: 50%;
}

.column--width-07 {
    width: 58.33%;
}

.column--width-08 {
    width: 66.66%;
}

.column--width-09 {
    width: 75%;
}

.column--width-10 {
    width: 83.33%;
}

.column--width-11 {
    width: 91.66%;
}

.column--width-12 {
    width: 100%;
}

.grid__column.grid__header {
    background-color: #666666;
    color: #ffffff;
}

.column--right-align {
    text-align: right;
}

/***** GRID CSS *****/



/***** CSS MODIFIERS *****/

.element--cursor-pointer {
    cursor: pointer;
}

.element--display-block {
    display: block;
}

.element--display-inline {
    display: inline;
}

.element--display-inline-block {
    display: inline-block;
}

.element--display-table-caption {
    display: table-caption;
}

.element--position-absolute {
    position: absolute;
}

.element--vertical-align-top {
    vertical-align: top;
}

.element--bottom-margin-35 {
    margin-bottom: 35px !important;
}

.element--bottom-margin-20 {
    margin-bottom: 20px !important;
}

.element--bottom-margin-21 {
    margin-bottom: 21px !important;
}

.element--bottom-margin-12 {
    margin-bottom: 12px;
}

.element--bottom-margin-6 {
    margin-bottom: 6px;
}

.element--bottom-margin-2 {
    margin-bottom: 2px;
}

.element--top-margin-64 {
    margin-top: 64px !important;
}

.element--top-margin-50 {
    margin-top: 50px !important;
}

.element--top-margin-36 {
    margin-top: 36px !important;
}

.element--top-margin-30 {
    margin-top: 30px !important;
}

.element--top-margin-25 {
    margin-top: 25px !important;
}

.element--top-margin-24 {
    margin-top: 24px !important;
}

.element--top-margin-22 {
    margin-top: 22px !important;
}

.element--top-margin-21 {
    margin-top: 21px !important;
}

.element--top-margin-20 {
    margin-top: 20px !important;
}

.element--top-margin-18 {
    margin-top: 18px !important;
}

.element--top-margin-17 {
    margin-top: 17px;
}

.element--top-margin-15 {
    margin-top: 15px !important;
}

.element--top-margin-minus15 {
    margin-top: -15px;
}

.element--top-margin-12 {
    margin-top: 12px;
}

.element--top-margin-11 {
    margin-top: 11px;
}

.element--top-margin-10 {
    margin-top: 10px !important;
}

.element--top-margin-8 {
    margin-top: 8px !important;
}

.element--top-margin-7 {
    margin-top: 7px !important;
}

.element--top-margin-6 {
    margin-top: 6px;
}

.element--top-margin-5 {
    margin-top: 5px !important;
}

.element--top-margin-4 {
    margin-top: 4px;
}

.element--top-margin-3 {
    margin-top: 3px !important;
}

.element--top-margin-2 {
    margin-top: 2px !important;
}

.element--top-margin-1 {
    margin-top: 1px !important;
}

.element--top-margin-0 {
    margin-top: 0 !important;
}

.element--top-margin-minus7 {
    margin-top: -7px !important;
}

.element--bottom-margin-40 {
    margin-bottom: 40px !important;
}

.element--bottom-margin-30 {
    margin-bottom: 30px !important;
}

.element--bottom-margin-25 {
    margin-bottom: 25px !important;
}

.element--bottom-margin-24 {
    margin-bottom: 24px !important;
}

.element--bottom-margin-22 {
    margin-bottom: 22px;
}

.element--bottom-margin-20 {
    margin-bottom: 20px !important;
}

.element--bottom-margin-18 {
    margin-bottom: 18px !important;
}

.element--bottom-margin-17 {
    margin-bottom: 17px !important;
}

.element--bottom-margin-16 {
    margin-bottom: 16px !important;
}

.element--bottom-margin-15 {
    margin-bottom: 15px !important;
}

.element--bottom-margin-10 {
    margin-bottom: 10px !important;
}

.element--bottom-margin-8 {
    margin-bottom: 8px;
}

.element--bottom-margin-4 {
    margin-bottom: 4px !important;
}

.element--bottom-margin-3 {
    margin-bottom: 3px !important;
}

.element--bottom-margin-5 {
    margin-bottom: 5px !important;
}

.element--left-margin-48 {
    margin-left: 48px !important;
}

.element--left-margin-39 {
    margin-left: 39px !important;
}

.element--left-margin-36 {
    margin-left: 36px !important;
}

.element--left-margin-30 {
    margin-left: 30px !important;
}

.element--left-margin-29 {
    margin-left: 29px !important;
}

.element--left-margin-25 {
    margin-left: 25px !important;
}

.element--left-margin-24 {
    margin-left: 24px !important;
}

.element--left-margin-20 {
    margin-left: 20px !important;
}

.element--left-margin-18 {
    margin-left: 18px !important;
}

.element--left-margin-15 {
    margin-left: 15px !important;
}

.element--left-margin-12 {
    margin-left: 12px !important;
}

.element--left-margin-10 {
    margin-left: 10px !important;
}

.element--left-margin-8 {
    margin-left: 8px !important;
}

.element--left-margin-5 {
    margin-left: 5px !important;
}

.element--left-margin-4 {
    margin-left: 4px !important;
}

.element--left-margin-2 {
    margin-left: 2px !important; 
}

.element--left-margin-0 {
    margin-left: 0px !important;
}

.element--left-margin-1percent {
    margin-left: 1% !important;
}

.element--left-margin-negative10 {
    margin-left: -10px !important;
}

.element--right-margin-0 {
    margin-right: 0 !important;
}

.element--right-margin-3 {
    margin-right: 3px !important;
}

.element--right-margin-4 {
    margin-right: 4px !important;
}

.element--right-margin-5 {
    margin-right: 5px !important;
}

.element--right-margin-8 {
    margin-right: 8px;
}

.element--right-margin-10 {
    margin-right: 10px !important;
}

.element--right-margin-12 {
    margin-right: 12px;
}

.element--right-margin-15 {
    margin-right: 15px;
}

.element--right-margin-16 {
    margin-right: 16px !important;
}

.element--right-margin-18 {
    margin-right: 18px !important;
}

.element--right-margin-20 {
    margin-right: 20px !important;
}

.element--right-margin-25 {
    margin-right: 25px;
}

.element--right-margin-48 {
    margin-right: 48px !important;
}

.element--bottom-margin-0 {
    margin-bottom: 0 !important;
}

.element--margin-0 {
    margin: 0 !important;
}

.element--margin-5 {
    margin: 5px;
}

.element--margin-8 {
    margin: 8px;
}

.element--padding-22 {
    padding: 22px;
}

.element--padding-10 {
    padding: 10px;
}

.element--padding-2 {
    padding: 2px;
}

.element--padding-0 {
    padding: 0 !important;
}

.element--top-padding-24 {
    padding-top: 24px !important;
}

.element--top-padding-18 {
    padding-top: 18px !important;
}

.element--top-padding-20 {
    padding-top: 20px;
}

.element--top-padding-15 {
    padding-top: 15px;
}

.element--top-padding-12 {
    padding-top: 12px !important;
}

.element--top-padding-10 {
    padding-top: 10px;
}
.element--top-padding-6 {
    margin-top: 6px !important;
}
.element--top-padding-5 {
    padding-top: 5px;
}
.element--top-padding-4 {
    margin-top: 4px;
}

.element--top-padding-2 {
    padding-top: 2px;
}

.element--top-padding-0 {
    padding-top: 0 !important;
}

.element--right-padding-30 {
    padding-right: 30px;
}

.element--right-padding-20 {
    padding-right: 20px;
}

.element--right-padding-10 {
    padding-right: 10px;
}

.element--right-padding-12 {
    padding-right: 12px;
}

.element--right-padding-percentage-10 {
    padding-right: 10% !important;
}

.element--right-padding-8 {
    padding-right: 8px !important;
}

.element--right-padding-5 {
    padding-right: 5px;
}

.element--right-padding-75 {
    padding-right: 75px;
}

.element--left-padding-30 {
    padding-left: 30px;
}

.element--left-padding-25 {
    padding-left: 25px !important;
}

.element--left-padding-20 {
    padding-left: 20px !important;
}

.element--left-padding-14 {
    padding-left: 14px !important;
}

.element--left-padding-12 {
    padding-left: 12px;
}

.element--left-padding-10 {
    padding-left: 10px !important;
}

.element--left-padding-percentage-10 {
    padding-left: 10% !important;
}

.element--left-padding-8 {
    padding-left: 8px !important;
}

.element--left-padding-7 {
    padding-left: 7px !important;
}

.element--left-padding-3 {
    padding-left: 3px !important;
}

.element--left-padding-0 {
    padding-left: 0 !important;
}

.element--bottom-padding-24 {
    padding-bottom: 24px !important;
}

.element--bottom-padding-20 {
    padding-bottom: 20px;
}

.element--bottom-padding-15 {
    padding-bottom: 15px !important;
}

.element--bottom-padding-14 {
    padding-bottom: 14px;
}

.element--bottom-padding-12 {
    padding-bottom: 12px;
}

.element--bottom-padding-10 {
    padding-bottom: 10px;
}

.element--bottom-padding-8 {
    padding-bottom: 8px;
}

.element--bottom-padding-4 {
    padding-bottom: 4px;
}

.element--bottom-padding-0 {
    padding-bottom: 0px;
}

.element--padding-20 {
    padding: 20px;
}

/* element--width-800 can be used for inline modal elements that should not exceed the min-width of the MP site of 960px */
.element--width-800 {
    max-width: 800px;
}

.element--width-650 {
    width: 650px;
}

.element--width-500 {
    width: 500px;
}

.element--width-400 {
    width: 400px;
}

.element--width-350 {
    width: 350px;
}

.element--width-250 {
    width: 250px !important;
}

.element--min-width-400 {
    min-width: 400px;
}

.element--min-width-85 {
    min-width: 85px;
}

.element--min-width-8 {
    min-width: 8px;
}

.element--width-74 {
    width: 74px;
}

.element--width-50 {
    width: 50px;
}

.element--width-percentage-90 {
    width: 90% !important;
}

.element--width-percentage-75 {
    width: 75%;
}

.element--width-percentage-60 {
    width: 60%;
}

.element--height-130 {
    height: 150px !important;
}

.element--height-100 {
    height: 100px !important;
}

.element--height-20 {
    height: 20px !important;
}

.element--height-30 {
    height: 30px !important;
}

.element--weight-400 {
    font-weight: 400;
}

.element--weight-600 {
    font-weight: 600;
}

.element--weight-700 {
    font-weight: 700;
}

.element--cursor-default {
    cursor: default !important;
}

.element--verticalalign-middle {
    vertical-align: middle;
}

.element--border-radius-right-3 {
    border-top-right-radius: 3px !important;
    border-bottom-right-radius: 3px !important;
}

.element--border-radius-5 {
    border-radius: 5px;
}

.element--border-bottom-none {
    border-bottom: none !important;
 }

.element--border-bottom-1px {
    border-bottom: 1px solid;
}

.rotate-counter-90 {
    transform: rotate(-90deg);
}

.rotate-90 {
    transform: rotate(90deg);
}

.text-uppercase {
    text-transform: uppercase;
}

.text-line-height-22 {
    line-height: 22px;
}

.text-line-height-30 {
    line-height: 30px !important;
}

.text-nowrap {
    white-space: nowrap;
}

.center-text {
    text-align: center;
}

.right-text {
    text-align: right;
}

.left-text {
    text-align: left;
}

.font-size-16 {
    font-size: 16px;
}

.font-size-18 {
    font-size: 18px;
}

.font-size-21 {
    font-size: 21px;
}

.font-size-2point5em {
    font-size: 2.5em;
}

.font-size-1point75em {
    font-size: 1.75em !important;
}

.font-size-point9em {
    font-size: 0.9em;
}

.tablespacer--width-48 {
    width: 48px !important;
    min-width: 48px !important;
}

.tablespacer--height-25 {
    height: 25px !important;
    min-height: 25px !important;
}

.overflow-hidden {
    overflow: hidden;
}

.white-space--normal {
    white-space: normal !important;
}

.border--rounded {
    border-radius: 4px !important;
}

.border--rounded-top {
    border-radius: 4px 4px 0 0;
}

.border--rounded-bottom {
    border-radius: 0 0 4px 4px;
}

.background-color--blue {
    background-color: #006693;
}

.background-color--green {
    background-color: #8EC549;
}

.background-color--light-grey {
    background-color: #F9F9F9;
}

.rainbow {
  background-image: gradient( linear, left top, right top, color-stop(0, #f22), color-stop(0.15, #f2f), color-stop(0.3, #22f), color-stop(0.45, #2ff), color-stop(0.6, #2f2),color-stop(0.75, #2f2), color-stop(0.9, #ff2), color-stop(1, #f22) );
  color:transparent;
  background-clip: text;
}

/***** END CSS MODIFIERS *****/

/********************************* Begin Language Specific Font Modifiers */
.lang-ja:where(body, input, button, .button, select, textarea, h2),
.lang-ja {
    font-family: 'proxima-nova', 'Segoe UI', 'Open Sans', 'Hiragino Kaku Gothic Pro', 'YuGothic', 'Yu Gothic', 'Meiryo', Tahoma, Helvetica, sans-serif;
}

.lang-ja .smallGrayTxt,
.lang-ja .viewTitleDetailsInfo.translation {
    font-size: .9em;
}
.lang-ja .simuse-plan-info,
.lang-ja .DataTables_sort_wrapper,
.lang-ja .disclaimer {
    font-size: 1em;
}

.lang-ja .featuredTitleBuyButton .addToCartContainer a.addToCart {
    font-size: 13px;
}

.lang-zh .smallGrayTxt,
.lang-zh .viewTitleDetailsInfo.translation {
    font-size: .9em;
}
.lang-zh .simuse-plan-info,
.lang-zh .DataTables_sort_wrapper,
.lang-zh .disclaimer {
    font-size: 1em;
}

/********************************* End Language Specific Font Modifiers */

/***** BEGIN Lucky Day *****/

.middleContent.LD__middleContent-withRight {
    padding: 0 18% 10px 0;
}

.LD__middleContent-aboveGrid {
    width: 100%;
    display: inline-block;
    margin-bottom: 20px;
    margin-top: 5px;
}

.LD__sidebar-right {
    right: 0;
    width: 17%;
    min-width: 144px;
    position: absolute;
    top: 60px;
    z-index: 300;
}
.LD__draft-collections-form--container {
    margin-right: 60px;
}

.LD__draft-collections-form--container .collections-form--block-section {
    width: calc(100% - 50px);
    float: left;
    margin-left: 15px;
    display: inline-block;
}

.LD__draft-collections-form--container .customGridWidthContainer {
    width: calc(100vw - 2.4% - 65px);
    min-width: 897px;
}

.LD__sidebar-container {
    border: 1px solid #d3d3d3;
    border-radius: 5px;
    padding: 10px 20px;
    min-height: 200px;
}

.LD__sidebar-details {
    margin-left: 24px;
    line-height: 2em;
}

.LD__sidebar-details-adv {
    display: inline-block;
    width: 90%;
    vertical-align: top;
}

a.LD__sidebar-edit {
    margin: 10px auto;
    text-decoration: none;
}

.LD__manageTitlesGrid-empty,
.LD__publishedCollectionsGrid-empty,
.LD__draftCollectionsGrid-empty {
    text-align: center;
    margin-top: 10%;
    font-size: 1.1em;
}

.LD__policies-body {
    padding-left: 20px;
    border-radius: 7px;
}

#LuckyDayLendingPoliciesFrm {
    margin-top: 10px;
}

    #LuckyDayLendingPoliciesFrm .LD__policies-inputDetails {
        margin: 1px 10px 0 0px;
        display: inline-block;
    }

    #LuckyDayLendingPoliciesFrm input[type="text"] {
        border-radius: 4px;
    }

    #LuckyDayLendingPoliciesFrm select {
        border-radius: 4px;
        margin-bottom: 10px;
        padding-left: 3px;
    }

#LuckyDayLendingPoliciesFrm .disabled select {
    padding-left: 6px;
}

#LuckyDayLendingPoliciesFrm .disabled .lending-option__warning {
    display: none;
}

#LuckyDayLendingPoliciesFrm .lending-option__warning {
    background-color: #FFF1F0;
    border-radius: 4px;
    padding: 5px 14px;
    margin-bottom: 10px;
    display: inline-block;
    font-size: .95em;
    color: black;
}

#LuckyDayLendingPoliciesFrm .lending-option__warning--icon {
    color: #FF7F01;
    float: left;
    display: inline-block;
    margin-right: 10px;
}

.LD__policies-buttonsSection {
    margin-top: 40px;
}

.LD__policies-buttonsSection a {
    display: block;
    margin-left: 0;
}

.LD__icon-verticalAlign-center {
    display: inline;
    position: relative;
    top: 3px;
}
/*Add title (+) link in grid*/
.LD__addIcon:hover {
    font-weight: bold;
}

.add-titles__add-unit-controls-container .add-titles__unit-type-dropdown {
    height: 28px;
    border-radius: 3px;
    border: 1px solid #DDDDDD;
    vertical-align: top;
    padding-left: 6px;
    cursor: pointer;
    margin-left: 10px;
}

.add-titles__add-unit-controls-container .add-titles__unit-option-input {
    height: 26px;
    width: 45px;
    text-align: right;
    border-radius: 3px;
    border: 1px solid #DDDDDD;
    margin-top: 0;
    padding-right: 10px;
    margin-left: 4px;
    margin-right: 10px;
}

.add-titles__add-unit-controls-container .add-titles__unit-type-dropdown .icon-small-arrow-down {
    font-size: 14px;
}

#LuckyDayManageTitlesGrid-body td.x-grid-dirty-cell {
    background: none;
}

.LD__icon-search, .SearchWithin__icon-search {
    color: #006693;
    cursor: pointer;
    font-size: 14pt;
    position: relative;
    top: 4px;
    left: 4px;
}

.LD__searchWithin-div, .SearchWithin-div {
    border: 1px solid #E3E3E3;
    border-radius: 4px;
    display: inline-block;
    padding: 2px;
    padding-bottom: 1px;
    width: 300px;
    height: 25px;
}

input[type="text"].LD__searchWithin-input, input[type="text"].SearchWithin-input {
    border: none;
    width: 88%;
}

#modal .modal__lucky-day--adjust-units .luckyDay__option--input-wrapper {
    margin-top: 14px;
}

.add-titles__allocation-copy--hidden,
#modal .modal__lucky-day--adjust-units .luckDay__input-wrapper--hidden {
    visibility: hidden;
    overflow: hidden;
    height: 0;
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
}

#modal .modal__lucky-day--adjust-units .luckyDay__option--input-wrapper input {
    width: 25px;
    height: 22px;
    border-radius: 4px;
    padding-right: 10px;
    text-align: right;
    margin: 0 5px;
}

#modal .modal__lucky-day--adjust-units .luckyDay__option--input-wrapper input.percentageInput {
    padding-right: 20px;
}

#modal .modal__lucky-day--adjust-units .luckyDay__option--input-wrapper .percentageCharacter {
    display: inline-block;
    margin-left: -23px;
    margin-right: 15px;
}

#modal .modal__lucky-day--adjust-units .luckyDay__option--input-wrapper.option-Unit input {
    margin-left: 0;
}

#modal .modal__lucky-day--adjust-units {
    margin: -7px 12px 5px 12px;
}

#modal .modal__lucky-day--adjust-units .lucky-day__radio-option--container {
    margin-top: 14px;
}

#modal .luckyday__getting-started h1 {
    font-size: 2em;
}

#modal .luckyday__getting-started .luckyday__getting-started--header {
    border: 1px solid #173e54;
    border-top: 1px solid black;
}

/*Help text class for max price spinner*/
.ext__form--lucky-day .spinner--max-price__informational-text {
    width: 300px;
    margin: 0 0 0 200px;
    top: 80px !important;
}
/***** END Lucky Day *****/

/***** BEGIN ExtJs styles *****/


.grid__cell-editable {
    border: 1px solid #999;
    background-color: #fff;
    padding: 2px;
    padding-bottom: 3px;
    border-radius: 4px;
}

.cell__editable-editing {
    border: none;
}

.cell__editable-editing.x-form-item {
    margin-left: 6px;
    margin-right: 16px;
    padding-bottom: 0;
}

.cell__editable-editing.x-form-item.x-form-invalid .x-form-text-field-body-grid-cell {
    width: 100%;
}

.cell__editable-editing div.x-form-trigger-wrap {
    padding-right: 10px;
}

.cell__editable-editing div.x-form-text-wrap {
    border: 1px solid #999;
    border-radius: 4px 0 0 4px;
}

.cell__editable-editing input {
    text-align: right;
    border: none;
}

.x-grid-item .x-grid-row.mp-orange {
    background-color: rgba(255, 128, 0, 0.4); /* #FF8000*/
}

.x-grid-item.x-grid-item-selected .x-grid-row.mp-orange {
    background-color: rgba(230, 103, 0, 0.65); /* #E66700*/
}

.x-toolbar .x-btn.ext__button--create-worksheet {
    left: 0px !important;
    text-transform: uppercase;
}

.ext__button--create-worksheet .icon-table {
    font-size: 1em;
}

.x-toolbar-footer .x-btn:hover {
    text-decoration: none;
}

.x-toolbar .x-btn.ext__button--reset-filters,
.x-toolbar .x-btn.ext__button--reset-filters:active {
    background: none;
    border: none;
    text-decoration: none;
    left: -6px !important;
}

.x-toolbar .x-btn.ext__button--reset-filters .x-btn-inner-default-small {
    color: #006693;
}

.ext__linkbutton--find-titles {
    left: 200px;
    width: 100px;
    padding-bottom: 10px;
}

.ext_linkbutton--find-titles-icon {
    font-size: 12px;
    color: #006693;
}

/***** END ExtJs styles *****/
/***** BEGIN Toast styles *****/
.toastv2 {
    display: inline-block;
    max-width: 800px;
    border: none;
    border-radius: 5px;
    position: fixed;
    box-shadow: 0px 5px 10px 0px rgba(51, 51, 51, 0.2);
    z-index: 1105;
    font-size: 14.8px;
}

    .toastv2.toast--wrapper {
        background-color: rgba(255, 255, 255, 1);
    }

    .toastv2 .toast--body {
        padding: 12px;
        display: inline-block;
        float: left;
        width: -webkit-fill-available;
        width: -moz-available;
        width: fill-available;
        border-radius: 5px;
    }

.toastv2 .toast--close {
    display: inline-block;
    float: right;
    width: 16px;
    vertical-align: top;
    padding-left: 20px;
    cursor: pointer;
}

    .toastv2 .toast--icon, .toastv2 .toast--icon .icon-alert {
        display: block;
        float: left;
        width: 19px;
        padding-right: 10px;
        vertical-align: top;
    }

        .toastv2 .toast--icon [class^="icon-"] {
            font-size: 18.5px;
        }

.toastv2 .toast--message {
    display: block;
    margin-left: 30px;
    margin-right: 36px;
    padding-top: 1px;
    color: rgba(51, 51, 51, 1);
}

    .toastv2 .toast--price {
        color: #D02C50;
        font-weight: 500;
    }

.toast--success {
    background-color: rgba(142, 197, 73, 0.15);
}
    .toast--success .toast--icon {
        color: rgba(142, 197, 73, 1);
    }

.toast--error {
    background-color: rgba(255, 151, 151, 0.15);
}
    .toast--error .toast--icon {
        color: rgba(255, 151, 151, 1);
    }

.toast--warning {
    background-color: rgba(255, 128, 0, 0.15);
}
    .toast--warning .toast--icon {
        color: rgba(255, 128, 0, 1);
    }
/***** END Toast styles *****/

/***** BEGIN Inputs *****/

input.rounded {
    border: 1px solid #aaaaaa;
    border-radius: 4px;
    display: inline-block;
    padding: 2px;
    padding-bottom: 1px;
    height: 25px;
}

/*
    How to style a select box found here:
    https://fabriceleven.com/design/clever-way-to-change-the-drop-down-selector-arrow-icon/
*/
.select--rounded {
    border: 1px solid #aaaaaa;
    border-radius: 4px;
    display: inline-block;
    padding: 2px 2px 1px 2px;
    position: relative;
    background-color: white;
    box-sizing: border-box;
}

    .select--rounded:after {
        content: "\e93b";
        color: #aaaaaa;
        font: normal normal normal 17px/1 'Marketplace';
        transform: rotate(90deg);
        right: 10px;
        top: 6px;
        position: absolute;
        pointer-events: none;
    }

    .select--rounded select {
        -webkit-appearance: none;
        -moz-appearance: none;
        border: none;
        width: 100%;
        padding-left: 5px;
        margin: 0;
        padding-right: 30px;
        cursor: pointer;
    }

    .select--rounded select.input-validation-error {
        border: none !important;
    }

        .select--rounded select > option {
            color: #333333;
        }

    .select--rounded select.hasPlaceholder option:first-of-type {
        color: #999999;
    }

    .select--rounded select.hasPlaceholder.showingPlaceholder {
        opacity: 0.65;
    }

/** Icon Inputs **/
.iconInput--container {
    display: inline-flex;
    vertical-align: top;
    cursor: pointer;
}

.iconInput--container input {
    display: none;
}

.iconInput--container input:disabled {
    cursor: default;
}

    .iconInput--container .iconInput--checkmark,
    .iconInput--container input.iconInput--partialCheck ~ .iconInput--checkbox .iconInput--checkmark {
        display: none;
        color: #FFFFFF;
        font-size: 10px;
        margin-top: 3px;
        margin-left: 3px;
    }

.iconInput--container input:checked ~ .iconInput--checkbox .iconInput--checkmark {
    display: block;
}

    .iconInput--container .iconInput--checkmark-partial,
    .iconInput--container input.iconInput--partialCheck:checked ~ .iconInput--checkbox .iconInput--checkmark-partial {
        display: none;
        color: #666666;
        padding-left: 2.5px;
        top: -1px;
    }

.iconInput--container input.iconInput--partialCheck ~ .iconInput--checkbox .iconInput--checkmark-partial {
    display: block;
}

    .iconInput--container input:checked ~ .iconInput--checkbox{
        background-color: #006693;
        border-color: #006693;
    }

.iconInput__checkbox--focusable:focus {
    outline: none;
    box-shadow: 0px 0px 0px 1px #000;
    border-radius: 3px;
}

.iconInput--checkbox {
    border: 1px solid #666666;
    border-radius: 3px;
    width: 16px;
    height: 16px;
    display: inline-block;
    background-color: #FFFFFF;
    min-width: 16px;
}

.iconInput--radioButton {
    border: 2px solid currentColor;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    display: grid;
    place-content: center;
    box-sizing: border-box;
    margin: 1px 0;
}

.iconInput--container input:checked ~ .iconInput--radioButton {
    padding: 3px;
    background: var(--overdrive-blue) content-box;
}

#UserDetailFrm .iconInput--container {
    cursor: default;
}

#UserDetailFrm .iconInput--container input {
    opacity: 0;
    position: absolute;
    cursor: pointer;
    z-index: 1;
    width: 20px;
    height: 20px;
    display: block;
}

#UserDetailFrm input:checked ~ span .iconInput--checkmark {
    color: #006693;
    display: block;
    margin-top: 2px;
}

#UserPermissions .iconInput--label {
    margin-left: 7px;
}

.iconInput--label {
    margin-left: 12px;
    align-self: center;
}

.iconInput--container input:disabled ~ .iconInput--label {
    cursor: default;
    color: #bbb;
}

.iconInput__container--checkbox .iconInput--label {
    margin-top: 1px;
}

.iconInput__container--radio-button .iconInput--label {
    margin-top: -1px;
}


.iconBox {
    width: 366px;
    margin: 20px 0;
    padding: 7px 10px;
    border-radius: 5px;
}

.iconBox--icon {
    float: left;
    width: 19px;
    padding-right: 5px;
}

.iconBox--message {
    margin-left: 25px;
}

.iconBox.iconBox--success {
    background-color: rgba(142, 197, 73, 0.15);
}

.iconBox.iconBox--warning {
    background-color: rgba(255, 128, 0, 0.15);
}

.iconBox.iconBox--error {
    background-color: rgba(255, 151, 151, 0.15);
}

.iconBox.iconBox--success .iconBox--icon {
    color: rgba(142, 197, 73, 1);
}

.iconBox.iconBox--warning .iconBox--icon {
    color: rgba(255, 128, 0, 1);
}

.iconBox.iconBox--error .iconBox--icon {
    color: rgba(255, 70, 70, 1);
}

.label--icon-rotatable {
    color: #0A2240;
    font-size: 14px;
    display: flex;
    cursor: pointer;
}

.label--icon-rotatable > input {
    display: none;
}

.label--icon-rotatable > input:checked + .icon {
    transform: rotate(90deg);
}

.label--icon-rotatable > span.label {
    margin-left: 10px;
    font-family: 'proxima-nova-semibold', 'proxima-nova', 'Segoe UI', 'Open Sans', Tahoma, Helvetica, sans-serif;
}

/** end Icon Inputs **/

/***** END Inputs *****/

/***** BEGIN Header modals *****/
/* be sure to set the fancybox param of noOuterPadding to true*/
.modal--header {
    background-color: #006693;
    color: #ffffff;
    border: 1px solid #173E54;
    border-radius: 4px 4px 0 0;
    padding: 22px
}

.modal--header h1, #modal .modal--header h1 {
    color: #ffffff;
    width: 100%;
    padding: 0;
    font-size: 2.25em;
}

.modal--body {
    padding: 22px 30px;
}

.modal--footer {
    background-color: #f3f3f3;
    padding: 10px 30px 30px 30px;
    border-radius: 0 0 4px 4px;
}

.tooltip--base {
    position: relative;
    display: inline-block;
}

.tooltip--text {
    visibility: hidden;
    position: absolute;
    z-index: 100;
    background-color: #0A2240;
    color: #ffffff;
    padding: 3px;
    border-radius: 3px;
    top: 16px;
}

.tooltip--base:hover .tooltip--text{
    visibility: visible;
}
/***** END Header modals *****/

/***** BEGIN Shop area *****/

.shop--wrapper {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    flex-shrink: 0;
}

.shop--middle {
    justify-content: center;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.shop--middle-content {
    padding: 24px 18px;
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
}

.shop--sidebar-left {
    width: 240px;
    background-color: #F9F9F9;
    justify-content: flex-start;
    flex: 0 0 auto;
    display: flex;
}

.shop--sidebar-right {
    justify-content: flex-end;
}

.shop--sidebar-content {
    display: flex;
    flex-direction: column;
    border-right: 1px solid #DDDDDD;
    flex: 1 0 auto;
    padding-bottom: 78px; /* padding so filters won't be stuck behind bottom apply button'*/
    width: 240px;
}

.shop--filters-section {
    padding: 18px;
    border-bottom: 1px solid #DDDDDD;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
}

    .shop--filters-section .icon-small-arrow-right,
    .shop--filters-section .icon-small-arrow-down {
        color: #006693;
        font-weight: 600;
        transition: all 0.2s 0s;
        transform: rotate(0);
        display: inline-block;
        margin: 0;
    }

.shop--filters-top-apply-section {
    flex-direction: row;
}

.shop--filters-section button:first-child {
    margin-left: auto;
}

.shop--filters-results-copy {
    justify-self: flex-start;
    display: flex;
    align-items: center;
    flex: 1 0 auto;
    font-size: 14.8px;
    color: #666666;
}

.shop--filters-results-count {
    width: 34px;
    text-align: right;
}

.shop--filters-apply-button {
    justify-self: flex-end;
    font-family: 'proxima-nova-semibold', 'proxima-nova', 'Segoe UI', 'Open Sans', Tahoma, Helvetica, sans-serif;
    font-size: 16px;
    margin-left: auto;
    display: flex;
    align-items: center;
    padding: 0 30px;
    height: 40px;
}

.shop--filters-fixed-bottom {
    display: flex;
    flex-direction: row;
    position: fixed;
    position: sticky;
    bottom: 0;
    border-top: 1px solid #DDDDDD;
    border-right: 1px solid #DDDDDD;
    width: 204px;
    box-shadow: 0px -5px 15px rgba(51, 51, 51, 0.12);
    background-color: #F9F9F9;
    margin-top: 18px;
    z-index: 20;
}

.shop--filters-apply-button-wrapper {
    margin-left: auto;
}

.shop--filters-results-copy-wrapper {
    display: flex;
    align-items: center;
}

.shop--filters-apply-buttonBelowCount {
    flex-direction: column;
}

    .shop--filters-apply-buttonBelowCount .shop--filters-apply-button-wrapper {
        margin-left: 0;
        display: flex;
        flex-direction: row;
    }

    .shop--filters-apply-buttonBelowCount .shop--filters-results-copy-wrapper {
        height: 18px;
        margin-bottom: 8px;
    }

.shop--filters-maxHeightScroll {
    max-height: 250px;
    margin-right: 2px;
    overflow-y: auto;
    flex: 1 1 auto;
    margin: 0;
    display: flex;
    flex-direction: column;
}

.shop--filters-maxHeightScroll.scroll-wrapper > .scroll-content {
    padding-right: 10px;
    max-height: 250px !important;
    padding-bottom: 1px !important;
}

    .shop--filters-maxHeightScroll div.shop--filters-facet:first-child,
    .shop--filters-maxHeightScroll div.shop--filters-facet.firstFacet {
        padding-top: 0;
    }

.shop--filters-facet, .shop--filters-facet-group {
    color: #006693;
    font-size: 13px;
    display: flex;
    padding-left: 8px;
    padding-top: 12px;
}

.shop--filters-facet {
    flex: 1 0 auto;
}

.shop--filters-facet-group > .iconInput--container {
    flex: 1 0 90%;
}

.shop--filters-facet-group .shop--filters-facet {
    padding-left: 20px;
}

.shop--filters-facet-group {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.shop--filters-facet-group > .label--icon-rotatable {
    display: flex;
    flex: 1 0 10%;
    justify-content: flex-end;
}

.shop--filters-find {
    display: flex;
    margin-left: 8px;
    padding: 12px 0 12px 0px;
    border-bottom: 1px solid #DDDDDD;
}

.shop--filters-range-prefix {
    margin-right: 3px;
}

.shop--filters-range-suffix {
    margin-left: 3px;
}

.shop--filters-range-wrapper {
    display: flex;
    align-items: center;
    flex: 1 1 auto;
    background-color: #FFFFFF;
    border: 1px solid #DDDDDD;
    border-radius: 3px;
    padding: 5px;
}

.shop--filters-range-wrapper input[type="text"] {
    padding: 0;
    margin: 0;
    border: 0;
}

    .shop--filters-range-wrapper.input-validation-error input[type="text"] {
        background-color: #fef1ec !important;
    }

.shop--filters-range-note {
    font-size: 12.8px;
    color: #999;
    padding-top: 12px;
    padding-left: 8px;
}

.shop--filters-range-validationMessage {
    font-size: 12.8px;
    color: #AE4646;
    padding-top: 12px;
    padding-left: 8px;
}

.shop--filters-section input[type="text"].shop--filters-textinput {
    flex: 1 1 auto;
    background-color: #FFFFFF;
    border: 1px solid #DDDDDD;
    border-radius: 3px;
    padding: 5px;
    min-width: 0;
}

        .shop--filters-section input[type="text"].shop--filters-textinput.withSearchIcon {
            padding-right: 26px;
        }

    .shop--filters-section .shop--filters-find.withClearIcon input[type="text"].shop--filters-textinput {
        padding-left: 20px;
    }

    .shop--filters-find .icon-search {
        color: #666666;
        position: relative;
        right: 24px;
        top: 10px;
        width: 0;
        margin: 0;
    }

    .shop--filters-find .icon-close {
        color: #666666;
        position: relative;
        left: 6px;
        top: 12px;
        width: 0;
        margin: 0;
        font-size: 12px;
        display: none;
        cursor: pointer;
    }

    .shop--filters-find.withClearIcon .icon-close {
        display: inline;
    }

.shop--filters-exclude {
    display: flex;
    flex-direction: column;
    border-bottom: none;
    border-top: 1px solid #DDDDDD;
    padding-bottom: 0;
    padding-top: 12px;
    margin-top: 12px;
    margin-left: 8px;
}

.shop--filters-exclude-label {
    padding-bottom: 4px;
    padding-left: 8px;
}

.shop--filters-range {
    padding-top: 12px;
    padding-left: 8px;
    display: flex;
    align-items: center;
}

.shop--filters-range input {
    width: 100%;
}

.shop--filters-range-divider {
    padding: 0 8px;
    flex: 0 0 auto;
}

.shop--filters-range-clear {
    padding-top: 12px;
    padding-left: 8px;
}

.shop--filters-noResults {
    flex: 1 1 auto;
    padding: 0 0 0 16px;
}

.shop--filters-applied-count {
    border-radius: 50%;
    background-color: #006693;
    color: #FFFFFF;
    font-size: 13px;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 auto;
    margin-left: 8px;
}

.shop--filters-applied-category {
    padding-left: 8px;
    display: flex;
    flex-direction: column;
    margin-bottom: 4px;
    padding-right: 4px;
    flex: 0 0 auto;
}

.shop--filters-applied-category-name {
    color: #333333;
    font-size: 13px;
    display: inline-block;
    padding-bottom: 5px;
    font-family: 'proxima-nova-semibold', 'proxima-nova', 'Segoe UI', 'Open Sans', Tahoma, Helvetica, sans-serif;
}

.shop--filters-applied-facet {
    background-color: #006693;
    color: #ffffff;
    border-radius: 3px;
    flex: 0 1 auto;
    padding: 5px;
    display: flex;
    align-items: center;
    margin-right: 8px;
    margin-bottom: 8px;
    word-break: break-all;
}

    .shop--filters-applied-facet.hoverable:hover {
        background-color: #0A2240;
    }

    .shop--filters-applied-facet .select2-search-choice-close {
        margin-right: 0;
        padding-left: 8px;
        height: 12px;
        width: 12px;
        position: inherit;
        margin-bottom: 2px;
    }

.shop--filters-applied-main {
    padding-top: 12px;
}

.shop--filters-applied-main #noAppliedFilters {
    color: #666666;
    padding-left: 8px;
}

.shop--filters-range .select--rounded {
    border: 0;
    border-radius: 3px;
    height: auto;
    flex: 1 1 auto;
    padding: 1px;
    align-items: center;
}

.shop--filters-range .select--rounded select {
    height: auto !important;
    padding: 0;
    padding-right: 20px
}

.shop--filters-range .select--rounded:after {
    right: 5px;
    color: #333333;
    font-size: 14px;
    top: 3px;
}

.shop--filters-advanced {
    display: flex;
    flex-direction: column;
    background-color: #DDDDDD;
    padding: 8px;
    border-radius: 5px;
    margin-bottom: 12px;
    margin-right: 12px;
}

.shop--filters-advanced-name {
    font-family: 'proxima-nova-semibold', 'proxima-nova', 'Segoe UI', 'Open Sans', Tahoma, Helvetica, sans-serif;
    font-size: 13px;
    padding-bottom: 8px;
    color: #0A2240;
}

.shop--filters-advanced-category-name {
    font-size: 13px;
    color: #0A2240;
    padding-bottom: 5px;
    display: inline-block;
    font-family: 'proxima-nova-semibold', 'proxima-nova', 'Segoe UI', 'Open Sans', Tahoma, Helvetica, sans-serif;
}

/***** END Shop area *****/
.font--family-proxima-nova-semibold {
    font-family: 'proxima-nova-semibold', 'proxima-nova', 'Segoe UI', 'Open Sans', Tahoma, Helvetica, sans-serif;
}

/***** BEGIN Preferences area *****/

.preferences__option-header {
    font-size: 22.4px;
}

.preferences__middle-content {
    margin-left: 48px;
    margin-right: 48px;
    margin-bottom: 70px;
}

.preferences__middle-content .iconInput__container--radio-button {
    margin-top: 8px;
}

.preferences__middle-content .select--rounded {
    border: 1px solid #DDD;
    border-radius: 2px;
}

.preferences__middle-content .select--rounded:after {
    color: #333;
    font-weight: bold;
    font-size: 1em;
    padding-top: 6px;
}

.preferences__radio-button {
    margin-left: 4px;
    margin-top: 8px;
}

.preferences__options-divider {
    width: 720px;
    margin-top: 20px;
    text-align: left;
}

select.preferences__select--right-padding {
    padding-right: 70px;
}

.preferences__sub-option-header {
    font-size: 15px;
    margin-top: 20px;
    margin-bottom: 10px;
}

/********************************* Begin Overview page */

.preferences__overview-subsection {
    padding: 36.5px 0px;
    border-bottom: 1px solid #DDDDDD;
    text-align: left;
    width: 720px;
}

.preferences__header {
    margin-top: 6px;
    margin-bottom: 37px;
}

.preferences__header h1 {
    font-size: 28px;
    color: #0A2240;
}

.preferences__header p {
    font-size: 14.8px;
    line-height: 22.2px;
    margin-top: 18px;
}

.preferences__overview-subsection .preferences__subsection-header {
    font-size: 22.4px;
    font-family: 'proxima-nova';
    margin: 0;
}

.preferences__edit-setting {
    font-size: 14.8px;
    color: #006593;
}

.preferences__edit-setting a:hover {
    text-decoration: none;
    cursor: pointer;
    color: #006593;
    opacity: .8;
}


.preferences__detail-row {
    font-size: 12.8px;
    white-space: nowrap;
}

.preferences__search-display-value {
    white-space: nowrap;
    background: #006693 0% 0% no-repeat padding-box;
    border-radius: 5px;
    color: #FFFFFF;
    padding: 4px;
    align-items: center;
    margin-right: 6px;
    font-size: 12.8px;
}

.preferences__search-display-value-wrapper {
    white-space: normal;
    line-height: 32px;
}

.preferences__email-row:last-child {
    margin-bottom: 0 !important;
}

.preferences__email-row .icon-checkmark-heavy {
    color: #006593;
}

.preferences__scroll--max-height {
    max-height: 128px !important;
    overflow-y: scroll !important;
}

.preferences__scroll-wrapper .scroll-element_outer {
    width: 5px !important;
    overflow: hidden;
}

.preferences__scroll-wrapper--box-shadow-bottom {
    box-shadow: inset 0 -121px 10px -127px rgba(43,43,43,1);
}


/********************************* End Overview page */
/********************************* Begin Notifications page */
.preferences__notificationOption {
    margin-bottom: 10px;
}

.preferences__notificationOption .iconInput--container:first-of-type {
    margin-top: 10px;
}

.preferences__set-all-container {
    margin: 24px 0 24px 0;
}

.iconInput--container {
    cursor: default;
}

.iconInput--container .iconInput--label {
    cursor: pointer;
}

.iconInput--container input {
    opacity: 0;
    position: absolute;
    cursor: pointer;
    z-index: 1;
    display: block;
    height: 20px;
    width: 20px;
}

.iconInput--container input:disabled{
    cursor: default;
}

.iconInput--container input ~ span.iconInput--greyUnchecked {
    color: #aaaaaa;
}

.iconInput--container input:checked ~ span.iconInput--greyUnchecked.text--blue {
    color: #006693;
}

.notifications--button {
    min-width: 70px;
    border-radius: 5px;
    font-size: 14px;
}
/********************************* End Notifications page */
/********************************* Begin Search Presets page */
.preferences__search-presets .shop--filters-find {
    border-bottom: 0;
    padding-top: 0;
}

.preferences__search-presets .shop--filters-section {
    border-bottom: 0;
    padding-bottom: 0;
    max-width: 220px;
}

.preferences__search-presets .shop--filters-maxHeightScroll {
    height: 250px;
}

.preferences__search-presets .shop--filters-facet-group,
.preferences__search-presets .shop--filters-facet {
    flex: none;
    padding-top: 0;
    margin-bottom: 12px;
}

.preferences__search-presets .shop--filters-noResults {
    padding-left: 8px;
    margin-bottom: -17px;
}

.preferences__search-presets .scroll-element_outer {
    width: 5px !important;
    left: 6px !important;
    overflow: hidden;
}

.preferences__search-presets .scrollbar-outer > .scroll-element.scroll-y {
    opacity: 1;
}

.preferences__search-presets .scrollbar-outer > .scroll-element.scroll-y .scroll-bar {
    background-color: #AAA;
    opacity: 1;
}

/********************************* End Search Presets page */
/***** END Preferences area *****/
/*** Begin Generic inputs conatiner ***/

.preference-options__container {
    padding: 18px;
    padding-bottom: 13px;
    width: 480px;
    background: #F9F9F9;
    border: 1px solid #DDDDDD;
    border-radius: 2px;
    margin-top: 16px;
    margin-bottom: 20px;
}

.preference-options__header {
    color: #0A2241;
    font-size: 16px;
    margin: 0 0 8px 2px;
    margin-left: 2px;
    font-family: 'proxima-nova-semibold', 'proxima-nova', 'Segoe UI', 'Open Sans', Tahoma, Helvetica, sans-serif;
}

.preference-option__input-container {
    margin-top: 10px;
    margin-bottom: 2px;
}

.iconInput--label.preference-option__input-label {
    font-size: 14px;
    margin-left: 8px;
    margin-top: 0;
}

.preference-option__detail-container {
    margin: 5px 0 5px 28px;
    display: flex;
}

.preference-option__icon-checkmark {
    font-size: 11px;
    line-height: 1.4em;
    color: #666666;
    opacity: 0.5;
}

.preference-option__detail-label {
    font-size: 12px;
    color: #666666;
}

.preference-option__container--active .preference-option__detail-label,
.preference-option__container--active .preference-option__icon-checkmark {
    color: #333333;
    opacity: 1;
}

/*** End Generic inputs conatiner ***/

/*** Begin generic fancybox modal styles
**
** fancybox options for these styles should also include:
** fbOpts.borderless = true;
** fbOpts.noOuterPadding = true;
*/

#modal .inline-modal__top {
    padding: 19px 24px;
}

#modal .inline-modal__top h1 {
    font-family: proxima-nova-semibold;
    font-size: 1.9em;
    padding: 0 0 18px 0;
    margin: 5px 0 24px 0;
    border-bottom: 1px solid #E3E3E3;
    width: 100%;
}

#modal .inline-modal__top p {
    margin-bottom: 16px;
}

#modal .inline-modal__bottom {
    padding: 19px 24px;
    position: sticky;
    bottom: 0;
    box-shadow: 0 3px 17px rgba(51, 51, 51, 0.5)
}

#modal .inline-modal__bottom a,
#modal .inline-modal__bottom span {
    margin-bottom: 0;
    padding-left: 23px;
    padding-right: 23px;
}

#modal .inline-modal__bottom a:not(:first-child),
#modal .inline-modal__bottom span:not(:first-child) {
    margin-left: 7px;
}

/*** End generic fancybox modal styles  ***/

.Global-icon-link {
  display: flex;
  gap: 8px;
  align-items: center;
  cursor: pointer;
  color: #006693;
}

.Global-icon-link > :is([class^="icon-"], [class*=" icon-"]) {
  margin: 0;
}

.Global-icon-link:hover {
  color: #0a2240;
}

/* Inside of .umbrella, reverts all declarations with a specificity lower than (0,1,0) or those tied but before this point.
 * This is why many declarations like a:hover use :where to reduce specificity to fall inside this.
 */
.umbrella :where(*, *::before, *::after) {
  all: revert-layer;
} 