.expanding-radio-box__option-wrapper {
    border: 1px solid #dddddd;
    background-color: transparent;
    border-radius: 5px;
    margin: 16px 0;
    padding: 10px;
}

.expanding-radio-box__option-wrapper--active {
    border: 1px solid var(--overdrive-blue);
    background-color: #01669308;
}

.expanding-radio-box__option-wrapper-content,
.expanding-radio-box__option-wrapper-selection > *:not(:first-child) {
    margin-left: 32px;
}

.expanding-radio-box__option-wrapper:not(.expanding-radio-box__option-wrapper--active) {
    cursor: pointer;
}

.expanding-radio-box__option-wrapper:not(.expanding-radio-box__option-wrapper--active) .expanding-radio-box__option-wrapper-content {
    display: none;
}

.expanding-radio-box__option-wrapper-content .dialog-modal__section:first-of-type {
    margin-top: 12px;
}

.expanding-radio-box__option-wrapper-content .dialog-modal__section:last-of-type {
    margin-bottom: 12px;
}
